import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { DashboardPage } from './dashboard/dashboard.page'
import { HomePage } from './home/home.page'
import { ProjectContainer } from './project/project.container'
import { ProjectsPage } from './projects/projects.page'
import { SettingPage } from './setting/setting.page'

export const CommonRoute = () => (
  <Routes>
    <Route index element={<HomePage />} />
    <Route path='projects' element={<ProjectsPage />} />
    <Route path='project/*'>
      <Route index element={<ProjectContainer />} />
      <Route path=':projectId' element={<ProjectContainer />} />
    </Route>
    <Route path='setting' element={<SettingPage />} />
    <Route path='dashboard/*' element={<DashboardPage />} />
    <Route path='*' element={<Navigate replace to='/' />} />
  </Routes>
)
