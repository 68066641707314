import 'react-toastify/dist/ReactToastify.css'
import 'src/style/css/override/toastify.override.scss'

import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import styles from './toast.scss'

const errorOptions: ToastOptions = {
  theme: 'colored',
  autoClose: false,
  closeOnClick: false,
}

const successOptions: ToastOptions = {
  theme: 'colored',
  closeOnClick: false,
}

const toastErrorTemplate = (errCode: string, message: string, info: Record<string, any>) => {
  const infoSize = Object.keys(info).length
  const infoDatas = JSON.stringify(info, null, 2)

  return (
    <div className={styles.error}>
      <div className={styles.errCode}>{errCode}</div>
      <div className={styles.message}>{message}</div>
      {infoSize > 0 && <div>{infoDatas}</div>}
    </div>
  )
}

export const toastTool = {
  ...toast,
  errorString: (msg: string, options?: ToastOptions) => toast.error(msg, { ...options, ...errorOptions }),
  errorTemplate: (errCode: string, message: string, info: Record<string, any>, options?: ToastOptions) =>
    toast.error(toastErrorTemplate(errCode, message, info), { ...options, ...errorOptions }),
  success: (msg: string, options?: ToastOptions) => toast.success(msg, { ...options, ...successOptions }),
}
