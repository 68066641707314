import classNames from 'classnames'
import React from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import iconCircleCheck from 'src/style/icon/icon-circle-check.svg'
import styles from './project-step.scss'

type PropsType = {
  step: number
  stepItemLabels: string[]
}

export const ProjectStep = ({ step, stepItemLabels }: PropsType) => {
  return (
    <div className={styles.root}>
      {stepItemLabels.map((label, idx) => (
        <div
          key={label}
          className={classNames(styles.bar, { [styles.processed]: idx < step, [styles.active]: idx + 1 === step })}
        >
          <div className={styles.item}>
            <InlineSVG className={styles.icon} src={iconCircleCheck} />
            <label>{label}</label>
          </div>
        </div>
      ))}
    </div>
  )
}
