import React, { useState } from 'react'
import { Button } from 'src/component/atom/button/button'
import { Input } from 'src/component/atom/input/input'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import styles from './change-password-mail.scss'

type PropsType = {
  onClose(): void
}

export const ChangePasswordMailModal = withModalWrapper(({ onClose }: PropsType) => {
  const [username, setUsername] = useState('')

  function handleCreateClick() {
    onClose()
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <label className={styles.label}>{`e-mail`}</label>
        <Input sizeType='md' value={username} onChange={(e) => setUsername(e.currentTarget.value)} />
      </div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleCreateClick} disabled={!username}>
          {`送信`}
        </Button>
      </div>
    </div>
  )
}, 'Send Change Password Mail')
