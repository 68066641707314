import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import React from 'react'
import styles from './chart-bar-compare.scss'

type PropsType = {
  title?: string
  subTitle?: string
  chartHight?: number
  yAxisTitle?: string
  xAxisTitle?: string
  yUnit?: number
  valueDecimals?: number
  xTickValues?: string[]
  series?: { name: string; data: number[] }
}

export const ChartBarCompare = ({
  title,
  subTitle,
  chartHight,
  xAxisTitle,
  yAxisTitle,
  yUnit = 1,
  valueDecimals,
  xTickValues,
  series,
}: PropsType) => {
  HighchartsNoDataToDisplay(Highcharts)

  const chartTitle: Highcharts.TitleOptions = {
    text: title,
    align: 'left',
    margin: subTitle ? 40 : 55,
    style: {
      fontSize: '16px',
      fontWeight: '600',
    },
  }
  const chartYAxis: Highcharts.YAxisOptions = {
    min: 0,
    title: {
      text: yAxisTitle,
    },
  }
  const chartXAxis: Highcharts.XAxisOptions = {
    categories: xTickValues,
    tickmarkPlacement: 'on',
    tickLength: 10,
    tickWidth: 1,
    gridLineWidth: 1,
    title: {
      text: xAxisTitle,
    },
  }
  const dataSeries: Highcharts.SeriesOptionsType[] = [
    {
      type: 'column',
      name: series?.name,
      data: series
        ? series.data.map((v, idx) =>
            idx === 0 ? { y: v / yUnit, color: '#5158FA' } : { y: v / yUnit, color: '#bbb' },
          )
        : [],
    },
  ]

  const options: Highcharts.Options = {
    credits: { enabled: false },
    accessibility: { enabled: false },
    lang: {
      noData: '該当するデータが存在しません。',
    },
    chart: {
      type: 'column',
      height: chartHight,
      plotShadow: false,
      style: {
        fontFamily: 'Roboto, NotoSansJP',
      },
    },
    title: chartTitle,
    subtitle: {
      text: subTitle || ' ',
      align: 'left',
    },
    yAxis: chartYAxis,
    xAxis: chartXAxis,
    tooltip: {
      formatter() {
        return (
          '<span style="font-size: 11px">' +
          this.point.category +
          '</span><br/>' +
          '<span style="color:' +
          this.point.color +
          '">\u25CF</span> ' +
          this.series.name +
          ': <b>' +
          new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: valueDecimals,
          }).format(Number(this.point.y)) +
          '</b><br/>'
        )
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    series: dataSeries,
  }

  return (
    <div className={styles.root}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
