import classNames from 'classnames'
import React, { useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { Checkbox } from 'src/component/atom/checkbox/checkbox'
import { EditBranchModal } from 'src/modal/branch/edit-branch.modal'
import IconPencil from 'src/style/icon/icon-pencil.svg'
import { BranchType } from 'src/type/branch.type'
import styles from './branch-item.scss'

type PropsType = {
  branch: BranchType
  checked: boolean
  active?: boolean
  onChangeCheckbox?(e: React.ChangeEvent<HTMLInputElement>): void
}

export const BranchItem = ({ branch, checked, active, onChangeCheckbox }: PropsType) => {
  const [isModalVisible, setModalVisible] = useState(false)

  function handleClickEdit() {
    setModalVisible(true)
  }

  return (
    <div key={branch.id} className={classNames(styles.root, { [styles.active]: active })}>
      <div className={styles.checkboxCell}>
        <Checkbox value={branch.id} checked={checked} onChange={onChangeCheckbox} />
      </div>
      <div>{branch.branchCode}</div>
      <div>{branch.branchName}</div>
      <div className={styles.editNameButton} onClick={handleClickEdit}>
        <InlineSVG src={IconPencil} />
      </div>
      <EditBranchModal branch={branch} modalVisible={isModalVisible} onClose={() => setModalVisible(false)} />
    </div>
  )
}
