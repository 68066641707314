import classNames from 'classnames'
import React, { Fragment, useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'src/component/atom/button/button'
import { DataTable, DataTableColumnDefsType } from 'src/component/molecule/data-table/data-table'
import { ProjectResultIntl } from 'src/intl/data/result.key'
import { DownloadTargetListModal } from 'src/modal/download-target-list/download-target-list.modal'
import { projectSelector } from 'src/redux/project/project.state'
import IconDownload from 'src/style/icon/icon-download.svg'
import { ProjectType } from 'src/type/project.type'
import { util } from 'src/util/common.util'
import styles from './result.scss'
import { SegmentInfo } from './segment-info/segment-info'

type PropsType = {
  project: ProjectType
}

export const ResultPanel = ({ project }: PropsType) => {
  const navigate = useNavigate()

  const [modalVisible, setModalVisible] = useState(false)

  const projectDetail = useSelector(projectSelector.selectedProjectDetail)
  const selectedProjectTargetsColumns = useSelector(projectSelector.selectedProjectTargetsColumns)
  const projectResultTargets = useSelector(projectSelector.selectedProjectResultTargets)
  const projectResultTargetLength = useSelector(projectSelector.selectedProjectResultTargetLength)
  const projectResultSegments = useSelector(projectSelector.selectedProjectResultSegments)

  const KPIOptionalValue = useMemo(() => {
    if (projectDetail && projectDetail.projectResult.length > 0) {
      return util.resultKPIOptionalvalue(projectDetail.projectResult[0])
    }
  }, [projectDetail])

  const targetTopList = useMemo(() => {
    if (projectResultTargets) {
      const resultTargets = [...projectResultTargets]
      return resultTargets.sort((a, b) => -a.score + b.score).slice(0, 20)
    }
    return []
  }, [projectResultTargets])

  const targetResultsColumnDefs = useMemo(() => {
    const res = []

    res.push({ ...targetResultsColumnDefObjs.primaryKey, label: selectedProjectTargetsColumns[0].name })

    if (targetTopList[0].secondaryKey) {
      res.push({ ...targetResultsColumnDefObjs.secondaryKey, label: selectedProjectTargetsColumns[1].name })
    }

    res.push(targetResultsColumnDefObjs.score)

    return res
  }, [targetTopList, selectedProjectTargetsColumns])

  function handleDownloadButtonClick() {
    setModalVisible(true)
  }

  function handleHomeButtonClick() {
    navigate('/')
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <section className={styles.summary}>
          <div className={classNames(styles.header, styles.flexColumn)}>
            <span className={styles.title}>{`ビジネスKPI`}</span>
            <span className={styles.description}>
              {`${ProjectResultIntl[project.type].kpiRatioSummery}: ${(
                ((projectDetail && projectDetail.projectResult.length > 0 && projectDetail.projectResult[0].kpiRatio) ||
                  0) * 100
              ).toFixed(2)} % `}
            </span>
            {projectDetail &&
              projectDetail.projectResult.length > 0 &&
              projectDetail.projectResult[0].globalTargetRatio !== null && (
                <span className={styles.description}>
                  {`(${ProjectResultIntl[project.type].globalTargetRatio}: ${(
                    (projectDetail.projectResult[0]?.globalTargetRatio || 0) * 100
                  ).toFixed(2)} %)`}
                </span>
              )}
          </div>
          <div className={styles.summaryBody}>
            {KPIOptionalValue &&
              Object.entries(KPIOptionalValue).map(([key, value]) => (
                <Fragment key={key}>
                  <label className={styles.summaryLabel}>{ProjectResultIntl[project.type][key]}</label>
                  <span className={styles.summaryValue}>{value}</span>
                </Fragment>
              ))}
            <label className={styles.summaryLabel}>{`ターゲティングされた顧客数`}</label>
            <span className={styles.summaryValue}>{projectResultTargetLength}</span>
            <label className={styles.summaryLabel}>{`有意なセグメント数`}</label>
            <span className={styles.summaryValue}>{projectResultSegments.length}</span>
          </div>
        </section>
        <section className={styles.list}>
          <div className={classNames(styles.header, styles.flexEnd2End)}>
            <span className={styles.title}>{`ターゲットリスト`}</span>
            <div className={styles.downloadButton} onClick={handleDownloadButtonClick}>
              <span>{`ダウンロード`}</span>
              <div className={styles.downloadIconWrapper}>
                <InlineSVG className={styles.downloadIcon} src={IconDownload} />
              </div>
            </div>
          </div>
          <div className={styles.listGrid}>
            <DataTable
              dataColumnDefs={targetResultsColumnDefs}
              dataList={targetTopList}
              headerHeight={35}
              bodyBorder
              columnSeperator
              rowSeperator
            />
          </div>
        </section>
        <section className={styles.segments}>
          <div className={styles.header}>
            <span className={styles.title}>{`有意なセグメント`}</span>
          </div>
          {projectResultSegments && <SegmentInfo projectType={project.type} segments={projectResultSegments} />}
        </section>
      </div>
      <div className={styles.footer}>
        <a href='#' className={styles.outQnaLink}>
          {``}
        </a>
        <Button sizeType='lg' onClick={handleHomeButtonClick}>{`ホーム画面に戻る`}</Button>
      </div>
      <DownloadTargetListModal modalVisible={modalVisible} onClose={() => setModalVisible(false)} />
    </div>
  )
}

const targetResultsColumnDefObjs: Record<string, DataTableColumnDefsType> = {
  primaryKey: {
    dataKey: 'primaryKey',
    label: 'Key',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
  },
  secondaryKey: {
    dataKey: 'secondaryKey',
    label: '2nd Key',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
  },
  score: {
    dataKey: 'score',
    label: 'Score',
    flexRate: 1,
    headerAlign: 'center',
    headerBold: true,
    cellAlign: 'center',
    cellDataFormat: (data) => {
      return data ? (Math.round((+data + Number.EPSILON) * 10000) / 100).toFixed(2) : 0
    },
  },
}
