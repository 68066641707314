import lottie from 'lottie-web'
import React, { useEffect, useRef } from 'react'

type PropsType = {
  animationData: JSON
} & React.ButtonHTMLAttributes<HTMLDivElement>

export const Lottie = ({ animationData, ...props }: PropsType) => {
  const lottieRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let lottieInstance: any
    if (lottieRef.current) {
      lottieInstance = lottie.loadAnimation({
        container: lottieRef.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      })
      lottieInstance.setSubframe(false)
    }

    return () => {
      lottieInstance.destroy()
    }
  }, [])

  return <div {...props} ref={lottieRef} />
}
