import { createSelector } from '@reduxjs/toolkit'
import { authSelector } from 'src/redux/auth/auth.state'
import { blueprintSelector } from 'src/redux/blueprint/blueprint.state'
import { branchSelector } from 'src/redux/branch/branch.state'
import { datasetSelector } from 'src/redux/dataset/dataset.state'
import { projectSelector } from 'src/redux/project/project.state'
import { userSelector } from 'src/redux/user/user.state'
import { ReduxStatusEnum } from 'src/type/common.type'
import { dashboardSelector } from '../dashboard/dashboard.state'
import { softwareInfoSelector } from '../software-info/software-info.state'

const visible = createSelector(
  authSelector.status,
  projectSelector.status,
  datasetSelector.status,
  blueprintSelector.status,
  userSelector.status,
  branchSelector.status,
  dashboardSelector.status,
  softwareInfoSelector.status,
  (authStatus, projectStatus, datasetStatus, blueprintStatus, userStatus, branchStatus, dashboardStatus) => {
    if (
      [authStatus, projectStatus, datasetStatus, blueprintStatus, userStatus, branchStatus, dashboardStatus].includes(
        ReduxStatusEnum.PENDING,
      )
    ) {
      return true
    }
    return false
  },
)

export const spinnerSelector = {
  visible,
}
