import React, { useEffect, useMemo, useState } from 'react'
import { DropdownMultiSelector } from 'src/component/molecule/dropdown-multi-selector/dropdown-multi-selector'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { blueprintIntl } from 'src/intl/data/blueprint.key'
import { BlueprintType, MappingInputType, MappedOptionValueType } from 'src/type/blueprint.type'
import { OptionType } from 'src/type/common.type'
import { DatasetType, FieldTypesEnum } from 'src/type/dataset.type'
import { ProjectTypesEnum } from 'src/type/project.type'
import styles from './select-select-row.scss'

type PropsType = {
  projectType: ProjectTypesEnum
  blueprint: BlueprintType
  dataset: DatasetType
  mappedOptionValue?: MappedOptionValueType
  mappedField?: { [fieldId: number]: number }
  isDropdownUpside?: boolean
  onChange?(mappedKeys: { [blueprintId: number]: MappingInputType }): void
}

export const SelectSelectRow = ({
  projectType,
  blueprint,
  dataset,
  mappedOptionValue,
  mappedField,
  isDropdownUpside,
  onChange,
}: PropsType) => {
  const fieldOptions = useMemo(
    () =>
      dataset.fields
        ? dataset.fields
            .filter(
              (field) =>
                !mappedField || (mappedField && !mappedField[field.id]) || mappedField[field.id] === blueprint.id,
            )
            .filter((field) => field.type === FieldTypesEnum.CATEGORY)
            .map((field) => ({ label: field.name, value: field.id }))
        : [],
    [dataset, mappedField],
  )

  const [selectedField, setSelectedField] = useState<OptionType | undefined>()
  const [selectedCategories, setSelectedCategories] = useState<OptionType[]>()

  const categoryOptions = useMemo(() => {
    const activeField = dataset.fields?.find((field) => field.id === selectedField?.value)

    return activeField && activeField.category ? activeField.category.values.map((v) => ({ label: v, value: v })) : []
  }, [selectedField])

  useEffect(() => {
    setSelectedField(mappedOptionValue?.selectedFieldOptions[0])
    setSelectedCategories(mappedOptionValue?.values?.map((value) => ({ label: value, value })) || [])
  }, [mappedOptionValue])

  useEffect(() => {
    if (categoryOptions.length === 0) {
      setSelectedCategories(undefined)
    }
  }, [categoryOptions])

  function handleChangeMapping(fieldOp?: OptionType, categoryOps?: OptionType[]) {
    if (categoryOps && categoryOps.length > 0) {
      onChange &&
        onChange({
          [blueprint.id]: {
            bluePrintId: blueprint.id,
            fieldIds: [{ id: fieldOp?.value }],
            values: categoryOps.map<string>((op) => op.value),
          },
        })
    }
  }

  return (
    <ul className={styles.gridBodyRow}>
      <li className={styles.blueprintCell}>
        {blueprintIntl[projectType][blueprint.name].name}
        {blueprint.isRequired && <span className={styles.required}>*</span>}
      </li>
      <li className={styles.inputCell}>
        <DropdownSingleSelector
          placeholder={`マッピングされていません。`}
          sizeType='lg'
          isNeededFilter
          selectedVisible
          options={fieldOptions}
          selectedOption={selectedField}
          isOpenUpside={isDropdownUpside}
          isDisselectable
          onChange={(op) => {
            setSelectedField(op)
            handleChangeMapping(op, selectedCategories)
          }}
        />
        <DropdownMultiSelector
          placeholder={`マッピングされていません。`}
          sizeType='lg'
          isNeededFilter
          options={categoryOptions}
          selectedOptions={selectedCategories}
          isOpenUpside={isDropdownUpside}
          disabled={categoryOptions.length === 0}
          onChange={(ops) => {
            setSelectedCategories(ops)
            handleChangeMapping(selectedField, ops)
          }}
        />
      </li>
      <li className={styles.descCell}>{blueprintIntl[projectType][blueprint.name].description}</li>
    </ul>
  )
}
