import React from 'react'
import { Dispatch, SetStateAction } from 'react'
import { ValidationErrors } from 'src/type/validation.type'

/**
 * Custom input form control hooks
 * @param initValue initial form state
 * @param validateFn form validator function array (optional)
 * @returns [ formState, setFormState, formError ]
 */
export function useFormControl<T>(
  initValue: T,
  validateFn?: ((formValue: T) => ValidationErrors | null)[],
): [T, Dispatch<SetStateAction<T>>, ValidationErrors | null] {
  const [value, setValue] = React.useState(initValue)
  let formError: ValidationErrors | null = null

  if (validateFn && validateFn.length > 0) {
    formError = validateFn
      .map((fn) => fn(value))
      .reduce<ValidationErrors | null>((res, error) => {
        if (error) {
          if (!res) {
            res = {}
          }
          Object.assign(res, error)
        }
        return res
      }, null)
  }

  return [value, setValue, formError]
}
