import { jwtDecode, JwtPayload } from 'jwt-decode'
import { Action } from 'redux'
import { Epic, combineEpics } from 'redux-observable'
import { Observable, catchError, filter, map, mergeMap, of, shareReplay, switchMap } from 'rxjs'
import { TokenUserType } from 'src/type/auth.type'
import { httpApi } from 'src/util/http.module'
import { httpErrorhandling } from '../redux.util'
import { authAction } from './auth.action'
import { authService } from './auth.service'

const pendingStartEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [authAction.login.request.type, authAction.logout.request.type, authAction.refresh.request.type].includes(
        action.type,
      ),
    ),
    map(() => authAction.increasePendingCount()),
  )

const pendingEndEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        authAction.login.success.type,
        authAction.login.failure.type,
        authAction.login.cancelled.type,
        authAction.logout.success.type,
        authAction.logout.failure.type,
        authAction.logout.cancelled.type,
        authAction.refresh.success.type,
        authAction.refresh.failure.type,
        authAction.refresh.cancelled.type,
      ].includes(action.type),
    ),
    map(() => authAction.decreasePendingCount()),
  )

const pendingFailureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) => [authAction.login.failure.type, authAction.logout.failure.type].includes(action.type)),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const loginEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(authAction.login.request.match),
    switchMap(({ payload }) =>
      authService.login(payload).pipe(
        switchMap(({ response }) => {
          httpApi.setAuthToken(response.accessToken)

          const decodedToken = jwtDecode<{ user: TokenUserType } & JwtPayload>(response.accessToken)

          return decodedToken
            ? [authAction.login.success(), authAction.setLoggedInUser(decodedToken.user)]
            : of(authAction.login.failure())
        }),
        catchError((err) => of(authAction.login.failure(err))),
      ),
    ),
  )

const logoutEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(authAction.logout.request.match),
    switchMap(() =>
      authService.logout().pipe(
        switchMap(() => {
          httpApi.clearAuthToken()

          return [authAction.logout.success(), authAction.setLoggedInUser(null)]
        }),
        catchError((err) => of(authAction.logout.failure(err))),
      ),
    ),
  )

const refreshEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(authAction.refresh.request.match),
    mergeMap(() =>
      authService
        .refresh()
        .pipe(shareReplay(1))
        .pipe(
          mergeMap(({ response }) => {
            httpApi.setAuthToken(response.accessToken)

            const decodedToken = jwtDecode<{ user: TokenUserType } & JwtPayload>(response.accessToken)

            return decodedToken
              ? [authAction.refresh.success(), authAction.setLoggedInUser(decodedToken.user)]
              : of(authAction.refresh.failure())
          }),
          catchError((err) => of(authAction.refresh.failure(err))),
        ),
    ),
  )

export const authEpic = combineEpics(
  pendingStartEpic,
  pendingEndEpic,
  pendingFailureEpic,
  loginEpic,
  logoutEpic,
  refreshEpic,
)
