import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { ProjectType, ProjectTypesEnum } from 'src/type/project.type'
import { GridRowItem } from './grid-row-item/grid-row-item'
import styles from './project-grid.scss'

type PropsType = {
  gridType: 'summary' | 'panel'
  projects: ProjectType[]
  availableTopics?: ProjectTypesEnum[]
  onChangeCheckedProject?(projectIds: number[]): void
  onChangeTargetProjectName?(projectId: number, newName: string): void
}

export const ProjectGrid = ({
  gridType,
  projects,
  availableTopics,
  onChangeCheckedProject,
  onChangeTargetProjectName,
}: PropsType) => {
  const [checkedProjects, setCheckedProjects] = useState<{ [projectId: number]: boolean }>({})

  useEffect(() => {
    setCheckedProjects(
      projects.reduce<{ [projectId: number]: boolean }>((res, project) => {
        res[project.id] = false
        return res
      }, {}),
    )
  }, [projects])

  useEffect(() => {
    onChangeCheckedProject &&
      onChangeCheckedProject(
        Object.entries(checkedProjects)
          .filter(([, value]) => !!value)
          .map(([key]) => +key),
      )
  }, [checkedProjects])

  return (
    <div className={styles.root}>
      <ul
        className={classNames(styles.head, {
          [styles.panelGridTemplate]: gridType === 'panel',
          [styles.summaryGridTemplate]: gridType === 'summary',
        })}
      >
        {gridType === 'panel' && <li></li>}
        <li className={styles.headCell}>{`プロジェクト名`}</li>
        <li className={styles.headCell}>{`ステータス`}</li>
        <li className={styles.headCell}>{`日付`}</li>
        <li className={styles.headCell}>{`テーマ`}</li>
      </ul>
      <div className={styles.body}>
        {projects.map((project) => (
          <GridRowItem
            key={project.id}
            project={project}
            gridType={gridType}
            available={project.projectResult.length > 0 || !availableTopics || availableTopics.includes(project.type)}
            onChangeCheckbox={(e) => {
              setCheckedProjects({ ...checkedProjects, [e.currentTarget.value]: e.currentTarget.checked })
            }}
            onChangeProjectName={onChangeTargetProjectName}
          />
        ))}
      </div>
    </div>
  )
}
