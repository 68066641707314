import { Action } from 'redux'
import { combineEpics, Epic } from 'redux-observable'
import { catchError, filter, map, mergeMap, Observable, of, switchMap } from 'rxjs'
import { httpErrorhandling } from '../redux.util'
import { datasetAction } from './dataset.action'
import { datasetService } from './dataset.service'

const pendingStartEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        datasetAction.fetchDatasets.request.type,
        datasetAction.createDataset.request.type,
        datasetAction.deleteDataset.request.type,
      ].includes(action.type),
    ),
    map(() => datasetAction.increasePendingCount()),
  )

const pendingEndEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        datasetAction.fetchDatasets.success.type,
        datasetAction.fetchDatasets.failure.type,
        datasetAction.fetchDatasets.cancelled.type,
        datasetAction.createDataset.success.type,
        datasetAction.createDataset.failure.type,
        datasetAction.createDataset.cancelled.type,
        datasetAction.deleteDataset.success.type,
        datasetAction.deleteDataset.failure.type,
        datasetAction.deleteDataset.cancelled.type,
      ].includes(action.type),
    ),
    map(() => datasetAction.decreasePendingCount()),
  )

const pendingFailureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) =>
      [
        datasetAction.fetchDatasets.failure.type,
        datasetAction.createDataset.failure.type,
        datasetAction.deleteDataset.failure.type,
      ].includes(action.type),
    ),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const fetchDatasetsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(datasetAction.fetchDatasets.request.match),
    switchMap(({ payload }) =>
      datasetService.fetchDatasets({ projectId: payload.projectId }).pipe(
        mergeMap(({ response }) => [datasetAction.fetchDatasets.success(), datasetAction.setDatasets(response)]),
        catchError((err) => of(datasetAction.fetchDatasets.failure(err))),
      ),
    ),
  )

const fetchDatasetsBackgroundEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(datasetAction.fetchDatasetsInProgress.request.match),
    switchMap(({ payload }) =>
      datasetService.fetchDatasets({ projectId: payload.projectId }).pipe(
        mergeMap(({ response }) => [
          datasetAction.fetchDatasetsInProgress.success(),
          datasetAction.setDatasets(response),
        ]),
        catchError((err) => of(datasetAction.fetchDatasetsInProgress.failure(err))),
      ),
    ),
  )

const fetchDatasetEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(datasetAction.fetchDatasetInProgress.request.match),
    switchMap(({ payload }) =>
      datasetService.fetchDataset({ datasetId: payload.datasetId }).pipe(
        mergeMap(({ response }) => [
          datasetAction.fetchDatasetInProgress.success(),
          datasetAction.setDataset({ datasetId: response.id, dataset: response }),
        ]),
        catchError((err) => of(datasetAction.fetchDatasetInProgress.failure(err))),
      ),
    ),
  )

const createDatasetEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(datasetAction.createDataset.request.match),
    switchMap(({ payload }) =>
      datasetService.createDataset(payload).pipe(
        mergeMap(({ response }) => [
          datasetAction.setDataset({ datasetId: response.id, dataset: response }),
          datasetAction.createDataset.success(),
        ]),
        catchError((err) => [datasetAction.createDataset.failure(err)]),
      ),
    ),
  )

const deleteDatasetEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(datasetAction.deleteDataset.request.match),
    switchMap(({ payload }) =>
      datasetService.deleteDataset(payload).pipe(
        mergeMap(() => [datasetAction.deleteDataset.success(), datasetAction.removeDataset({ datasetId: payload.id })]),
        catchError((err) => [datasetAction.deleteDataset.failure(err)]),
      ),
    ),
  )

export const datasetEpic = combineEpics(
  pendingStartEpic,
  pendingEndEpic,
  pendingFailureEpic,
  fetchDatasetsEpic,
  fetchDatasetsBackgroundEpic,
  fetchDatasetEpic,
  createDatasetEpic,
  deleteDatasetEpic,
)
