import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Lottie } from 'src/component/atom/lottie/lottie'
import SpinnerData from 'src/style/lottie/spinner.json'
import styles from './spinner.scss'

type PropsType = {
  visible: boolean
}

export const Spinner = ({ visible }: PropsType) => {
  const [container] = useState(document.createElement('div'))

  useEffect(() => {
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [container])

  return createPortal(
    <>
      {visible && (
        <div className={styles.root}>
          <Lottie animationData={SpinnerData} className={styles.spinner} />
          <span>Loading ...</span>
        </div>
      )}
    </>,
    container,
  )
}
