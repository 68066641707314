import React from 'react'
import styles from './tag-label.scss'

type PropsType = {
  title: string
}

export const TagLabel = ({ title }: PropsType) => {
  return <span className={styles.root}>{title}</span>
}
