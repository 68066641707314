import { ValidationErrors } from 'src/type/validation.type'

export const validatorsErrkeys = {
  required: 'isRequired',
  matchValue: 'matchValueInvalid',
}

function isRequired<T>(formValue: T): ValidationErrors | null {
  return (typeof formValue !== 'number' && !formValue) || (typeof formValue === 'number' && isNaN(formValue))
    ? { [validatorsErrkeys.required]: true }
    : null
}

function matchValue(value: string) {
  return function <T extends string>(formValue: T): ValidationErrors | null {
    return !value || formValue !== value ? { [validatorsErrkeys.matchValue]: true } : null
  }
}

export const validators = {
  isRequired,
  matchValue,
}
