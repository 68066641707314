import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { InputPassword } from 'src/component/molecule/input-password/input-password'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { useFormControl } from 'src/hook/use-form-control'
import { userAction } from 'src/redux/user/user.action'
import { userSelector } from 'src/redux/user/user.state'
import { ReduxStatusEnum } from 'src/type/common.type'
import { UserType } from 'src/type/user.type'
import { validators } from 'src/util/validators.util'
import styles from './change-password.scss'

type PropsType = {
  user: UserType
  onClose(): void
}

export const ChangePasswordModal = withModalWrapper(({ user, onClose }: PropsType) => {
  const dispatch = useDispatch()

  const [touched, setTouched] = useReducer(
    (state: { [inputName: string]: boolean }, newState: { [inputName: string]: boolean }) => {
      return { ...state, ...newState }
    },
    {},
  )

  const [currentPassword, setCurrentPassword, currentPasswordError] = useFormControl('', [validators.isRequired])
  const [passwordPattern, setPasswordPattern, passwordPatternError] = useFormControl('', [validators.isRequired])
  const [newPassword, setNewPassword, newPasswordError] = useFormControl('', [
    validators.isRequired,
    validators.matchValue(passwordPattern),
  ])

  const isError = currentPasswordError || newPasswordError

  const [changeClick, setChangeClick] = useState(false)

  const userStatus = useSelector(userSelector.status)
  const editSuccess = useSelector(userSelector.editSuccess)

  useEffect(() => {
    return () => {
      dispatch(userAction.setEditSuccess(false))
    }
  }, [])

  useEffect(() => {
    if (changeClick && userStatus === ReduxStatusEnum.IDLE && editSuccess) {
      onClose && onClose()
    }
  }, [editSuccess])

  function handleCreateClick() {
    setChangeClick(true)
    dispatch(userAction.editUserPassword.request({ userId: user.id, currentPassword, newPassword }))
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.item}>
          <label className={styles.label}>{`現在のパスワード`}</label>
          <InputPassword
            sizeType='md'
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.currentTarget.value)}
            onFocus={() => setTouched({ currentPassword: true })}
            isError={touched.currentPassword && !!currentPasswordError}
          />
          <div className={styles.errorText}>
            {touched.currentPassword && !!currentPasswordError && 'current password error'}
          </div>
        </div>
        <div className={styles.item}>
          <label className={styles.label}>{`新しいパスワード`}</label>
          <InputPassword
            sizeType='md'
            value={passwordPattern}
            onChange={(e) => setPasswordPattern(e.currentTarget.value)}
            onFocus={() => setTouched({ passwordPattern: true })}
            isError={touched.passwordPattern && !!passwordPatternError}
          />
          <div className={styles.errorText}>
            {touched.passwordPattern && !!passwordPatternError && 'new password error'}
          </div>
        </div>
        <div className={styles.item}>
          <label className={styles.label}>{`パスワードの確認`}</label>
          <InputPassword
            sizeType='md'
            value={newPassword}
            onChange={(e) => setNewPassword(e.currentTarget.value)}
            onFocus={() => setTouched({ password: true })}
            isError={touched.password && !!newPasswordError}
          />
          <div className={styles.errorText}>{touched.password && !!newPasswordError && 'confirm password error'}</div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleCreateClick} disabled={!!isError}>
          {`変更`}
        </Button>
      </div>
    </div>
  )
}, 'パスワードの変更')
