import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { blueprintAction } from 'src/redux/blueprint/blueprint.action'
import { datasetAction } from 'src/redux/dataset/dataset.action'
import { datasetSelector } from 'src/redux/dataset/dataset.state'
import { projectAction } from 'src/redux/project/project.action'
import { projectSelector } from 'src/redux/project/project.state'
import { softwareInfoSelector } from 'src/redux/software-info/software-info.state'
import { toastTool } from 'src/tool/toast.tool'
import { ProjectTypesEnum } from 'src/type/project.type'
import { ProjectPage } from './project.page'

export const ProjectContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { projectId } = useParams<{ projectId: string }>()

  const availableTopics = useSelector(softwareInfoSelector.availableTopics)
  const targetProject = useSelector(projectSelector.projectById(+(projectId ?? 0)))
  const isSelectedProjectDatasets = useSelector(datasetSelector.isDatasets)

  useEffect(() => {
    if (targetProject) {
      if (targetProject.projectResult.length > 0 || !availableTopics || availableTopics.includes(targetProject.type)) {
        if (projectId) {
          dispatch(projectAction.setSelectedProjectId({ projectId: +projectId }))
          dispatch(datasetAction.fetchDatasets.request({ projectId: +projectId }))
          dispatch(projectAction.fetchProjectDetail.request({ id: +projectId }))

          if (targetProject.projectResult.length > 0 && targetProject.type !== ProjectTypesEnum.CORP_SALES_INSIGHTS) {
            dispatch(projectAction.fetchProjectResultSegments.request({ id: +projectId }))
            dispatch(projectAction.fetchProjectResultTargets.request({ id: +projectId, limit: 20 }))
          }

          dispatch(projectAction.fetchProjectGoal.request({ projectType: targetProject.type }))
          dispatch(blueprintAction.fetchBlueprint.request({ projectType: targetProject.type }))
        }
      } else {
        toastTool.errorString('You did not subscribe this topic.')
        navigate('/')
      }
    } else {
      toastTool.errorString('Wrong Project Id !!')
      navigate('/')
    }

    return () => {
      dispatch(projectAction.initSelectedProjectId())
      dispatch(datasetAction.init())
      dispatch(blueprintAction.init())
    }
  }, [targetProject])

  return <>{targetProject && isSelectedProjectDatasets && <ProjectPage project={targetProject} />}</>
}
