export enum ReduxStatusEnum {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
}

export type OptionType<T = any> = {
  label: string
  value: T
}

export type CommonIdType = {
  id: number
}
