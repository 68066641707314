import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { Input } from 'src/component/atom/input/input'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { userAction } from 'src/redux/user/user.action'
import { userSelector } from 'src/redux/user/user.state'
import { ReduxStatusEnum } from 'src/type/common.type'
import { UserType } from 'src/type/user.type'
import styles from './change-nickname.scss'

type PropsType = {
  user: UserType
  onClose(): void
}

export const ChangeNicknameModal = withModalWrapper(({ user, onClose }: PropsType) => {
  const dispatch = useDispatch()

  const [userNickname, setUserNickname] = useState('')
  const [changeClick, setChangeClick] = useState(false)

  const userStatus = useSelector(userSelector.status)

  useEffect(() => {
    if (changeClick && userStatus === ReduxStatusEnum.IDLE) {
      onClose && onClose()
    }
  }, [userStatus])

  function handleCreateClick() {
    setChangeClick(true)
    dispatch(userAction.editUserCommon.request({ userId: user.id, userNickname }))
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <label className={styles.label}>{`User Nickname`}</label>
        <Input sizeType='md' value={userNickname} onChange={(e) => setUserNickname(e.currentTarget.value)} />
      </div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleCreateClick} disabled={!userNickname}>
          {`Change`}
        </Button>
      </div>
    </div>
  )
}, 'Change Nickname')
