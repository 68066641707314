import classNames from 'classnames'
import React from 'react'
import { Input, InputPropsType } from 'src/component/atom/input/input'
import styles from './input-with.scss'

type PropsType = {
  styleWidth?: number
  prefix?: string
  suffix?: string
  sizeType?: 'xs' | 'sm' | 'md' | 'lg'
  isFullWidth?: boolean
  isDarkDisabled?: boolean
  disabled?: boolean
} & InputPropsType

export const InputWith = ({
  styleWidth,
  prefix,
  suffix,
  sizeType,
  isFullWidth,
  isDarkDisabled,
  disabled,
  ...props
}: PropsType) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.xsSize]: sizeType === 'xs',
        [styles.smSize]: sizeType === 'sm',
        [styles.mdSize]: sizeType === 'md',
        [styles.lgSize]: sizeType === 'lg',
        [styles.fullWidth]: isFullWidth,
        [styles.isDarkDisabled]: isDarkDisabled,
        [styles.disabled]: disabled,
      })}
      style={{ width: styleWidth }}
    >
      {prefix && <div className={styles.prefix}>{prefix}</div>}
      <Input
        {...props}
        sizeType={sizeType}
        borderType='none'
        isFullWidth={isFullWidth}
        isDarkDisabled={isDarkDisabled}
        disabled={disabled}
      />
      {suffix && <div className={styles.suffix}>{suffix}</div>}
    </div>
  )
}
