import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { useDispatch } from 'react-redux'
import { Checkbox } from 'src/component/atom/checkbox/checkbox'
import { AddBranchModal } from 'src/modal/branch/add-branch.modal'
import { DeleteBranchesModal } from 'src/modal/delete-branches/delete-branches.modal'
import { branchAction } from 'src/redux/branch/branch.action'
import IconPuls from 'src/style/icon/icon-plus.svg'
import IconTrash2 from 'src/style/icon/icon-trash2.svg'
import imageFile from 'src/style/image/image-file.svg'
import { toastTool } from 'src/tool/toast.tool'
import { BranchType } from 'src/type/branch.type'
import { BranchItem } from './branch-item/branch-item'
import styles from './branch.scss'

const acceptFileExtension = ['.csv']

type PropsType = {
  branches: BranchType[]
}
export const BranchPanel = ({ branches }: PropsType) => {
  const dispatch = useDispatch()

  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [checkedBranches, setCheckedBranches] = useState<{ [key: string]: boolean }>({})
  const [drag, setDrag] = React.useState<boolean>(false)

  const allChecked = React.useMemo(
    () => Object.values(checkedBranches).every((checked) => !!checked),
    [checkedBranches],
  )

  const checkedBranchIds = useMemo(() => {
    return Object.entries(checkedBranches)
      .filter(([, value]) => !!value)
      .map(([key]) => +key)
  }, [checkedBranches])

  useEffect(() => {
    setCheckedBranches(
      branches.reduce<{ [key: string]: boolean }>((res, branch) => {
        res[branch.id] = checkedBranches[branch.id]
        return res
      }, {}),
    )
  }, [branches])

  function handleClickAdd() {
    setIsAddModalVisible(true)
  }

  function handleClickDelete() {
    setIsDeleteModalVisible(true)
  }

  function handleAllCheckClick(e: React.ChangeEvent<HTMLInputElement>) {
    setCheckedBranches(
      branches.reduce<{ [key: string]: boolean }>((res, option) => {
        res[option.id] = e.currentTarget.checked
        return res
      }, {}),
    )
  }

  function uploadFile(files: FileList) {
    const fileExtention = '.' + files[0].name.split('.').pop()?.toLowerCase()

    if (!acceptFileExtension.includes(fileExtention)) {
      toastTool.errorString('Wrong file extextion!')
    } else {
      dispatch(branchAction.createBranches.request({ file: files[0] }))
    }
  }

  function handleDropFile(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      uploadFile(e.dataTransfer.files)
      e.dataTransfer.clearData()
    }
    setDrag(false)
  }

  function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation()
    setDrag(true)
  }

  function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation()
    setDrag(false)
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
  }

  function handleChangeUploadFile(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.currentTarget.files
    if (files) {
      uploadFile(files)
    }
  }

  function handleDownloadButtonClick() {
    dispatch(branchAction.downloadBranchCodeTemplate.request())
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>{`支店一覧`}</div>
        <div className={styles.buttonWrapper}>
          <div className={styles.actionButton} onClick={handleClickAdd}>
            <InlineSVG src={IconPuls} className={styles.addIcon} />
            <div className={styles.buttonLabel}>{`追加`}</div>
          </div>
          <div className={styles.actionButton} onClick={handleClickDelete}>
            <InlineSVG src={IconTrash2} className={styles.deleteIcon} />
            <div className={styles.buttonLabel}>{`削除`}</div>
          </div>
        </div>
      </div>
      <div className={styles.bodyArea}>
        <div className={styles.branchArea}>
          <div className={styles.listHeader}>
            <div className={styles.checkboxCell}>
              <Checkbox checked={allChecked} onChange={handleAllCheckClick} />
            </div>
            <div>{`支店コード`}</div>
            <div>{`支店名`}</div>
          </div>
          <div className={styles.list}>
            {branches.length > 0 ? (
              branches.map((branch) => (
                <BranchItem
                  key={branch.id}
                  branch={branch}
                  checked={!!checkedBranches[branch.id]}
                  onChangeCheckbox={(e) =>
                    setCheckedBranches({ ...checkedBranches, [branch.id]: e.currentTarget.checked })
                  }
                />
              ))
            ) : (
              <div className={styles.fileArea} onDragEnter={handleDragEnter}>
                <InlineSVG src={imageFile} />
                <div className={styles.dragInfoText}>
                  <span className={styles.info}>{`クリックしてファイルをアップロード`}</span>
                  <span className={styles.info}>{`またはドラック＆ドロップ`}</span>
                </div>
                <label className={styles.fileSelectButton}>
                  <input
                    type='file'
                    accept={acceptFileExtension.join(', ')}
                    multiple={false}
                    onChange={handleChangeUploadFile}
                  />
                  {`ファイルを選択`}
                </label>
                <div className={styles.downloadButton} onClick={handleDownloadButtonClick}>
                  <span>{`テンプレートをダウンロード`}</span>
                </div>
                <div
                  className={classNames(styles.onDrag, { [styles.visible]: drag })}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDropFile}
                  onDragOver={handleDragOver}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <AddBranchModal modalVisible={isAddModalVisible} onClose={() => setIsAddModalVisible(false)} />
      <DeleteBranchesModal
        targetBranchIds={checkedBranchIds}
        modalVisible={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
      />
    </div>
  )
}
