import { createAction, createReducer } from '@reduxjs/toolkit'
import { TaskErrorType, TaskProgressType, TaskSuccessType } from 'src/type/task.type'
import { createAsyncAction } from '../redux.type'

type TaskStateType = {
  taskProgresses: TaskProgressType[]
}

const eventConnectionOpen = createAction<number>('[task] web socket connect')
const eventConnectionClose = createAction('[task] web socket close')

const fetchTasks = createAsyncAction('[task] fetch tasks')
const setTasks = createAction<TaskProgressType[]>('[task] set tasks')

const setTaskSuccess = createAction<TaskSuccessType>('[task] task success')
const removeTaskSuccess = createAction<TaskSuccessType>('[task] remove success task')
const setTaskProgress = createAction<TaskProgressType[]>('[task] task progress')
const setTaskError = createAction<TaskErrorType>('[task] task error')

export const taskAction = {
  eventConnectionOpen,
  eventConnectionClose,

  fetchTasks,
  setTasks,

  setTaskSuccess,
  removeTaskSuccess,
  setTaskProgress,
  setTaskError,
}

const initTaskState: TaskStateType = {
  taskProgresses: [],
}

export const taskReducer = createReducer(initTaskState, (builder) =>
  builder
    .addCase(setTasks, (state, { payload }) => {
      state.taskProgresses = payload
    })
    .addCase(setTaskSuccess, (state, { payload }) => {
      state.taskProgresses = state.taskProgresses.filter((progress) => payload.taskId !== progress.taskId)
      state.taskProgresses.push({ ...payload, progress: { current: 100, total: 100 } })
    })
    .addCase(removeTaskSuccess, (state, { payload }) => {
      state.taskProgresses = state.taskProgresses.filter(
        (progress) =>
          !(payload.taskId === progress.taskId && payload.id === progress.id && payload.status === progress.status),
      )
    })
    .addCase(setTaskProgress, (state, { payload }) => {
      state.taskProgresses = payload
    })
    .addCase(setTaskError, (state, { payload }) => {
      state.taskProgresses = state.taskProgresses.filter((progress) => payload.taskId !== progress.taskId)
    }),
)
