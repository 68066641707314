import { CommonIdType, OptionType } from './common.type'
import { FieldType } from './dataset.type'
import { ProjectTypesEnum } from './project.type'

export enum BlueprintTypesEnum {
  KEY = 'KEY',
  SELECT = 'SELECT',
  SELECT_SELECT = 'SELECT_SELECT',
  SELECT_SINGLE = 'SELECT_SINGLE',
  SELECT_VALUE = 'SELECT_VALUE',
}

export enum BlueprintResourceTypesEnum {
  CUSTOMER = 'CUSTOMER',
  TRANSACTION = 'TRANSACTION',
  TARGET_CUSTOMER = 'TARGET_CUSTOMER',
  LOAN1 = 'LOAN1',
}

export type BlueprintType = {
  id: number
  createdAt: string
  updatedAt: string
  name: string
  isRequired: boolean
  projectType: ProjectTypesEnum
  type: BlueprintTypesEnum
  resourceType: BlueprintResourceTypesEnum
}

export type BlueprintMappingDetailType = {
  project: CommonIdType
  bluePrint: CommonIdType
  fields: FieldType[]
  value: number
  values: string[]
  createdAt: string
  updatedAt: string
}

export type UpdatedFieldMappingReturnType = {
  project: CommonIdType
  bluePrint: CommonIdType
  fields: CommonIdType[]
  value?: number
  values?: string[]
}

export type MappedOptionValueType = {
  project: CommonIdType
  bluePrint: CommonIdType
  selectedFieldOptions: OptionType[]
  value?: number
  values?: string[]
}

export type MappingInputType = {
  bluePrintId: number
  fieldIds: CommonIdType[]
  value?: number
  values?: string[]
}
