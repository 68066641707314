import { Action } from 'redux'
import { combineEpics, Epic } from 'redux-observable'
import { filter, switchMap, mergeMap, catchError, of, Observable, map } from 'rxjs'
import { httpErrorhandling } from '../redux.util'
import { softwareInfoAction } from './software-info.action'
import { softwareInfoService } from './software-info.service'

const pendingStartEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) => [softwareInfoAction.fetchSoftwareInfo.request.type].includes(action.type)),
    map(() => softwareInfoAction.increasePendingCount()),
  )

const pendingEndEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        softwareInfoAction.fetchSoftwareInfo.success.type,
        softwareInfoAction.fetchSoftwareInfo.failure.type,
        softwareInfoAction.fetchSoftwareInfo.cancelled.type,
      ].includes(action.type),
    ),
    map(() => softwareInfoAction.decreasePendingCount()),
  )

const pendingFailureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) => [softwareInfoAction.fetchSoftwareInfo.failure.type].includes(action.type)),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const fetchBlueprintEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(softwareInfoAction.fetchSoftwareInfo.request.match),
    switchMap(() =>
      softwareInfoService.fetchSoftwareInfo().pipe(
        mergeMap(({ response }) => [
          softwareInfoAction.fetchSoftwareInfo.success(),
          softwareInfoAction.setSoftwareInfo(response),
        ]),
        catchError((err) => of(softwareInfoAction.fetchSoftwareInfo.failure(err))),
      ),
    ),
  )

export const softwareInfoEpic = combineEpics(pendingStartEpic, pendingEndEpic, pendingFailureEpic, fetchBlueprintEpic)
