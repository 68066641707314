import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { Input } from 'src/component/atom/input/input'
import { branchAction } from 'src/redux/branch/branch.action'
import { branchSelector } from 'src/redux/branch/branch.state'
import { BranchType } from 'src/type/branch.type'
import { ReduxStatusEnum } from 'src/type/common.type'
import styles from './branch.modal.scss'

type PropsType = {
  branch?: BranchType
  onClose(): void
}

export const Branch = ({ branch, onClose }: PropsType) => {
  const dispatch = useDispatch()

  const [branchCode, setBranchCode] = useState(branch?.branchCode || '')
  const [branchName, setBranchName] = useState(branch?.branchName || '')
  const [changeClick, setChangeClick] = useState(false)

  const branchStatus = useSelector(branchSelector.status)
  const asyncSuccess = useSelector(branchSelector.asyncSuccess)

  useEffect(() => {
    if (changeClick && branchStatus === ReduxStatusEnum.IDLE) {
      onClose && onClose()
    }
  }, [asyncSuccess])

  useEffect(() => {
    return () => {
      dispatch(branchAction.setAsyncSuccess(false))
    }
  }, [])

  function handleCreateClick() {
    setChangeClick(true)
    if (branch) {
      dispatch(branchAction.editBranch.request({ branchId: branch.id, branchCode, branchName }))
    } else {
      dispatch(branchAction.createBranch.request({ branchCode, branchName }))
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <label className={styles.label}>{`支店コード`}</label>
        <Input sizeType='md' value={branchCode} onChange={(e) => setBranchCode(e.currentTarget.value)} />
        <label className={styles.label}>{`支店名`}</label>
        <Input sizeType='md' value={branchName} onChange={(e) => setBranchName(e.currentTarget.value)} />
      </div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleCreateClick} disabled={!branchCode}>
          {branch ? `変更` : '追加'}
        </Button>
      </div>
    </div>
  )
}
