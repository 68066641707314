import React, { useState } from 'react'
import { Button } from 'src/component/atom/button/button'
import { Checkbox } from 'src/component/atom/checkbox/checkbox'
import styles from './category-filter-values.scss'

type PropsType = {
  isCurrentField?: boolean
  selectedCategories?: string[]
  categories?: string[]
  onChange?: (values: string[]) => void
}

export const CategoryFilterValues = ({ isCurrentField, selectedCategories, categories = [], onChange }: PropsType) => {
  const [checkedConditions, setCheckedConditions] = useState<{ [key: string]: boolean }>(
    categories.reduce<{ [key: string]: boolean }>((res, option) => {
      res[option] = !!selectedCategories?.find((selectedCategory) => selectedCategory === option)
      return res
    }, {}),
  )
  const allChecked = React.useMemo(
    () => Object.values(checkedConditions).every((checked) => checked),
    [checkedConditions],
  )

  function handleAllCheckClick(e: React.ChangeEvent<HTMLInputElement>) {
    setCheckedConditions(
      categories.reduce<{ [key: string]: boolean }>((res, option) => {
        res[option] = e.currentTarget.checked
        return res
      }, {}),
    )
  }

  function handleClickButton() {
    const newCategoies = Object.entries(checkedConditions)
      .filter(([, checked]) => checked)
      .map(([categoryName]) => categoryName)

    newCategoies.length > 0 &&
      onChange &&
      onChange(
        Object.entries(checkedConditions)
          .filter(([, checked]) => checked)
          .map(([categoryName]) => categoryName),
      )
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>{`フィルター条件`}</div>
      <label className={styles.categoryItem}>
        <Checkbox checked={allChecked} onChange={handleAllCheckClick} />
        <span className={styles.categoryName}>ALL</span>
      </label>
      <div className={styles.catogoryArea}>
        {categories.map((category) => (
          <label key={category} className={styles.categoryItem}>
            <Checkbox
              value={category}
              checked={checkedConditions[category]}
              onChange={(e) => setCheckedConditions({ ...checkedConditions, [category]: e.currentTarget.checked })}
            />
            <span className={styles.categoryName}>{category}</span>
          </label>
        ))}
      </div>
      <div className={styles.footer}>
        <Button sizeType='sm' buttonColor='black' onClick={handleClickButton}>
          {isCurrentField ? `修正` : `設定`}
        </Button>
      </div>
    </div>
  )
}
