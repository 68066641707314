import React, { useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { ChartPackedBubble } from 'src/component/molecule/chart-bubble/chart-bubble'
import { SegmentItem } from 'src/component/molecule/segment-item/segment-item'
import { AddSegmentModal } from 'src/modal/segment/add-segment.modal'
import { datasetSelector } from 'src/redux/dataset/dataset.state'
import IconPlus from 'src/style/icon/icon-plus.svg'
import { DatasetTypesEnum, FieldTypesEnum } from 'src/type/dataset.type'
import { ProjectTypesEnum, SegmentType } from 'src/type/project.type'
import styles from './segment-info.scss'

type PropsType = {
  projectType: ProjectTypesEnum
  segments?: SegmentType[]
}
export const SegmentInfo = ({ projectType, segments }: PropsType) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const datasetWithDatasetTypes = useSelector(datasetSelector.datasetWithDatasetTypes)

  const datasetFields = useMemo(
    () =>
      datasetWithDatasetTypes[DatasetTypesEnum.TEST].fields?.map<{ type: FieldTypesEnum; name: string }>((field) => ({
        type: field.type,
        name: field.name,
      })) || [],
    [datasetWithDatasetTypes],
  )

  function handleCreateClick() {
    setModalVisible(true)
  }

  return (
    <div className={styles.root}>
      <div className={styles.segments}>
        {false && (
          <Button sizeType='xs' buttonColor='tertiary' onClick={handleCreateClick}>
            <InlineSVG className={styles.segmentBtnIcon} src={IconPlus} />
            <span className={styles.segmentBtnText}>{`セグメントを追加`}</span>
          </Button>
        )}
        <div className={styles.list}>
          {segments?.map((segment, idx) => (
            <SegmentItem
              key={segment.id}
              projectType={projectType}
              segmentIndex={idx + 1}
              segment={segment}
              datasetFields={datasetFields}
            />
          ))}
        </div>
      </div>
      <ChartPackedBubble segments={segments || []} />
      <AddSegmentModal
        modalVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        datasetFields={datasetFields}
      />
    </div>
  )
}
