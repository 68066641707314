import classNames from 'classnames'
import React, { useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import iconDropdown from 'src/style/icon/icon-dropdown.svg'
import styles from './expantion-panel.scss'

type PropsType = {
  title: string
  contentHeight: number
  initailExpantion?: boolean
  children?: React.ReactNode
}

export const ExpantionPanel = ({ title, contentHeight, initailExpantion = false, children }: PropsType) => {
  const [expantion, setExpantion] = useState(initailExpantion)

  return (
    <div className={styles.root}>
      <div className={styles.titleArea} onClick={() => setExpantion(!expantion)}>
        {title}
        <InlineSVG className={classNames(styles.dropdownIcon, { [styles.reversed]: expantion })} src={iconDropdown} />
      </div>
      <div className={classNames(styles.content)} style={{ height: expantion ? contentHeight : 0 }}>
        {children}
      </div>
    </div>
  )
}
