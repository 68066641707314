import { AuthTokenType } from 'src/type/auth.type'
import { httpApi } from 'src/util/http.module'

export type LoginParamsType = {
  username: string
  password: string
  rememberMe?: boolean
}
const login = (params: LoginParamsType) => httpApi.post<AuthTokenType>({ url: `/login`, body: params })

const logout = () => httpApi.post<AuthTokenType>({ url: `/logout` })

const refresh = () => httpApi.post<AuthTokenType>({ url: `/token` })

export const authService = {
  login,
  logout,
  refresh,
}
