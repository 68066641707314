import { Action } from 'redux'
import { Epic, combineEpics } from 'redux-observable'
import { Observable, catchError, filter, map, mergeMap, of, switchMap } from 'rxjs'
import { toastTool } from 'src/tool/toast.tool'
import { httpErrorhandling } from '../redux.util'
import { userAction } from './user.action'
import { userService } from './user.service'

const pendingStartEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        userAction.signup.request.type,
        userAction.fetchUser.request.type,
        userAction.editUserCommon.request.type,
        userAction.editUserPassword.request.type,
      ].includes(action.type),
    ),
    map(() => userAction.increasePendingCount()),
  )

const pendingEndEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        userAction.signup.success.type,
        userAction.signup.failure.type,
        userAction.signup.cancelled.type,
        userAction.fetchUser.success.type,
        userAction.fetchUser.failure.type,
        userAction.fetchUser.cancelled.type,
        userAction.editUserCommon.success.type,
        userAction.editUserCommon.failure.type,
        userAction.editUserCommon.cancelled.type,
        userAction.editUserPassword.success.type,
        userAction.editUserPassword.failure.type,
        userAction.editUserPassword.cancelled.type,
      ].includes(action.type),
    ),
    map(() => userAction.decreasePendingCount()),
  )

const pendingFailureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) =>
      [
        userAction.signup.failure.type,
        userAction.fetchUser.failure.type,
        userAction.editUserCommon.failure.type,
        userAction.editUserPassword.failure.type,
      ].includes(action.type),
    ),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const signupEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(userAction.signup.request.match),
    switchMap(({ payload }) =>
      userService.signup(payload).pipe(
        switchMap(({ response }) => [
          userAction.signup.success(),
          userAction.setRequestedSignUpUsername(response.username),
        ]),
        catchError((err) => of(userAction.signup.failure(err))),
      ),
    ),
  )

const fetchUserEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(userAction.fetchUser.request.match),
    switchMap(({ payload }) =>
      userService.fetchUser(payload).pipe(
        switchMap(({ response }) => [userAction.fetchUser.success(), userAction.setUser(response)]),
        catchError((err) => of(userAction.fetchUser.failure(err))),
      ),
    ),
  )

const editUserCommonEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(userAction.editUserCommon.request.match),
    switchMap(({ payload }) =>
      userService.editUserCommon(payload).pipe(
        switchMap(({ response }) => {
          toastTool.success('user info update success')
          return [userAction.editUserCommon.success(), userAction.setUser(response), userAction.setEditSuccess(true)]
        }),
        catchError((err) => of(userAction.editUserCommon.failure(err))),
      ),
    ),
  )

const editUserPasswordEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(userAction.editUserPassword.request.match),
    switchMap(({ payload }) =>
      userService.editUserPassword(payload).pipe(
        switchMap(() => {
          toastTool.success('user info update success')
          return [userAction.editUserPassword.success(), userAction.setEditSuccess(true)]
        }),
        catchError((err) => of(userAction.editUserPassword.failure(err))),
      ),
    ),
  )

export const userEpic = combineEpics(
  pendingStartEpic,
  pendingEndEpic,
  pendingFailureEpic,
  signupEpic,
  fetchUserEpic,
  editUserCommonEpic,
  editUserPasswordEpic,
)
