import React from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import IconExclamation from 'src/style/icon/icon-circle-exclamation.svg'
import styles from './tooltip.scss'

type PropsType = {
  children: React.ReactNode
}

export const Tooltip = ({ children }: PropsType) => {
  return (
    <div className={styles.root}>
      <InlineSVG className={styles.icon} src={IconExclamation} />
      <div className={styles.anchor}>
        <div className={styles.info}>{children}</div>
      </div>
    </div>
  )
}
