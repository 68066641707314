import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { Input } from 'src/component/atom/input/input'
import { DropdownSingleOptions } from 'src/component/molecule/dropdown-single-options/dropdown-single-options'
import { useVisibleWithOutsideClick } from 'src/hook/use-visible-with-outside-click'
import iconCircleCheck from 'src/style/icon/icon-circle-check.svg'
import iconDropdown from 'src/style/icon/icon-dropdown.svg'
import { OptionType } from 'src/type/common.type'
import styles from './dropdown-single-selector.scss'

type PropsType = {
  options: OptionType[]
  selectedOption?: OptionType
  placeholder?: string
  sizeType?: 'sm' | 'md' | 'lg'
  widthType?: 'sm' | 'md' | 'lg'
  isNeededFilter?: boolean
  selectedVisible?: boolean
  isOpenUpside?: boolean
  isDisselectable?: boolean
  isDarkDisabled?: boolean
  disabled?: boolean
  onChange?(option: OptionType): void
  onFocus?(e: React.FocusEvent<HTMLDivElement, Element>): void
}

export const DropdownSingleSelector = ({
  options,
  selectedOption,
  placeholder,
  sizeType,
  widthType,
  isNeededFilter,
  selectedVisible,
  isOpenUpside,
  isDisselectable,
  isDarkDisabled,
  disabled,
  onChange,
  onFocus,
}: PropsType) => {
  const [innerSelectedOption, setInnerSelectedOption] = useState<OptionType>()
  const { ref, isVisible, setIsVisible } = useVisibleWithOutsideClick<HTMLDivElement>(false)
  const [filterString, setFilterString] = React.useState<string>('')

  const filteredOptions = useMemo(() => {
    const lowerCaseFilterString = filterString.toLowerCase()

    return isNeededFilter
      ? options.filter((option) => option.label.toLowerCase().includes(lowerCaseFilterString))
      : options
  }, [options, filterString])

  useEffect(() => {
    setInnerSelectedOption(selectedOption)
    setFilterString(selectedOption?.label || '')
  }, [selectedOption])

  useEffect(() => {
    if (isVisible) {
      ref.current?.scrollIntoView({
        block: 'center',
      })
      if (isNeededFilter) {
        setFilterString('')
      }
    }
    if (!isVisible) {
      if (innerSelectedOption) {
        setFilterString(innerSelectedOption.label)
      } else {
        setFilterString(selectedOption?.label || '')
      }
    }
  }, [isVisible])

  function handleOptionChange(option: OptionType) {
    setInnerSelectedOption(option)
    setIsVisible(false)
    onChange && onChange(option)
  }

  return (
    <div ref={ref} className={styles.root} tabIndex={0} onFocus={onFocus}>
      <div
        className={classNames(styles.control, {
          [styles.smSize]: sizeType === 'sm',
          [styles.mdSize]: sizeType === 'md',
          [styles.lgSize]: sizeType === 'lg',
          [styles.smWidth]: widthType === 'sm',
          [styles.mdWidth]: widthType === 'md',
          [styles.lgWidth]: widthType === 'lg',
          [styles.selectedVisible]: selectedVisible,
          [styles.active]: isVisible,
          [styles.isDarkDisabled]: isDarkDisabled,
          [styles.disabled]: disabled,
        })}
        onClick={() => !disabled && setIsVisible(!isVisible)}
      >
        <Input
          borderType='none'
          sizeType={sizeType}
          placeholder={isVisible ? 'Filter by name' : placeholder}
          isFullWidth
          value={filterString}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            setFilterString(e.currentTarget.value)
          }}
          readOnly={!isNeededFilter}
          onFocus={() => setIsVisible(true)}
          disabled={disabled}
        />
        {selectedVisible && (
          <InlineSVG
            className={classNames(styles.selectedIcon, { [styles.notSelectedIcon]: !innerSelectedOption })}
            src={iconCircleCheck}
          />
        )}
        <InlineSVG className={classNames(styles.dropdownIcon, { [styles.reversed]: isVisible })} src={iconDropdown} />
      </div>
      {isVisible && (
        <DropdownSingleOptions
          options={filteredOptions}
          selectedOption={innerSelectedOption}
          sizeType={sizeType}
          active={isVisible}
          isOpenUpside={isOpenUpside}
          isDisselectable={isDisselectable}
          onChange={handleOptionChange}
        />
      )}
    </div>
  )
}
