import classNames from 'classnames'
import React, { useRef, useEffect } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import iconCheck from 'src/style/icon/icon-check.svg'
import { OptionType } from 'src/type/common.type'
import styles from './vertical-option-item.scss'

type PropsType = {
  option: OptionType
  active: boolean
  onClick?(option: OptionType): void
}

export const VerticalOptionItem = ({ option, active, onClick }: PropsType) => {
  const itemRef = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (active && itemRef.current) {
      itemRef.current.scrollIntoView({
        block: 'center',
      })
    }
  }, [active, itemRef.current])

  return (
    <li
      ref={itemRef}
      className={classNames(styles.root, {
        [styles.active]: active,
      })}
      onClick={() => onClick && onClick(option)}
    >
      <span>{option.label}</span>
      {active && <InlineSVG src={iconCheck} />}
    </li>
  )
}
