import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChartBarCompare } from 'src/component/molecule/chart-bar-compare/chart-bar-compare'
import { ChartBarMonthly } from 'src/component/molecule/chart-bar-monthly/chart-bar-monthly'
import { ChartLine } from 'src/component/molecule/chart-line/chart-line'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { dashboardIndustryDataIntl } from 'src/intl/data/dashboard.key'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { dashboardSelector } from 'src/redux/dashboard/dashboard.state'
import { OptionType } from 'src/type/common.type'
import styles from './industry-trand.scss'

export const IndustryTrand = () => {
  const dispatch = useDispatch()

  const dashcboardIndustry = useSelector(dashboardSelector.dashboardIndustry)
  const corporateTypeOptions = useSelector(dashboardSelector.dsContractCorporateTypeOptions)
  const industry1Options = useSelector(dashboardSelector.dsContractIndustry1Options)
  const industry2Options = useSelector(dashboardSelector.dsContractIndustry2Options)
  const industry3Options = useSelector(dashboardSelector.dsContractIndustry3Options)
  const selectedCorporateType = useSelector(dashboardSelector.selectedCorporateType)
  const selectedIndustry1 = useSelector(dashboardSelector.selectedIndustry1)
  const selectedIndustry2 = useSelector(dashboardSelector.selectedIndustry2)
  const selectedIndustry3 = useSelector(dashboardSelector.selectedIndustry3)
  const unspecifiedIndustryCompanyCount = useSelector(dashboardSelector.unspecifiedIndustryCompanyCount)
  const unspecifiedIndustryCompanyRate = useSelector(dashboardSelector.unspecifiedIndustryCompanyRate)

  useEffect(() => {
    dispatch(dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.request({ branchCode: 'allBranches' }))
  }, [])

  const industry1IntlOptions = useMemo(() => {
    return industry1Options.map<OptionType>((bc) => ({
      label: dashboardIndustryDataIntl[bc.value] || bc.value,
      value: bc.value,
    }))
  }, [industry1Options])

  const industry2IntlOptions = useMemo(() => {
    return industry2Options.map<OptionType>((bc) => ({
      label: dashboardIndustryDataIntl[bc.value] || bc.value,
      value: bc.value,
    }))
  }, [industry2Options])

  const industry3IntlOptions = useMemo(() => {
    return industry3Options.map<OptionType>((bc) => ({
      label: dashboardIndustryDataIntl[bc.value] || bc.value,
      value: bc.value,
    }))
  }, [industry3Options])

  const selectedCorporateTypeOption = useMemo(
    () => ({ label: selectedCorporateType, value: selectedCorporateType }),
    [selectedCorporateType],
  )
  const selectedIndustry1Option = useMemo(
    () => ({ label: dashboardIndustryDataIntl[selectedIndustry1] || selectedIndustry1, value: selectedIndustry1 }),
    [selectedIndustry1],
  )
  const selectedIndustry2Option = useMemo(
    () => ({ label: dashboardIndustryDataIntl[selectedIndustry2] || selectedIndustry2, value: selectedIndustry2 }),
    [selectedIndustry2],
  )
  const selectedIndustry3Option = useMemo(
    () => ({ label: dashboardIndustryDataIntl[selectedIndustry3] || selectedIndustry3, value: selectedIndustry3 }),
    [selectedIndustry3],
  )

  function handleChangeSelectCorporateType(option: OptionType<string>) {
    dispatch(dashboardAction.setDashboardIndustryFilterIndustry1s({ industry1Items: [] }))
    dispatch(dashboardAction.setDashboardIndustryFilterIndustry2s({ industry2Items: [] }))
    dispatch(dashboardAction.setDashboardIndustryFilterIndustry3s({ industry3Items: [] }))
    dispatch(dashboardAction.setSelectedCorporateType(option.value))
  }

  function handleChangeSelectIndustry1(option: OptionType<string>) {
    dispatch(dashboardAction.setDashboardIndustryFilterIndustry2s({ industry2Items: [] }))
    dispatch(dashboardAction.setDashboardIndustryFilterIndustry3s({ industry3Items: [] }))
    if (option) {
      dispatch(dashboardAction.setSelectedIndustry1(option.value))
      dispatch(
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.request({
          corporateType: selectedCorporateType,
          industry1: option.value,
        }),
      )
    } else {
      dispatch(dashboardAction.setSelectedIndustry1(''))
    }
  }

  function handleChangeSelectIndustry2(option: OptionType<string> | undefined) {
    dispatch(dashboardAction.setDashboardIndustryFilterIndustry3s({ industry3Items: [] }))
    if (option) {
      dispatch(dashboardAction.setSelectedIndustry2(option.value))
      dispatch(
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.request({
          corporateType: selectedCorporateType,
          industry1: selectedIndustry1,
          industry2: option.value,
        }),
      )
    } else {
      dispatch(dashboardAction.setSelectedIndustry2(''))
    }
  }

  function handleChangeSelectIndustry3(option: OptionType<string> | undefined) {
    if (option) {
      dispatch(dashboardAction.setSelectedIndustry3(option.value))
    } else {
      dispatch(dashboardAction.setSelectedIndustry3(''))
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.tabContentHeader}>
        <div className={styles.tabContentHeaderIndustryController}>
          <div>{`業種`}</div>
          <DropdownSingleSelector
            sizeType='lg'
            isNeededFilter
            placeholder={industry1IntlOptions.length === 0 ? '業種データなし' : '業種を選択'}
            disabled={industry1IntlOptions.length === 0}
            options={industry1IntlOptions}
            selectedOption={selectedIndustry1Option}
            onChange={handleChangeSelectIndustry1}
          />
          <DropdownSingleSelector
            sizeType='lg'
            isNeededFilter
            placeholder={industry2IntlOptions.length === 0 ? '業種データなし' : '業種を選択'}
            disabled={industry2IntlOptions.length === 0}
            options={industry2IntlOptions}
            selectedOption={selectedIndustry2Option}
            onChange={handleChangeSelectIndustry2}
          />
          <DropdownSingleSelector
            sizeType='lg'
            isNeededFilter
            placeholder={industry3IntlOptions.length === 0 ? '業種データなし' : '業種を選択'}
            disabled={industry3IntlOptions.length === 0}
            options={industry3IntlOptions}
            selectedOption={selectedIndustry3Option}
            onChange={handleChangeSelectIndustry3}
          />
        </div>
        <div className={styles.tabContentHeaderCorpTypeController}>
          <div>{`法人格`}</div>
          <DropdownSingleSelector
            sizeType='lg'
            isNeededFilter={false}
            placeholder={corporateTypeOptions.length === 0 ? '法人格データなし' : '法人格を選択'}
            disabled={corporateTypeOptions.length === 0}
            options={corporateTypeOptions}
            selectedOption={selectedCorporateTypeOption}
            onChange={handleChangeSelectCorporateType}
          />
        </div>
      </div>
      <div className={styles.isUnspecifiedIndustry}>
        {unspecifiedIndustryCompanyCount > 0 &&
          `法人および個人事業主全体のうち、${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(unspecifiedIndustryCompanyCount)}社 (${new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: 2,
          }).format(
            unspecifiedIndustryCompanyRate,
          )}%)にて業種情報が欠落しています。より安定的な分析結果を得るために業種情報を追加した上でデータアップロードすることを推奨します。`}
      </div>
      <div className={styles.firstChartTitle}>{`融資実行トレンド`}</div>
      <div className={styles.chartWrapper}>
        <ChartBarMonthly
          title='融資1実行件数'
          yAxisTitle='件'
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartIndustryLoan1Count?.xValues}
          series={dashcboardIndustry?.chartIndustryLoan1Count?.series}
        />
        <ChartBarMonthly
          title='融資2実行件数'
          yAxisTitle='件'
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartIndustryLoan2Count?.xValues}
          series={dashcboardIndustry?.chartIndustryLoan2Count?.series}
        />
        <ChartBarMonthly
          title='融資1実行金額（単位：百万円）'
          yAxisTitle='百万円'
          yUnit={1000000}
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartIndustryLoan1Amount?.xValues}
          series={dashcboardIndustry?.chartIndustryLoan1Amount?.series}
        />
        <ChartBarMonthly
          title='融資2実行金額（単位：百万円）'
          yAxisTitle='百万円'
          yUnit={1000000}
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartIndustryLoan2Amount?.xValues}
          series={dashcboardIndustry?.chartIndustryLoan2Amount?.series}
        />
      </div>
      <div className={styles.chartTitle}>{`資金トレンド`}</div>
      <div className={styles.chartWrapper}>
        <ChartBarMonthly
          title='資金がなくなるまでの猶予期間（単位：か月）'
          subTitle='※ 月末残高÷直近6か月の月次平均出金額にて計算。'
          minYvalue={0}
          valueDecimals={0}
          yAxisTitle='か月'
          xTickValues={dashcboardIndustry?.chartRunway?.xValues}
          series={dashcboardIndustry?.chartRunway?.series}
        />
        <ChartLine
          title='残高推移（単位：千円）'
          yAxis1Title='千円(業種)'
          yAxis2Title='千円(全体)'
          y1Unit={1000}
          y2Unit={1000}
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartBalanceTrend?.xValues}
          series={dashcboardIndustry?.chartBalanceTrend?.series}
        />
        <ChartLine
          title='月次入金額の推移（単位：千円）'
          yAxis1Title='千円(業種)'
          yAxis2Title='千円(全体)'
          y1Unit={1000}
          y2Unit={1000}
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartDepositTrend?.xValues}
          series={dashcboardIndustry?.chartDepositTrend?.series}
        />
        <ChartLine
          title='月次出金額の推移（単位：千円）'
          yAxis1Title='千円(業種)'
          yAxis2Title='千円(全体)'
          y1Unit={1000}
          y2Unit={1000}
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartWithdrawalTrend?.xValues}
          series={dashcboardIndustry?.chartWithdrawalTrend?.series}
        />
        <ChartLine
          title='月次損益（月次入金-月次出金）（単位：千円）'
          yAxis1Title='千円(業種)'
          yAxis2Title='千円(全体)'
          y1Unit={1000}
          y2Unit={1000}
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartGain?.xValues}
          series={dashcboardIndustry?.chartGain?.series}
        />
      </div>
      <div className={styles.chartTitle}>{`経営環境トレンド`}</div>
      <div className={styles.chartWrapper}>
        <ChartBarCompare
          title='代表者年齢'
          yAxisTitle='年齢'
          xTickValues={dashcboardIndustry?.chartCeoAge?.xValues}
          series={dashcboardIndustry?.chartCeoAge?.series}
        />
        <ChartLine
          title='給与支払額の推移（単位：千円）'
          yAxis1Title='千円(業種)'
          yAxis2Title='千円(全体)'
          y1Unit={1000}
          y2Unit={1000}
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartSalary?.xValues}
          series={dashcboardIndustry?.chartSalary?.series}
        />
        <ChartLine
          title='社会保険料支払額の推移（単位：千円）'
          yAxis1Title='千円(業種)'
          yAxis2Title='千円(全体)'
          y1Unit={1000}
          y2Unit={1000}
          valueDecimals={0}
          xTickValues={dashcboardIndustry?.chartInsuranceFee?.xValues}
          series={dashcboardIndustry?.chartInsuranceFee?.series}
        />
        <ChartLine
          title='法人税の支払い比率（%)'
          subTitle='※ 月次損益対比での法人税の支払額の比率'
          yAxis1Title='%'
          valueDecimals={2}
          minYvalue={0}
          xTickValues={dashcboardIndustry?.chartCorporateTax?.xValues}
          series={dashcboardIndustry?.chartCorporateTax?.series}
        />
      </div>
    </div>
  )
}
