import classNames from 'classnames'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './nav-item-text.scss'

type PropsType = {
  navigationPath: string
  disabledClick?: boolean
  children?: React.ReactNode
}

export const NavItemText = ({ navigationPath, disabledClick, children }: PropsType) => {
  const [isHover, setIsHover] = useState(false)

  function handleNavLinkClick(e: React.MouseEvent) {
    disabledClick && e.preventDefault()
  }

  function handleMouseEnter() {
    setIsHover(true)
  }

  function handleMouseLeave() {
    setIsHover(false)
  }

  return (
    <NavLink
      className={({ isActive }) =>
        classNames(styles.root, {
          [styles.hover]: !disabledClick && isHover,
          [styles.isDisabledClick]: disabledClick,
          [styles.isActive]: isActive,
        })
      }
      to={navigationPath}
      onClick={handleNavLinkClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </NavLink>
  )
}
