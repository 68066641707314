import { createAction, createReducer } from '@reduxjs/toolkit'
import { UserType } from 'src/type/user.type'
import { createAsyncAction } from '../redux.type'
import {
  EditUserCommonParamsType,
  EditUserPasswordParamsType,
  FetchUserParamsType,
  SignupParamsType,
} from './user.service'

type UserStateType = {
  pendingCount: number
  requestedSignUpUsername: string | null
  user: UserType | null
  editSuccess: boolean
}

const increasePendingCount = createAction('[user] increase async action pending count')
const decreasePendingCount = createAction('[user] dencrease async action pending count')
const signup = createAsyncAction<SignupParamsType>('[user] sign up')
const setRequestedSignUpUsername = createAction<string | null>('[user] set requested sign up username')
const fetchUser = createAsyncAction<FetchUserParamsType>('[user] fetch user')
const setUser = createAction<UserType | null>('[user] set user')
const editUserCommon = createAsyncAction<EditUserCommonParamsType>('[user] edit user common')
const editUserPassword = createAsyncAction<EditUserPasswordParamsType>('[user] edit user password')
const setEditSuccess = createAction<boolean>('[user] set user edit success')

export const userAction = {
  increasePendingCount,
  decreasePendingCount,
  signup,
  setRequestedSignUpUsername,
  fetchUser,
  setUser,
  editUserCommon,
  editUserPassword,
  setEditSuccess,
}

const initUserState: UserStateType = {
  pendingCount: 0,
  requestedSignUpUsername: null,
  user: null,
  editSuccess: false,
}

export const userReducer = createReducer(initUserState, (builder) =>
  builder
    .addCase(increasePendingCount, (state) => {
      state.pendingCount++
    })
    .addCase(decreasePendingCount, (state) => {
      state.pendingCount--
    })
    .addCase(setRequestedSignUpUsername, (state, { payload }) => {
      state.requestedSignUpUsername = payload
    })
    .addCase(setUser, (state, { payload }) => {
      state.user = payload
    })
    .addCase(setEditSuccess, (state, { payload }) => {
      state.editSuccess = payload
    }),
)
