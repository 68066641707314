import { createSelector } from '@reduxjs/toolkit'
import { ReduxStatusEnum } from 'src/type/common.type'
import { RootStateType } from '../root.reducer'

const branchState = (state: RootStateType) => state.branchState
const status = createSelector(branchState, (state) =>
  state.pendingCount > 0 ? ReduxStatusEnum.PENDING : ReduxStatusEnum.IDLE,
)
const asyncSuccess = createSelector(branchState, (state) => state.asyncSuccess)
const branchRecords = createSelector(branchState, (state) => state.branchRecords ?? {})
const branchRecordsByCode = createSelector(branchState, (state) => state.branchRecordsByCode ?? {})
const branchIds = createSelector(branchState, (state) => state.branchIds ?? [])
const branches = createSelector(branchIds, branchRecords, (ids, records) => {
  return ids.map((id) => records[id])
})

export const branchSelector = {
  status,
  asyncSuccess,
  branchRecords,
  branchRecordsByCode,
  branchIds,
  branches,
}
