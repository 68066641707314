import React from 'react'
import { Button } from 'src/component/atom/button/button'
import { Input } from 'src/component/atom/input/input'
import { RangeSlider } from 'src/component/molecule/range-slider/range-slider'
import styles from './number-filter-values.scss'

type PropsType = {
  isCurrentField?: boolean
  min?: number
  max?: number
  defaultValue?: number[]
  step?: number
  disabled?: boolean
  onChange?: (range: number[][]) => void
}

export const NumberFilterValues = ({
  isCurrentField,
  min = 0,
  max = 100,
  defaultValue,
  disabled,
  onChange,
}: PropsType) => {
  // TODO: refactor controlling set value when onBlur into Input component and seperate component (number, text..)
  const [changingValue0, setChangingValue0] = React.useState(defaultValue ? defaultValue[0] : min)
  const [value0, setValue0] = React.useState(defaultValue ? defaultValue[0] : min)
  const [changingValue1, setChangingValue1] = React.useState(defaultValue ? defaultValue[1] : max)
  const [value1, setValue1] = React.useState(defaultValue ? defaultValue[1] : max)

  function handleChangeValue0(e: React.ChangeEvent<HTMLInputElement>) {
    setChangingValue0(+e.currentTarget.value)
  }

  function handleChangeValue1(e: React.ChangeEvent<HTMLInputElement>) {
    setChangingValue1(+e.currentTarget.value)
  }

  function handleBlurValue0(e: React.ChangeEvent<HTMLInputElement>) {
    controlValue0(+e.currentTarget.value)
  }

  function handleBlurValue1(e: React.ChangeEvent<HTMLInputElement>) {
    controlValue1(+e.currentTarget.value)
  }

  function handleEnterKeyDownValue0(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      controlValue0(+e.currentTarget.value)
    }
  }

  function handleEnterKeyDownValue1(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      controlValue1(+e.currentTarget.value)
    }
  }

  function controlValue0(value: number) {
    if (value > value1) {
      setValue0(value1)
      setChangingValue0(value1)
    } else if (value < min) {
      setValue0(min)
      setChangingValue0(min)
    } else {
      setValue0(value)
    }
  }

  function controlValue1(value: number) {
    if (value < value0) {
      setValue1(value0)
      setChangingValue1(value0)
    } else if (value > max) {
      setValue1(max)
      setChangingValue1(max)
    } else {
      setValue1(value)
    }
  }

  function handleChangeSlider(ranges: number[]) {
    setValue0(ranges[0])
    setChangingValue0(ranges[0])
    setValue1(ranges[1])
    setChangingValue1(ranges[1])
  }

  function handleClickButton() {
    onChange && onChange([[value0, value1]])
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>{`フィルター条件`}</div>
      <div className={styles.inputArea}>
        <Input
          type='number'
          sizeType='sm'
          borderType='default'
          widthType='lg'
          value={changingValue0}
          onChange={handleChangeValue0}
          onBlur={handleBlurValue0}
          onKeyDown={handleEnterKeyDownValue0}
        />
        <span>~</span>
        <Input
          type='number'
          sizeType='sm'
          borderType='default'
          widthType='lg'
          value={changingValue1}
          onChange={handleChangeValue1}
          onBlur={handleBlurValue1}
          onKeyDown={handleEnterKeyDownValue1}
        />
      </div>
      <div className={styles.sliderArea}>
        <RangeSlider values={[value0, value1]} min={min} max={max} disabled={disabled} onChange={handleChangeSlider} />
      </div>
      <div className={styles.footer}>
        <Button sizeType='sm' buttonColor='black' onClick={handleClickButton}>
          {isCurrentField ? `修正` : `設定`}
        </Button>
      </div>
    </div>
  )
}
