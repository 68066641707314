import { Action } from 'redux'
import { Epic, combineEpics } from 'redux-observable'
import { Observable, catchError, filter, map, mergeMap, of, switchMap, tap } from 'rxjs'
import { httpErrorhandling } from '../redux.util'
import { branchAction } from './branch.action'
import { branchService } from './branch.service'

const pendingStartEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        branchAction.fetchBranches.request.type,
        branchAction.createBranches.request.type,
        branchAction.createBranch.request.type,
        branchAction.editBranch.request.type,
        branchAction.deleteBranches.request.type,
        branchAction.downloadBranchCodeTemplate.request.type,
      ].includes(action.type),
    ),
    map(() => branchAction.increasePendingCount()),
  )

const pendingEndEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        branchAction.fetchBranches.success.type,
        branchAction.fetchBranches.failure.type,
        branchAction.fetchBranches.cancelled.type,
        branchAction.createBranches.success.type,
        branchAction.createBranches.failure.type,
        branchAction.createBranches.cancelled.type,
        branchAction.createBranch.success.type,
        branchAction.createBranch.failure.type,
        branchAction.createBranch.cancelled.type,
        branchAction.editBranch.success.type,
        branchAction.editBranch.failure.type,
        branchAction.editBranch.cancelled.type,
        branchAction.deleteBranches.success.type,
        branchAction.deleteBranches.failure.type,
        branchAction.deleteBranches.cancelled.type,
        branchAction.downloadBranchCodeTemplate.success.type,
        branchAction.downloadBranchCodeTemplate.failure.type,
        branchAction.downloadBranchCodeTemplate.cancelled.type,
      ].includes(action.type),
    ),
    map(() => branchAction.decreasePendingCount()),
  )

const pendingFailureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) =>
      [
        branchAction.fetchBranches.failure.type,
        branchAction.createBranches.failure.type,
        branchAction.createBranch.failure.type,
        branchAction.editBranch.failure.type,
        branchAction.deleteBranches.failure.type,
        branchAction.downloadBranchCodeTemplate.failure.type,
      ].includes(action.type),
    ),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const fetchBranchesEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(branchAction.fetchBranches.request.match),
    switchMap(() =>
      branchService.fetchBranches().pipe(
        switchMap(({ response }) => {
          return [branchAction.fetchBranches.success(), branchAction.setBranches(response)]
        }),
        catchError((err) => of(branchAction.fetchBranches.failure(err))),
      ),
    ),
  )

const createBranchesEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(branchAction.createBranches.request.match),
    switchMap(({ payload }) =>
      branchService.createBranches(payload).pipe(
        switchMap(() => {
          return [branchAction.createBranches.success(), branchAction.fetchBranches.request()]
        }),
        catchError((err) => of(branchAction.createBranches.failure(err))),
      ),
    ),
  )

const createBranchEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(branchAction.createBranch.request.match),
    switchMap(({ payload }) =>
      branchService.createBranch(payload).pipe(
        switchMap(({ response }) => {
          return [
            branchAction.createBranch.success(),
            branchAction.setBranch(response),
            branchAction.setAsyncSuccess(true),
          ]
        }),
        catchError((err) => of(branchAction.createBranch.failure(err))),
      ),
    ),
  )

const editBranchEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(branchAction.editBranch.request.match),
    switchMap(({ payload }) =>
      branchService.editBranch(payload).pipe(
        switchMap(({ response }) => {
          return [
            branchAction.editBranch.success(),
            branchAction.setBranch(response),
            branchAction.setAsyncSuccess(true),
          ]
        }),
        catchError((err) => of(branchAction.editBranch.failure(err))),
      ),
    ),
  )

const deleteBranchesEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(branchAction.deleteBranches.request.match),
    switchMap(({ payload }) =>
      branchService.deleteBranches(payload).pipe(
        switchMap(() => {
          return [branchAction.deleteBranches.success(), branchAction.fetchBranches.request()]
        }),
        catchError((err) => of(branchAction.deleteBranches.failure(err))),
      ),
    ),
  )

const downloadBranchesTemplateEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(branchAction.downloadBranchCodeTemplate.request.match),
    switchMap(() =>
      branchService.downloadBranchCodeTemplate().pipe(
        mergeMap(() => [branchAction.downloadBranchCodeTemplate.success()]),
        tap(() => {
          window.location.href = `/api/branches/templatefile`
        }),
        catchError((err) => of(branchAction.downloadBranchCodeTemplate.failure(err))),
      ),
    ),
  )

export const branchEpic = combineEpics(
  pendingStartEpic,
  pendingEndEpic,
  pendingFailureEpic,
  fetchBranchesEpic,
  createBranchesEpic,
  createBranchEpic,
  editBranchEpic,
  deleteBranchesEpic,
  downloadBranchesTemplateEpic,
)
