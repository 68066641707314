import React from 'react'
import { NotiInfo } from 'src/component/molecule/noti-info/noti-info'
import { UserInfo } from 'src/component/molecule/user-info/user-info'
import { AlertLogType } from 'src/type/notification.type'
import { UserType } from 'src/type/user.type'
import styles from './header.scss'

type PropsType = {
  user: UserType
  alertLogs: AlertLogType[]
  children: React.ReactNode
  onOpenNotification?(): void
}

export const Header = ({ user, alertLogs, children, onOpenNotification }: PropsType) => {
  return (
    <header className={styles.root}>
      <div className={styles.children}>{children}</div>
      <div className={styles.infoArea}>
        <NotiInfo alertLogs={alertLogs} onOpenNotification={onOpenNotification} />
        <UserInfo user={user} />
      </div>
    </header>
  )
}
