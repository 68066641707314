import classNames from 'classnames'
import React from 'react'
import styles from './button.scss'

export type PropsType = {
  sizeType?: 'xs' | 'sm' | 'md' | 'lg'
  buttonColor?: 'primary' | 'secondary' | 'tertiary' | 'orange' | 'purple' | 'blue' | 'pink' | 'mint' | 'black'
  outlined?: boolean
  disabled?: boolean
  isRounded?: boolean
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children?: React.ReactNode
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = ({
  sizeType,
  buttonColor,
  outlined = false,
  disabled = false,
  isRounded,
  onClick,
  className,
  children,
  ...props
}: PropsType) => (
  <button
    {...props}
    type='button'
    className={classNames(styles.root, className, {
      [styles.xsSize]: sizeType === 'xs',
      [styles.smSize]: sizeType === 'sm',
      [styles.mdSize]: sizeType === 'md',
      [styles.lgSize]: sizeType === 'lg',
      [styles.primary]: buttonColor === 'primary',
      [styles.secondary]: buttonColor === 'secondary',
      [styles.tertiary]: buttonColor === 'tertiary',
      [styles.orange]: buttonColor === 'orange',
      [styles.purple]: buttonColor === 'purple',
      [styles.blue]: buttonColor === 'blue',
      [styles.pink]: buttonColor === 'pink',
      [styles.mint]: buttonColor === 'mint',
      [styles.rounded]: isRounded,
      [styles.outlined]: outlined,
    })}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)
