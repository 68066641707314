import classNames from 'classnames'
import React, { useMemo } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { SymmetryButton } from 'src/component/atom/symmetry-button/symmetry-button'
import { ResultColumnNameIntl } from 'src/intl/data/result.key'
import IconTrash2 from 'src/style/icon/icon-trash2.svg'
import { FieldTypesEnum } from 'src/type/dataset.type'
import { ResultSegmentFieldsType } from 'src/type/project.type'
import styles from './segment-variable.scss'

type PropsType = {
  variable: ResultSegmentFieldsType
  isActionAvailable?: boolean
  onDeleteClick?(variable: ResultSegmentFieldsType): void
}

export const SegmentVariable = ({ variable, isActionAvailable, onDeleteClick }: PropsType) => {
  const conditions = useMemo(() => {
    if (!variable.conditions) return null
    if (variable.type === FieldTypesEnum.CATEGORY) {
      return <>{variable.conditions.map((condition) => condition.param).join(', ')}</>
    } else if (variable.type === FieldTypesEnum.NUMBER) {
      const getComparison = (comparisonString: string) => {
        switch (comparisonString) {
          case 'lt':
            return '<'
          case 'lte':
            return '<='
          case 'eq':
            return '=='
          case 'gte':
            return '>='
          case 'gt':
            return '>'
          default:
            return null
        }
      }

      const firstCondition = variable.conditions[0]
      const conjunction = variable.conditions[1]
      const secondCondition = variable.conditions[2]

      return (
        <>
          {firstCondition.param && `x ${getComparison(firstCondition.op)} ${(+firstCondition.param || 0).toFixed(2)}`}
          {conjunction.op !== 'none' &&
            secondCondition &&
            secondCondition.param &&
            ` ${conjunction.op} x ${getComparison(secondCondition.op)} ${(+secondCondition.param || 0).toFixed(2)}`}
        </>
      )
    }
  }, [variable.conditions])

  return (
    <div className={classNames(styles.root, { [styles.noConditions]: !variable.conditions })}>
      <span className={styles.field}>{ResultColumnNameIntl[variable.name] ?? variable.name}</span>
      {conditions ? (
        <span className={styles.condition}>{conditions}</span>
      ) : (
        <span className={styles.condition}>{`設定なし`}</span>
      )}
      {isActionAvailable && (
        <div className={styles.hoverAction}>
          <SymmetryButton sizeType='md' isRounded noShape onClick={() => onDeleteClick && onDeleteClick(variable)}>
            <InlineSVG className={styles.deleteAction} src={IconTrash2} />
          </SymmetryButton>
        </div>
      )}
    </div>
  )
}
