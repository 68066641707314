import React, { useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { SymmetryButton } from 'src/component/atom/symmetry-button/symmetry-button'
import { TagLabel } from 'src/component/atom/tag-label/tag-label'
import { SegmentVariable } from 'src/component/molecule/segment-variable/segment-variable'
import { SegmentItemIntl } from 'src/intl/data/result.key'
import { EditSegmentModal } from 'src/modal/segment/edit-segment.modal'
import IconCopy from 'src/style/icon/icon-copy.svg'
import IconExcel from 'src/style/icon/icon-excel.svg'
import IconFx from 'src/style/icon/icon-fx.svg'
import IconPlus from 'src/style/icon/icon-plus.svg'
import IconTrash3 from 'src/style/icon/icon-trash3.svg'
import { FieldTypesEnum } from 'src/type/dataset.type'
import { ProjectTypesEnum, SegmentType } from 'src/type/project.type'
import { intlUtil } from 'src/util/intl.util'
import { Tooltip } from '../tooltip/tooltip'
import styles from './segment-item.scss'

type PropsType = {
  projectType: ProjectTypesEnum
  segmentIndex: number
  segment: SegmentType
  datasetFields: { type: FieldTypesEnum; name: string }[]
}

export const SegmentItem = ({ projectType, segmentIndex, segment, datasetFields }: PropsType) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const fullSegmentIntl = intlUtil.fullSegmentExplainIntl(segment.resultSegmentFields)

  function handleUpdateClick() {
    setModalVisible(true)
  }

  return (
    <div className={styles.root}>
      <span className={styles.title}>
        {`セグメント ${segmentIndex}`}
        <Tooltip>
          <pre className={styles.segmentDesc}>{fullSegmentIntl.join('で,') + 'の顧客'}</pre>
        </Tooltip>
      </span>
      <div className={styles.clusterSet}>
        {segment.resultSegmentFields.map((variable) => (
          <SegmentVariable key={variable.name} variable={variable} />
        ))}
        {false && (
          <SymmetryButton sizeType='md' buttonColor='tertiary' isRounded outlined onClick={handleUpdateClick}>
            <InlineSVG className={styles.edit} src={IconPlus} />
          </SymmetryButton>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.tags}>
          <div className={styles.tagItem}>
            <TagLabel title={SegmentItemIntl[projectType].average || `average`} />
            <span className={styles.tagValue}>{segment.average}</span>
          </div>
          <div className={styles.tagItem}>
            <TagLabel title={SegmentItemIntl[projectType].size || `size`} />
            <span className={styles.tagValue}>{segment.size}</span>
          </div>
        </div>
        {false && (
          <div className={styles.actions}>
            <SymmetryButton sizeType='xs' noShape>
              <InlineSVG className={styles.actionIcon} src={IconFx} />
            </SymmetryButton>
            <SymmetryButton sizeType='xs' noShape>
              <InlineSVG className={styles.actionIcon} src={IconExcel} />
            </SymmetryButton>
            <SymmetryButton sizeType='xs' noShape>
              <InlineSVG className={styles.actionIcon} src={IconTrash3} />
            </SymmetryButton>
            <SymmetryButton sizeType='xs' noShape>
              <InlineSVG className={styles.actionIcon} src={IconCopy} />
            </SymmetryButton>
          </div>
        )}
      </div>
      <EditSegmentModal
        modalVisible={modalVisible}
        segment={segment}
        onClose={() => setModalVisible(false)}
        datasetFields={datasetFields}
      />
    </div>
  )
}
