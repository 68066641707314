import { createAction, createReducer } from '@reduxjs/toolkit'
import { TokenUserType } from 'src/type/auth.type'
import { createAsyncAction } from '../redux.type'
import { LoginParamsType } from './auth.service'

type AuthStateType = {
  pendingCount: number
  loggedInUser: TokenUserType | null
}

const increasePendingCount = createAction('[auth] increase async action pending count')
const decreasePendingCount = createAction('[auth] dencrease async action pending count')
const login = createAsyncAction<LoginParamsType>('[auth] login')
const setLoggedInUser = createAction<TokenUserType | null>('[auth] set logged in user')
const logout = createAsyncAction('[auth] logout')
const refresh = createAsyncAction('[auth] re-authenticate token')

export const authAction = {
  increasePendingCount,
  decreasePendingCount,
  login,
  setLoggedInUser,
  logout,
  refresh,
}

const initAuthState: AuthStateType = {
  pendingCount: 0,
  loggedInUser: null,
}

export const authReducer = createReducer(initAuthState, (builder) =>
  builder
    .addCase(increasePendingCount, (state) => {
      state.pendingCount++
    })
    .addCase(decreasePendingCount, (state) => {
      state.pendingCount--
    })
    .addCase(setLoggedInUser, (state, { payload }) => {
      state.loggedInUser = payload
    }),
)
