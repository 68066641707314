import React, { useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useVisibleWithOutsideClick } from 'src/hook/use-visible-with-outside-click'
import { LogDetailModal } from 'src/modal/log-detail/log-detail.modal'
import IconAlertActive from 'src/style/icon/icon-alert-active.svg'
import IconAlert from 'src/style/icon/icon-alert.svg'
import IconCheck from 'src/style/icon/icon-check.svg'
import IconX from 'src/style/icon/icon-x.svg'
import { AlertLogType, TASK_ALERT_STATUS } from 'src/type/notification.type'
import { util } from 'src/util/common.util'
import styles from './noti-info.scss'

type PropsType = {
  alertLogs: AlertLogType[]
  onOpenNotification?(): void
}

export const NotiInfo = ({ alertLogs, onOpenNotification }: PropsType) => {
  const { ref, isVisible, setIsVisible } = useVisibleWithOutsideClick<HTMLDivElement>(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [targetLogId, setTargetLogId] = useState<number>()

  const isUnreadAlert = useMemo(() => {
    return alertLogs.some((item) => !item.userCheck)
  }, [alertLogs])

  function handleClickBell() {
    setIsVisible(!isVisible)
    onOpenNotification && onOpenNotification()
  }

  function handleClickLogItem(alertLogId: number) {
    setTargetLogId(alertLogId)
    setModalVisible(true)
  }

  return (
    <div ref={ref}>
      <div className={styles.bell} onClick={handleClickBell}>
        <InlineSVG src={isUnreadAlert ? IconAlertActive : IconAlert} className={styles.icon} />
      </div>
      <div className={styles.anchor}>
        {isVisible && (
          <ul className={styles.content}>
            {alertLogs.length > 0 ? (
              alertLogs.map((alertItem) => (
                <li key={alertItem.id} className={styles.item} onClick={() => handleClickLogItem(alertItem.id)}>
                  {[TASK_ALERT_STATUS.SUCCESS, TASK_ALERT_STATUS.FILE_UPLOAD].includes(alertItem.status) ? (
                    <InlineSVG src={IconCheck} className={styles.successIcon} />
                  ) : (
                    <InlineSVG src={IconX} className={styles.failIcon} />
                  )}
                  <div className={styles.text}>{util.taskErrorInfoLogText(alertItem)}</div>
                </li>
              ))
            ) : (
              <li className={styles.noContentItem}>No Alarm</li>
            )}
          </ul>
        )}
      </div>
      {targetLogId && (
        <LogDetailModal targetLogId={targetLogId} modalVisible={modalVisible} onClose={() => setModalVisible(false)} />
      )}
    </div>
  )
}
