import React, { useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { Input } from 'src/component/atom/input/input'
import iconSearch from 'src/style/icon/icon-search.svg'
import { OptionType } from 'src/type/common.type'
import { VerticalOptionItem } from './vertical-option-item/vertical-option-item'
import styles from './vertical-selector.scss'

type PropsType = {
  options: OptionType[]
  selectedOption?: OptionType
  onChange?(option: OptionType): void
}

export const VerticalSelector = ({ options, selectedOption, onChange }: PropsType) => {
  const [activeOption, setActiveOption] = useState<OptionType | undefined>(selectedOption)
  const [filterString, setFilterString] = useState<string>('')

  const filteredOptions = useMemo(() => {
    const lowerCaseFilterString = filterString.toLowerCase()

    return options.filter((option) => option.label.toLowerCase().includes(lowerCaseFilterString))
  }, [options, filterString])

  function handleItemClick(option: OptionType) {
    setActiveOption(option)
    onChange && onChange(option)
  }

  return (
    <div className={styles.root}>
      <div className={styles.filter}>
        <Input
          type='search'
          placeholder='Search for dataset'
          sizeType='sm'
          borderType='none'
          isFullWidth
          onChange={(e) => setFilterString(e.currentTarget.value)}
        />
        <InlineSVG className={styles.suffixIcon} src={iconSearch} />
      </div>
      <ul className={styles.datasetList}>
        {filteredOptions.map((option) => (
          <VerticalOptionItem
            key={option.label}
            option={option}
            active={activeOption?.value === option.value}
            onClick={handleItemClick}
          />
        ))}
      </ul>
    </div>
  )
}
