import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { projectAction } from 'src/redux/project/project.action'
import { ProjectStepEnum, ProjectType } from 'src/type/project.type'
import styles from './train-confirm.scss'

type PropsType = {
  project: ProjectType
  onClose(): void
}

export const TrainConfirmModal = withModalWrapper(({ project, onClose }: PropsType) => {
  const dispatch = useDispatch()

  function handleNextButtonClick() {
    dispatch(projectAction.trainingProject.request({ projectId: project.id }))
    dispatch(projectAction.setProjectStep(ProjectStepEnum.TRAINNING))
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>{`設定が終わりました。\nこのままAIによる学習を進めますか?`}</div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleNextButtonClick}>
          {`確定`}
        </Button>
      </div>
    </div>
  )
}, '設定の最終確認')
