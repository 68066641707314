import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import React, { useEffect, useRef } from 'react'

type PropsType = {
  height?: number
  width?: number
  pieSize?: number
  title?: string
  yUnit?: string
  data: { name: string; y: number; label?: string }[]
  colorSets?: { [name: string]: string }
  selectedDataName?: string
  onClickItem?(name: string | undefined): void
  onClickOutside?(): void
}

export const ChartPie = ({
  height,
  width,
  pieSize,
  title,
  yUnit,
  data,
  colorSets,
  selectedDataName,
  onClickItem,
  onClickOutside,
}: PropsType) => {
  HighchartsNoDataToDisplay(Highcharts)

  const rootRef = useRef<HighchartsReactRefObject>(null)

  const isAllZero = data.every((point) => point.y === 0)

  const chartTitle: Highcharts.TitleOptions = {
    text: title,
    align: 'left',
    margin: 40,
    style: {
      fontSize: '16px',
      fontWeight: '600',
    },
  }

  const options: Highcharts.Options = {
    credits: { enabled: false },
    accessibility: { enabled: false },
    lang: {
      noData: '該当するデータが存在しません。',
    },
    chart: {
      type: 'pie',
      height,
      width,
      plotShadow: false,
      style: {
        fontFamily: 'Roboto, NotoSansJP',
      },
      events: {
        render() {
          this.series[0].data.forEach((point) => {
            if (!selectedDataName) {
              point.graphic?.css({
                opacity: 1,
              })
            } else if (point.name !== selectedDataName) {
              point.graphic?.css({
                opacity: 0.2,
              })
            } else {
              point.graphic?.css({
                opacity: 1,
              })
            }
          })
        },
      },
    },
    title: chartTitle,
    tooltip: {
      formatter() {
        return (
          '<b>' +
          (data.find((d) => d.name === this.point.name)?.label || this.point.name) +
          '</b><br/>' +
          '<span style="color:' +
          this.point.color +
          '">\u25CF</span> ' +
          this.series.name +
          ': <b>' +
          Highcharts.numberFormat(this.point.y || 0, 0, '.', ',') +
          '</b><br/>'
        )
      },
    },
    plotOptions: {
      pie: {
        cursor: 'pointer',
        size: pieSize,
        point: {
          events: {
            click() {
              if (this.selected) {
                onClickItem && onClickItem(undefined)
              } else {
                onClickItem && onClickItem(this.name)
              }
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            opacity: 1,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        dataLabels: [
          {
            enabled: true,
            distance: -40,
            format: '{point.percentage:.1f}%',
            style: {
              fontSize: '15px',
              textOutline: 'none',
              color: 'white',
            },
            filter: {
              operator: '>',
              property: 'percentage',
              value: 10,
            },
          },
        ],
        name: yUnit,
        data: isAllZero
          ? []
          : data.map(({ name, y }) => ({
              name,
              y,
              color: colorSets ? colorSets[name] : undefined,
              selected: selectedDataName === name,
              sliced: selectedDataName === name,
            })),
      },
    ],
  }

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (
        rootRef.current &&
        rootRef.current.container.current &&
        !rootRef.current.container.current.contains(e.target)
      ) {
        onClickOutside && onClickOutside()
      }
    }

    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [rootRef])

  return <HighchartsReact ref={rootRef} highcharts={Highcharts} options={options} />
}
