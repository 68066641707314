import classNames from 'classnames'
import React, { InputHTMLAttributes, Ref, forwardRef } from 'react'
import styles from './input.scss'

export type InputPropsType = {
  type?: 'number' | 'search' | 'text' | 'password'
  sizeType?: 'xs' | 'sm' | 'md' | 'lg'
  borderType?: 'default' | 'bottom' | 'none'
  widthType?: 'sm' | 'md' | 'lg'
  isFullWidth?: boolean
  isDarkDisabled?: boolean
  isError?: boolean
} & InputHTMLAttributes<HTMLInputElement>

// eslint-disable-next-line react/display-name
export const Input = forwardRef<HTMLInputElement, InputPropsType>(
  (
    {
      type,
      sizeType,
      borderType = 'default',
      widthType,
      isFullWidth,
      isDarkDisabled,
      isError,
      ...props
    }: InputPropsType,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <input
        ref={ref}
        {...props}
        type={type}
        className={classNames(styles.root, {
          [styles.xsSize]: sizeType === 'xs',
          [styles.smSize]: sizeType === 'sm',
          [styles.mdSize]: sizeType === 'md',
          [styles.lgSize]: sizeType === 'lg',
          [styles.smWidth]: widthType === 'sm',
          [styles.mdWidth]: widthType === 'md',
          [styles.lgWidth]: widthType === 'lg',
          [styles.borderBottom]: borderType === 'bottom',
          [styles.borderNone]: borderType === 'none',
          [styles.fullWidth]: isFullWidth,
          [styles.isDarkDisabled]: isDarkDisabled,
          [styles.isError]: isError,
        })}
      />
    )
  },
)
