import React, { Fragment, useEffect, useState } from 'react'
import { VerticalSelector } from 'src/component/molecule/vertical-selector/vertical-selector'
import { OptionType } from 'src/type/common.type'
import styles from './mapping.scss'

type PropsType = {
  datasetFieldsList: OptionType[][]
  selectedFieldList?: OptionType[]
  onChange?(mappingKeys: OptionType[]): void
}

export const Mapping = ({ datasetFieldsList, selectedFieldList = [], onChange }: PropsType) => {
  const [selectedFields, setSelectedFields] = useState<{ [key: string]: OptionType }>(
    selectedFieldList.reduce<{ [key: string]: OptionType }>((res, selectedField, idx) => {
      res[idx] = selectedField
      return res
    }, {}),
  )

  useEffect(() => {
    const selectedList = Object.entries(selectedFields)
      .sort(([keyA], [keyB]) => (keyA > keyB ? 1 : -1))
      .reduce<OptionType[]>((res, [, selectedField]) => {
        res.push(selectedField)
        return res
      }, [])

    onChange && onChange(selectedList)
  }, [selectedFields])

  return (
    <div className={styles.fieldset}>
      {datasetFieldsList.map((datasetFields, idx) => (
        <Fragment key={`datasetFields-${idx}`}>
          {idx !== 0 && <div>=</div>}
          <section className={styles.fieldSection}>
            <VerticalSelector
              options={datasetFields}
              selectedOption={selectedFields[idx]}
              onChange={(op) => setSelectedFields({ ...selectedFields, [idx]: op })}
            />
          </section>
        </Fragment>
      ))}
    </div>
  )
}
