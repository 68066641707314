import { Socket, io } from 'socket.io-client'

const socketUrl = ``

let curSocket: Socket | undefined

const connectedSocket = (userId: number) => {
  if (!curSocket) {
    curSocket = io(socketUrl, { auth: { userId } })
  }
  return curSocket
}

const disconnectSocket = () => {
  if (curSocket) {
    curSocket.disconnect()
  }
}

export const socketIo = {
  connectedSocket,
  disconnectSocket,
}
