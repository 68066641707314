import classNames from 'classnames'
import React, { useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { Input, InputPropsType } from 'src/component/atom/input/input'
import IconEyeOutlineSlash from 'src/style/icon/icon-eye-outline-slash.svg'
import IconEyeOutline from 'src/style/icon/icon-eye-outline.svg'
import styles from './input-password.scss'

export const InputPassword = ({ sizeType, widthType, borderType, isFullWidth, isError, ...props }: InputPropsType) => {
  const [show, setShow] = useState(false)

  return (
    <div
      className={classNames(styles.root, {
        [styles.xsSize]: sizeType === 'xs',
        [styles.smSize]: sizeType === 'sm',
        [styles.mdSize]: sizeType === 'md',
        [styles.lgSize]: sizeType === 'lg',
        [styles.borderBottom]: borderType === 'bottom',
        [styles.borderNone]: borderType === 'none',
        [styles.fullWidth]: isFullWidth,
        [styles.isError]: isError,
      })}
    >
      <Input
        {...props}
        type={show ? 'text' : 'password'}
        sizeType={sizeType}
        widthType={widthType}
        isFullWidth
        borderType='none'
      />
      <div className={styles.showIconArea}>
        <InlineSVG src={show ? IconEyeOutlineSlash : IconEyeOutline} onClick={() => setShow(!show)} />
      </div>
    </div>
  )
}
