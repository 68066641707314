import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutoSizer, List, ListRowProps } from 'react-virtualized'
import { Button } from 'src/component/atom/button/button'
import { Checkbox } from 'src/component/atom/checkbox/checkbox'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { branchSelector } from 'src/redux/branch/branch.state'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { dashboardSelector } from 'src/redux/dashboard/dashboard.state'
import { ReduxStatusEnum } from 'src/type/common.type'
import styles from './download-ds-company.scss'

type PropsType = {
  onClose(): void
}

export const DownloadDsCompanyModal = withModalWrapper(({ onClose }: PropsType) => {
  const dispatch = useDispatch()
  const dashboardStatus = useSelector(dashboardSelector.status)
  const dsCompanyBranchCodeOptions = useSelector(dashboardSelector.dsCompanyBranchCodeOptions)
  const selectedCompanyBranchCode = useSelector(dashboardSelector.selectedCompanyBranchCode)
  const branchRecordsByCode = useSelector(branchSelector.branchRecordsByCode)

  const listRef = React.useRef<List>(null)

  const branchCodes = useMemo(() => {
    return dsCompanyBranchCodeOptions.filter((option) => option.value !== 'allBranches')
  }, [dsCompanyBranchCodeOptions])

  const [closeClick, setCloseClick] = useState(false)
  const [checkedConditions, setCheckedConditions] = useState<{ [companyNames: string]: boolean }>(
    branchCodes.reduce<{ [key: string]: boolean }>((res, branchCodeOpt) => {
      res[branchCodeOpt.value] = false
      return res
    }, {}),
  )

  const allChecked = useMemo(() => Object.values(checkedConditions).every((checked) => checked), [checkedConditions])

  useEffect(() => {
    if (closeClick === true && dashboardStatus === ReduxStatusEnum.IDLE) {
      onClose()
    }
  }, [dashboardStatus])

  const rowRenderer = (props: ListRowProps) => {
    const rowBranchCode = branchCodes[props.index].value

    return (
      <label key={props.key} className={styles.selectItem} style={props.style}>
        <Checkbox
          value={rowBranchCode}
          checked={checkedConditions[rowBranchCode]}
          onChange={(e) => setCheckedConditions({ ...checkedConditions, [rowBranchCode]: e.currentTarget.checked })}
        />
        <span className={styles.companyName}>
          {branchRecordsByCode[rowBranchCode] ? branchRecordsByCode[rowBranchCode].branchName : rowBranchCode}
        </span>
      </label>
    )
  }

  function handleAllCheckClick(e: React.ChangeEvent<HTMLInputElement>) {
    setCheckedConditions(
      branchCodes.reduce<{ [key: string]: boolean }>((res, option) => {
        res[option.value] = e.currentTarget.checked
        return res
      }, {}),
    )
  }

  function handleDownloadClick() {
    setCloseClick(true)
    if (selectedCompanyBranchCode === 'allBranches') {
      const checkedBranchCodes = Object.entries(checkedConditions)
        .filter(([, value]) => !!value)
        .map(([key]) => key)

      dispatch(dashboardAction.downloadDsCompanyDoc.request({ branchCodes: checkedBranchCodes }))
    } else {
      dispatch(dashboardAction.downloadDsCompanyDoc.request({ branchCodes: [selectedCompanyBranchCode] }))
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div
          className={classNames(styles.selectCompany, {
            [styles.allCompanySelector]: selectedCompanyBranchCode === 'allBranches',
          })}
        >
          <div className={styles.selectTitle}>{`ダウンロードファイル設定`}</div>
          <div className={styles.selectSub}>{`直近で返済実績がある取引先データのみダウンロードされます。`}</div>
          {selectedCompanyBranchCode !== 'allBranches' ? (
            <div className={styles.selectSub}>{`${
              branchRecordsByCode[selectedCompanyBranchCode]
                ? branchRecordsByCode[selectedCompanyBranchCode].branchName
                : selectedCompanyBranchCode
            } 支店内の企業をダウンロードします`}</div>
          ) : (
            <>
              <label className={styles.selectItem}>
                <Checkbox checked={allChecked} onChange={handleAllCheckClick} />
                <span className={styles.companyName}>{`全体 (${branchCodes.length})`}</span>
              </label>
              <div className={styles.selectArea}>
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      ref={listRef}
                      width={width}
                      height={height}
                      rowHeight={40}
                      rowCount={branchCodes.length}
                      rowRenderer={rowRenderer}
                    />
                  )}
                </AutoSizer>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <Button sizeType='lg' outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' onClick={handleDownloadClick}>
          {`ダウンロード`}
        </Button>
      </div>
    </div>
  )
}, `企業別 詳細レポート`)
