import classNames from 'classnames'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'src/component/atom/button/button'
import { Checkbox } from 'src/component/atom/checkbox/checkbox'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { InputWith } from 'src/component/molecule/input-with/input-with'
import { projectGoalIntl } from 'src/intl/data/project.key'
import { projectAction } from 'src/redux/project/project.action'
import { projectSelector } from 'src/redux/project/project.state'
import { ReduxStatusEnum } from 'src/type/common.type'
import { ProjectGoalType, ProjectGoalTypesEnum, ProjectStepEnum, ProjectType } from 'src/type/project.type'
import { projectGoalProperties } from 'src/ui-variable/project.ui'
import styles from './goal.scss'

type PropsType = {
  project: ProjectType
}

export const GoalPanel = ({ project }: PropsType) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const projectGoal = useSelector(projectSelector.selectedProjectGoal)
  const projectSettingValues = useSelector(projectSelector.selectedProjectSettingValues)
  const projectStatus = useSelector(projectSelector.status)

  const [nextButtonClick, setNextButtonClick] = useState(false)

  const [checkOptionalList, setCheckOptionalList] = useReducer(
    (state: { [goalId: number]: boolean }, newState: { [goalId: number]: boolean }) => {
      return { ...state, ...newState }
    },
    {},
  )
  const [checkedInputValue, setCheckedInputValue] = useReducer(
    (state: { [goalId: number]: number | string }, newState: { [goalId: number]: number | string }) => {
      return { ...state, ...newState }
    },
    {},
  )

  const projectGoalObjs = useMemo(() => {
    return projectGoal
      ? projectGoal.reduce<{ [goalId: number]: ProjectGoalType }>((res, goal) => {
          res[goal.id] = goal
          return res
        }, {})
      : {}
  }, [projectGoal])

  const [requiredGoal, optionalGoal] = useMemo(() => {
    const sortedProjectGoal = projectGoal
      ? [...projectGoal].sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
      : []
    const required = sortedProjectGoal.filter((goal) => goal.isRequired)
    const optional = sortedProjectGoal.filter((goal) => !goal.isRequired)

    return [required, optional]
  }, [projectGoal])

  const [goalItems, nextButtonDisabled] = useMemo(() => {
    const requiredIds = requiredGoal.map((goal) => goal.id)

    requiredIds.push(
      ...Object.entries(checkOptionalList)
        .filter(([id, checked]) => {
          const parentGoalId = projectGoalObjs[+id].parent
          return checked && (!parentGoalId || (!!parentGoalId && checkOptionalList[parentGoalId] === true))
        })
        .map(([id]) => +id),
    )

    const targetGoalItems = requiredIds.map((id) => {
      const targetGoal = projectGoal?.find((goal) => goal.id === id)
      if (targetGoal) {
        return { goalId: targetGoal.id, value: checkedInputValue[targetGoal.id] }
      }
    })

    const isDisabled = targetGoalItems.some((params) => !params?.value)

    return [targetGoalItems, isDisabled]
  }, [checkedInputValue, checkOptionalList])

  useEffect(() => {
    if (projectGoal) {
      setCheckedInputValue(
        projectGoal.reduce<{ [goalId: number]: number | string }>((res, goal) => {
          if (projectSettingValues[goal.id] || goal.options.default) {
            res[goal.id] = projectSettingValues[goal.id] ?? goal.options.default
          }
          return res
        }, {}),
      )
    }
  }, [projectGoal])

  useEffect(() => {
    if (optionalGoal && projectSettingValues) {
      setCheckOptionalList(
        optionalGoal.reduce<{ [goalId: number]: boolean }>((res, goal) => {
          res[goal.id] = !!projectSettingValues[goal.id]
          return res
        }, {}),
      )
    }
  }, [optionalGoal, projectSettingValues])

  useEffect(() => {
    if (nextButtonClick && projectStatus === ReduxStatusEnum.IDLE) {
      dispatch(projectAction.setProjectStep(ProjectStepEnum.DATASET))
    }
  }, [projectStatus])

  function handleUpdateProjectSetting(goalId: number, value: string | number) {
    setCheckedInputValue({ [goalId]: value })
  }

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCheckOptionalList({ [e.currentTarget.value]: e.currentTarget.checked })
    if (!e.currentTarget.checked) {
      projectGoalObjs[+e.currentTarget.value].children.map((goalId) =>
        setCheckOptionalList({ [goalId]: e.currentTarget.checked }),
      )
    }
  }

  function handlePrevButtonClick() {
    navigate('/')
  }

  function handleNextButtonClick() {
    const goalParams = goalItems.filter((params): params is { goalId: number; value: number | string } => !!params)
    const deleteGoalParams = Object.keys(projectSettingValues)
      .filter((id) => !goalParams.some((item) => item.goalId === +id))
      .map((id) => ({ goalId: +id }))

    dispatch(
      projectAction.updateProjectSetting.request({
        projectId: project.id,
        goalParams,
        deleteGoalParams,
      }),
    )
    setNextButtonClick(true)
  }

  return (
    <div className={styles.root}>
      {projectGoal && (
        <>
          <div className={styles.title}>{`ビジネスゴール`}</div>
          <section className={styles.goalRequired}>
            {requiredGoal.map((goal) => (
              <div key={goal.id} className={styles.item}>
                {projectGoalIntl[project.type][goal.name].prefix && (
                  <label>{projectGoalIntl[project.type][goal.name].prefix}</label>
                )}
                {goal.type === ProjectGoalTypesEnum.VALUE && (
                  <InputWith
                    defaultValue={checkedInputValue[goal.id]}
                    type={goal.options.min === 0 || goal.options.min ? 'number' : undefined}
                    min={goal.options.min}
                    max={goal.options.max}
                    sizeType='lg'
                    widthType={projectGoalProperties[project.type][goal.name].widthType}
                    prefix={projectGoalProperties[project.type][goal.name].prefixUnit}
                    suffix={projectGoalProperties[project.type][goal.name].suffixUnit}
                    onChange={(e) => handleUpdateProjectSetting(goal.id, e.currentTarget.value)}
                  />
                )}
                {goal.type === ProjectGoalTypesEnum.SELECT && (
                  <DropdownSingleSelector
                    options={
                      goal.options.selectableValues
                        ? goal.options.selectableValues.map((op) => ({ label: `${op}`, value: op }))
                        : []
                    }
                    selectedOption={
                      checkedInputValue[goal.id]
                        ? { label: `${checkedInputValue[goal.id]}`, value: checkedInputValue[goal.id] }
                        : undefined
                    }
                    sizeType='lg'
                    placeholder={`マッピングされていません。`}
                    widthType={projectGoalProperties[project.type][goal.name].widthType}
                    onChange={(op) => handleUpdateProjectSetting(goal.id, op.value)}
                  />
                )}
                {projectGoalIntl[project.type][goal.name].suffix && (
                  <label>{projectGoalIntl[project.type][goal.name].suffix}</label>
                )}
                <span className={styles.required}>*</span>
              </div>
            ))}
          </section>
          {optionalGoal.length > 0 && <div className={styles.optionalTitle}>{`任意記入項目`}</div>}
          <section className={styles.goalOptional}>
            {optionalGoal.map((goal) => (
              <div
                key={goal.id}
                className={classNames(styles.item, {
                  [styles.disabledOptional]: !!goal.parent && checkOptionalList[goal.parent] !== true,
                })}
              >
                <Checkbox
                  value={goal.id}
                  checked={checkOptionalList[goal.id] ?? false}
                  onChange={handleCheckboxChange}
                  disabled={!!goal.parent && checkOptionalList[goal.parent] !== true}
                />
                {projectGoalIntl[project.type][goal.name].prefix && (
                  <label>{projectGoalIntl[project.type][goal.name].prefix}</label>
                )}
                {goal.type === ProjectGoalTypesEnum.VALUE && (
                  <InputWith
                    defaultValue={checkedInputValue[goal.id]}
                    type={goal.options.min === 0 || goal.options.max ? 'number' : undefined}
                    min={goal.options.min}
                    max={goal.options.max}
                    sizeType='md'
                    widthType={projectGoalProperties[project.type][goal.name].widthType}
                    prefix={projectGoalProperties[project.type][goal.name].prefixUnit}
                    suffix={projectGoalProperties[project.type][goal.name].suffixUnit}
                    onChange={(e) => handleUpdateProjectSetting(goal.id, e.currentTarget.value)}
                    disabled={!checkOptionalList[goal.id]}
                  />
                )}
                {goal.type === ProjectGoalTypesEnum.SELECT && (
                  <DropdownSingleSelector
                    options={
                      goal.options.selectableValues
                        ? goal.options.selectableValues.map((op) => ({ label: `${op}`, value: op }))
                        : []
                    }
                    selectedOption={{ label: `${checkedInputValue[goal.id]}`, value: checkedInputValue[goal.id] }}
                    sizeType='lg'
                    placeholder={`マッピングされていません。`}
                    widthType={projectGoalProperties[project.type][goal.name].widthType}
                    onChange={(op) => handleUpdateProjectSetting(goal.id, op.value)}
                  />
                )}
                {projectGoalIntl[project.type][goal.name].suffix && (
                  <label>{projectGoalIntl[project.type][goal.name].suffix}</label>
                )}
              </div>
            ))}
          </section>
          <div className={styles.footer}>
            <a href='#' className={styles.outQnaLink}>
              {``}
            </a>
            <Button sizeType='lg' outlined onClick={handlePrevButtonClick}>
              {`前へ`}
            </Button>
            <Button sizeType='lg' onClick={handleNextButtonClick} disabled={nextButtonDisabled}>{`次へ`}</Button>
          </div>
        </>
      )}
    </div>
  )
}
