import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { notificationSelector } from 'src/redux/notification/notification.state'
import { util } from 'src/util/common.util'
import styles from './log-detail.scss'

type PropsType = {
  targetLogId: number
  onClose(): void
}

export const LogDetailModal = withModalWrapper(({ targetLogId, onClose }: PropsType) => {
  const targetLog = useSelector(notificationSelector.alertLogById(targetLogId))

  return (
    <div className={styles.root}>
      <div className={styles.infoMessage}>
        <label className={styles.messageLabel}>Message: </label>
        <span className={styles.messageText}>{util.taskErrorInfoLogText(targetLog)}</span>
      </div>
      <div className={styles.infoLabel}>Info</div>
      <div className={styles.infoLog}>{JSON.stringify(targetLog.infoLog, null, 2)}</div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
      </div>
    </div>
  )
}, 'Log Detail')
