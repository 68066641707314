import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsSeriesLabel from 'highcharts/modules/series-label'
import React from 'react'
import styles from './chart-line.scss'

type PropsType = {
  title?: string
  subTitle?: string
  chartHight?: number
  yAxis1Title?: string
  yAxis2Title?: string
  y1Unit?: number
  y2Unit?: number
  minYvalue?: number
  xAxisTitle?: string
  xTickValues?: string[]
  valueDecimals?: number
  series?: { name: string; data: number[] }[]
}

export const ChartLine = ({
  title,
  subTitle,
  chartHight,
  xAxisTitle,
  yAxis1Title,
  yAxis2Title,
  y1Unit = 1,
  y2Unit = 1,
  minYvalue,
  xTickValues,
  series,
  valueDecimals,
}: PropsType) => {
  HighchartsSeriesLabel(Highcharts)
  HighchartsNoDataToDisplay(Highcharts)

  const xTickValuesLength = xTickValues?.length || 0
  const xTickPositions =
    xTickValuesLength === 0
      ? []
      : Array.from(
          { length: Math.ceil(xTickValuesLength / 3) },
          (_, index) => xTickValuesLength - 1 - index * 3,
        ).reverse()

  const chartTitle: Highcharts.TitleOptions = {
    text: title,
    align: 'left',
    margin: subTitle ? 40 : 55,
    style: {
      fontSize: '16px',
      fontWeight: '600',
    },
  }

  const chartYAxis: Highcharts.YAxisOptions[] = [
    {
      min: minYvalue,
      title: {
        text: yAxis1Title,
      },
      labels: {
        formatter() {
          return new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: valueDecimals,
          }).format(Number(this.value))
        },
      },
    },
  ]

  const chartXAxis: Highcharts.XAxisOptions = {
    categories: xTickValues,
    tickmarkPlacement: 'on',
    tickLength: 10,
    tickWidth: 1,
    gridLineWidth: 1,
    title: {
      text: xAxisTitle,
    },
    labels: {
      rotation: 0,
      style: {
        textOverflow: 'clip',
      },
      formatter() {
        if (xTickPositions.includes(this.pos)) {
          return this.value.toString()
        }
        return ''
      },
    },
  }

  const dataSeries: Highcharts.SeriesOptionsType[] = series
    ? series.map((data, idx) => ({
        type: 'line',
        name: data.name,
        data: data.data.map((v) =>
          (minYvalue === undefined || minYvalue >= 0) && minYvalue !== undefined && minYvalue >= v
            ? minYvalue
            : idx === 0
            ? v / y1Unit
            : v / y2Unit,
        ),
        color: idx === 0 ? '#5158FA' : '#bbb',
        zIndex: idx === 0 ? 1 : undefined,
        yAxis: idx !== 0 && yAxis2Title ? 1 : undefined,
      }))
    : [
        {
          type: 'line',
          name: '',
          data: [],
          color: '#5158FA',
        },
        {
          type: 'line',
          name: '',
          data: [],
          color: '#bbb',
        },
      ]

  if (yAxis2Title) {
    chartYAxis.push({
      opposite: true,
      min: minYvalue,
      title: {
        text: yAxis2Title,
      },
      labels: {
        formatter() {
          return new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: valueDecimals,
          }).format(Number(this.value))
        },
      },
    })
  }

  const options: Highcharts.Options = {
    credits: { enabled: false },
    accessibility: { enabled: false },
    lang: {
      noData: '該当するデータが存在しません。',
    },
    chart: {
      type: 'line',
      height: chartHight,
      plotShadow: false,
      style: {
        fontFamily: 'Roboto, NotoSansJP',
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
      y: 35,
      floating: true,
    },
    tooltip: {
      formatter() {
        return (
          '<span style="font-size: 11px">' +
          this.point.category +
          '</span><br/>' +
          '<span style="color:' +
          this.point.color +
          '">\u25CF</span> ' +
          this.series.name +
          ': <b>' +
          new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: valueDecimals,
          }).format(Number(this.point.y)) +
          '</b><br/>'
        )
      },
    },
    title: chartTitle,
    subtitle: {
      text: subTitle || ' ',
      align: 'left',
    },
    yAxis: chartYAxis,
    xAxis: chartXAxis,
    series: dataSeries,
  }

  return (
    <div className={styles.root}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
