import { createSelector } from '@reduxjs/toolkit'
import { RootStateType } from 'src/redux/root.reducer'
import { OptionType, ReduxStatusEnum } from 'src/type/common.type'
import { PieChartColors } from './dashboard.const'

const dashboardState = (state: RootStateType) => state.dashboardState

const status = createSelector(dashboardState, (state) =>
  state.pendingCount > 0 ? ReduxStatusEnum.PENDING : ReduxStatusEnum.IDLE,
)
const dashboardCompanyRecords = createSelector(dashboardState, (state) => state.dashboardCompanyRecords ?? {})
const dashboardCompanyIds = createSelector(dashboardState, (state) => state.dashboardCompanyIds ?? [])
const dashboardCompanies = createSelector(dashboardCompanyRecords, dashboardCompanyIds, (records, ids) =>
  ids.map((id) => records[id]),
)
const dashboardCompaniesIsMainbank = createSelector(dashboardCompanies, (state) =>
  state.filter((c) => c.isMainBank === 'true'),
)
const dashboardCompanyPieChartDatas = createSelector(dashboardState, (state) => state.dashboardCompanyPieChartDatas)
const totalCompanyCount = createSelector(dashboardState, (state) => state.totalCompanyCount)
const unspecifiedIndustryCompanyCount = createSelector(dashboardState, (state) => state.unspecifiedIndustryCompanyCount)
const unspecifiedIndustryCompanyRate = createSelector(
  totalCompanyCount,
  unspecifiedIndustryCompanyCount,
  (totalCompanyCount, unspecifiedIndustryCompanyCount) => {
    return totalCompanyCount === 0 ? 0 : (unspecifiedIndustryCompanyCount / totalCompanyCount) * 100
  },
)

const dashboardIndustry = createSelector(dashboardState, (state) => state.dashboardIndustry)

const dashboardContract = createSelector(dashboardState, (state) => state.dashboardContract)

const dsContractBranchCodeOptions = createSelector(dashboardState, (state) => {
  const branchCodes = state.dsContractBranchCodeItems ? [...state.dsContractBranchCodeItems] : undefined
  if (branchCodes) {
    const allBranchesIdx = branchCodes.indexOf('allBranches')
    if (allBranchesIdx && allBranchesIdx !== -1) {
      branchCodes.splice(allBranchesIdx, 1)
      branchCodes.unshift('allBranches')
    }
    return branchCodes.map<OptionType>((s) => ({ label: s, value: s }))
  }

  return []
})
const dsContractLookbackPeriodOptions = createSelector(dashboardState, (state) =>
  (state.dsContractLookbackPeriodItems?.map<OptionType>((s) => ({ label: `直近 ${s}ヶ月`, value: s })) || []).sort(
    (a, b) => Number(a.value) - Number(b.value),
  ),
)

const selectedContractBranchCode = createSelector(dashboardState, (state) => state.selectedContractBranchCode)
const selectedLookbackPeriod = createSelector(dashboardState, (state) => state.selectedLookbackPeriod)

const contractInderstyColor = createSelector(dashboardContract, (state) => {
  const locPieChartColors = [...PieChartColors]

  return (state || []).reduce<{ [name: string]: string }>((res, item) => {
    const itemKeys = Object.keys(item)

    for (const chartDataKey of itemKeys) {
      const chartData = item[chartDataKey as keyof typeof item]
      if (chartData && typeof chartData === 'object' && 'name' in chartData && !res[chartData.name]) {
        const shiftedColor = locPieChartColors.shift()
        if (shiftedColor) res[chartData.name] = shiftedColor
      }
    }

    return res
  }, {})
})

const dsContractCorporateTypeOptions = createSelector(
  dashboardState,
  (state) => state.dsIndustryCorporateTypeItems?.map<OptionType>((s) => ({ label: s, value: s })) || [],
)
const dsContractIndustry1Options = createSelector(
  dashboardState,
  (state) => state.dsIndustryIndustry1Items?.map<OptionType>((s) => ({ label: s, value: s })) || [],
)
const dsContractIndustry2Options = createSelector(
  dashboardState,
  (state) => state.dsIndustryIndustry2Items?.map<OptionType>((s) => ({ label: s, value: s })) || [],
)
const dsContractIndustry3Options = createSelector(
  dashboardState,
  (state) => state.dsIndustryIndustry3Items?.map<OptionType>((s) => ({ label: s, value: s })) || [],
)

const selectedCorporateType = createSelector(dashboardState, (state) => state.selectedCorporateType)
const selectedIndustry1 = createSelector(dashboardState, (state) => state.selectedIndustry1)
const selectedIndustry2 = createSelector(dashboardState, (state) => state.selectedIndustry2)
const selectedIndustry3 = createSelector(dashboardState, (state) => state.selectedIndustry3)

const dsCompanyBranchCodeOptions = createSelector(dashboardState, (state) => {
  const resultOptions: OptionType[] = [{ label: 'All Branches', value: 'allBranches' }]
  if (state.dsCompanyBranchCodeItems) {
    resultOptions.push(...state.dsCompanyBranchCodeItems.map<OptionType>((s) => ({ label: s, value: s })))
  }
  return resultOptions
})

const selectedCompanyBranchCode = createSelector(dashboardState, (state) => state.selectedCompanyBranchCode)
const dashboardCompanyPage = createSelector(dashboardState, (state) => state.dashboardCompanyPage)

export const dashboardSelector = {
  status,

  dashboardCompanyRecords,
  dashboardCompanies,
  dashboardCompaniesIsMainbank,
  dashboardCompanyPieChartDatas,

  totalCompanyCount,
  unspecifiedIndustryCompanyCount,
  unspecifiedIndustryCompanyRate,

  dashboardIndustry,
  dashboardContract,

  contractInderstyColor,

  dsContractBranchCodeOptions,
  dsContractLookbackPeriodOptions,

  selectedContractBranchCode,
  selectedLookbackPeriod,

  dsContractCorporateTypeOptions,
  dsContractIndustry1Options,
  dsContractIndustry2Options,
  dsContractIndustry3Options,

  selectedCorporateType,
  selectedIndustry1,
  selectedIndustry2,
  selectedIndustry3,

  dsCompanyBranchCodeOptions,

  selectedCompanyBranchCode,
  dashboardCompanyPage,
}
