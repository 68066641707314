import React, { useEffect, useReducer, useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { Input } from 'src/component/atom/input/input'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { InputPassword } from 'src/component/molecule/input-password/input-password'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { useFormControl } from 'src/hook/use-form-control'
import { userAction } from 'src/redux/user/user.action'
import { userSelector } from 'src/redux/user/user.state'
import imageSignUp from 'src/style/image/image-sign-up.svg'
import { ReduxStatusEnum } from 'src/type/common.type'
import { validators } from 'src/util/validators.util'
import styles from './sign-up.scss'

type PropsType = {
  onClose?(): void
}

export const SignUpModal = withModalWrapper(({ onClose }: PropsType) => {
  const dispatch = useDispatch()

  const [touched, setTouched] = useReducer(
    (state: { [inputName: string]: boolean }, newState: { [inputName: string]: boolean }) => {
      return { ...state, ...newState }
    },
    {},
  )

  const [username, setUsername, nameError] = useFormControl('', [validators.isRequired])
  const [passwordPattern, setPasswordPattern, passwordPatternError] = useFormControl('', [validators.isRequired])
  const [password, setPassword, passwordError] = useFormControl('', [
    validators.isRequired,
    validators.matchValue(passwordPattern),
  ])
  const [userNickname, setUserNickname, userNicknameError] = useFormControl('', [validators.isRequired])
  const [branchId, setBranchId, branchError] = useFormControl<number | undefined>(undefined, [])
  const [department, setDepartment, departmentError] = useFormControl<string | undefined>(undefined, [])

  const isError = nameError || passwordError || userNicknameError || branchError || departmentError

  const [signUpClick, setSignUpClick] = useState(false)
  const userStatus = useSelector(userSelector.status)
  const requestedSignUpUsername = useSelector(userSelector.requestedSignUpUsername)

  useEffect(() => {
    if (signUpClick && userStatus === ReduxStatusEnum.IDLE && requestedSignUpUsername) {
      onClose && onClose()
    }
  }, [requestedSignUpUsername])

  function handleClickSignUpButton() {
    setSignUpClick(true)
    dispatch(
      userAction.signup.request({ username, userNickname, password, branchId, department, level: 1, activate: false }),
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.formHeader}>
          <InlineSVG src={imageSignUp} />
          <div>
            <div className={styles.headerText}>{`Get started!`}</div>
            <div className={styles.descText}>{`Please create an account to proceed`}</div>
          </div>
        </div>
        <div className={styles.formItem}>
          <span className={styles.inputLabel}>{`ユーザー名`}</span>
          <Input
            sizeType='lg'
            onChange={(e) => setUsername(e.currentTarget.value)}
            onFocus={() => setTouched({ username: true })}
            isError={touched.username && !!nameError}
          />
          <div className={styles.errorText}>{touched.username && !!nameError && 'username error text'}</div>
        </div>
        <div className={styles.formItem}>
          <span className={styles.inputLabel}>{`パスワード`}</span>
          <InputPassword
            sizeType='lg'
            onChange={(e) => setPasswordPattern(e.currentTarget.value)}
            onFocus={() => setTouched({ passwordPattern: true })}
            isError={touched.passwordPattern && !!passwordPatternError}
          />
          <div className={styles.errorText}>
            {touched.passwordPattern && !!passwordPatternError && 'password pattern error text'}
          </div>
        </div>
        <div className={styles.formItem}>
          <span className={styles.inputLabel}>{`Confirm パスワード`}</span>
          <InputPassword
            type='password'
            sizeType='lg'
            onChange={(e) => setPassword(e.currentTarget.value)}
            onFocus={() => setTouched({ password: true })}
            isError={touched.password && !!passwordError}
          />
          <div className={styles.errorText}>{touched.password && !!passwordError && 'password error text'}</div>
        </div>
        <div className={styles.formItem}>
          <span className={styles.inputLabel}>{`ユーザーNickname`}</span>
          <Input
            sizeType='lg'
            onChange={(e) => setUserNickname(e.currentTarget.value)}
            onFocus={() => setTouched({ userNickname: true })}
            isError={touched.userNickname && !!userNicknameError}
          />
          <div className={styles.errorText}>
            {touched.userNickname && !!userNicknameError && 'userNickname error text'}
          </div>
        </div>
        <div className={styles.formItem}>
          <span className={styles.inputLabel}>{`Department`}</span>
          <Input
            sizeType='lg'
            onChange={(e) => setDepartment(e.currentTarget.value)}
            onFocus={() => setTouched({ department: true })}
            isError={touched.department && !!departmentError}
          />
          <div className={styles.errorText}>{touched.department && !!departmentError && 'department error text'}</div>
        </div>
        <div className={styles.formItem}>
          <span className={styles.inputLabel}>{`Branch`}</span>
          <DropdownSingleSelector
            sizeType='lg'
            isNeededFilter
            options={[]}
            onChange={(op) => setBranchId(op.value)}
            onFocus={() => setTouched({ branch: true })}
          />
          <div className={styles.errorText}>{touched.branch && !!branchError && 'branch error text'}</div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          className={styles.submitBtn}
          buttonColor='secondary'
          disabled={!!isError}
          onClick={handleClickSignUpButton}
        >
          Start your Journey
        </Button>
      </div>
    </div>
  )
})
