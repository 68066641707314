import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProjectStep } from 'src/component/molecule/project-step/project-step'
import { Header } from 'src/component/organism/header/header'
import { ProjectTypesIntl } from 'src/intl/data/project.key'
import { notificationAction } from 'src/redux/notification/notification.action'
import { notificationSelector } from 'src/redux/notification/notification.state'
import { projectSelector } from 'src/redux/project/project.state'
import { userSelector } from 'src/redux/user/user.state'
import { ProjectType, ProjectTypesEnum } from 'src/type/project.type'
import { BluePrintPanel } from './blue-print/blue-print.panel'
import { GoalPanel } from './goal/goal.panel'
import styles from './project.scss'
import { ResultPanel } from './result/result.panel'
import { TrainingPanel } from './training/training.panel'
import { UploadPanel } from './upload/upload.panel'

type PropsType = {
  project: ProjectType
}

export const ProjectPage = ({ project }: PropsType) => {
  const dispatch = useDispatch()

  const user = useSelector(userSelector.user)
  const projectStep = useSelector(projectSelector.selectedProjectStep)
  const alertLogs = useSelector(notificationSelector.alertLogs)
  const notReadedLogIds = useSelector(notificationSelector.notReadedLogIds)

  function handleOpenNotification() {
    if (notReadedLogIds.length > 0) {
      dispatch(notificationAction.updateAlertLog.request())
    }
  }

  return (
    <div className={styles.root}>
      {user && (
        <Header user={user} alertLogs={alertLogs} onOpenNotification={handleOpenNotification}>
          <div className={styles.headerText}>{ProjectTypesIntl[project.type].name}</div>
          <ProjectStep
            step={projectStep}
            stepItemLabels={
              project.type === ProjectTypesEnum.CORP_SALES_INSIGHTS ? stepItemLabelsExceptResult : stepItemLabels
            }
          />
        </Header>
      )}
      {projectStep === 1 && <GoalPanel project={project} />}
      {projectStep === 2 && <UploadPanel project={project} />}
      {projectStep === 3 && <BluePrintPanel project={project} />}
      {projectStep === 4 && <TrainingPanel />}
      {projectStep === 5 && <ResultPanel project={project} />}
    </div>
  )
}

const stepItemLabels = ['ビジネスゴール', 'データのアップロード', 'データマッピング', 'AIによる学習', '予測結果']
const stepItemLabelsExceptResult = ['ビジネスゴール', 'データのアップロード', 'データマッピング', 'AIによる学習']
