import { createSelector } from '@reduxjs/toolkit'
import { RootStateType } from 'src/redux/root.reducer'
import { ReduxStatusEnum } from 'src/type/common.type'
import { DatasetTypesEnum, DatasetType } from 'src/type/dataset.type'

const datasetState = (state: RootStateType) => state.datasetState
const status = createSelector(datasetState, (state) =>
  state.pendingCount > 0 ? ReduxStatusEnum.PENDING : ReduxStatusEnum.IDLE,
)
const isDatasets = createSelector(datasetState, (state) => !!state.datasetObjs)
const datasetObjs = createSelector(datasetState, (state) => state.datasetObjs ?? {})
const datasetIds = createSelector(datasetState, (state) => state.datasetIds ?? [])
const datasets = createSelector(datasetIds, datasetObjs, (datasetIds, datasetObjs) => {
  return datasetIds.map((id) => datasetObjs[id])
})
const datasetWithDatasetTypes = createSelector(datasets, (state) => {
  const mappedDatasets = state.reduce<Map<DatasetTypesEnum, DatasetType>>((res, dataset) => {
    res.set(dataset.type, dataset)
    return res
  }, new Map())

  return Object.fromEntries(mappedDatasets.entries())
})

export const datasetSelector = {
  datasetState,
  status,
  isDatasets,
  datasets,
  datasetObjs,
  datasetWithDatasetTypes,
}
