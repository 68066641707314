import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { IndustryInsight } from './industry-insight/industry-insight'
import { SalesInsight } from './sales-insight/sales-insight'

export const DashboardRoute = () => {
  return (
    <Routes>
      <Route index element={<Navigate replace to='industry-ins' />} />
      <Route path='industry-ins' element={<IndustryInsight />} />
      <Route path='sales-ins' element={<SalesInsight />} />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}
