import { createAction, createReducer } from '@reduxjs/toolkit'
import { createAsyncAction } from 'src/redux/redux.type'
import { DatasetDictType, DatasetType } from 'src/type/dataset.type'
import { CreateDatasetParamsType, DeleteDatasetParamsType } from './dataset.service'

type DatasetStateType = {
  pendingCount: number
  datasetObjs: DatasetDictType | null
  datasetIds: number[] | null
}

const init = createAction('[dataset] init')
const increasePendingCount = createAction('[dataset] increase async action pending count')
const decreasePendingCount = createAction('[dataset] dencrease async action pending count')
const fetchDatasets = createAsyncAction<{ projectId: number }>('[dataset] fetch datasets')
const fetchDatasetsInProgress = createAsyncAction<{ projectId: number }>('[dataset] fetch datasets in task progress')
const setDatasets = createAction<DatasetType[]>('[dataset] set datasets')
const fetchDatasetInProgress = createAsyncAction<{ datasetId: number }>('[dataset] fetch a dataset in task progress')

const createDataset = createAsyncAction<CreateDatasetParamsType>('[dataset] create dataset')
const setDataset = createAction<{ datasetId: number; dataset: Partial<DatasetType> }>('[dataset] set dataset')

const deleteDataset = createAsyncAction<DeleteDatasetParamsType>('[dataset] delete delete')
const removeDataset = createAction<{ datasetId: number }>('[dataset] remove dataset state')

export const datasetAction = {
  init,
  increasePendingCount,
  decreasePendingCount,
  fetchDatasets,
  fetchDatasetsInProgress,
  setDatasets,
  fetchDatasetInProgress,

  createDataset,
  setDataset,

  deleteDataset,
  removeDataset,
}

const initDatasetState: DatasetStateType = {
  pendingCount: 0,
  datasetObjs: null,
  datasetIds: null,
}

export const datasetReducer = createReducer(initDatasetState, (builder) =>
  builder
    .addCase(init, () => initDatasetState)
    .addCase(increasePendingCount, (state) => {
      state.pendingCount++
    })
    .addCase(decreasePendingCount, (state) => {
      state.pendingCount--
    })
    .addCase(setDatasets, (state, { payload }) => {
      state.datasetObjs = payload.reduce<DatasetDictType>((res, dataset) => {
        res[dataset.id] = dataset
        return res
      }, {})

      state.datasetIds = payload.map((data) => data.id)
    })
    .addCase(setDataset, (state, { payload }) => {
      if (!state.datasetObjs) {
        state.datasetObjs = {}
      }

      if (!state.datasetIds) {
        state.datasetIds = [payload.datasetId]
      } else if (!state.datasetObjs[payload.datasetId]) {
        state.datasetIds.push(payload.datasetId)
      }

      state.datasetObjs[payload.datasetId] = { ...state.datasetObjs[payload.datasetId], ...payload.dataset }
    })
    .addCase(removeDataset, (state, { payload }) => {
      if (state.datasetIds) state.datasetIds = state.datasetIds.filter((datasetId) => datasetId !== payload.datasetId)
      if (state.datasetObjs) delete state.datasetObjs[payload.datasetId]
    }),
)
