import { createAction, createReducer } from '@reduxjs/toolkit'
import { ProjectTypesEnum } from 'src/type/project.type'
import { createAsyncAction } from '../redux.type'

type SoftwareInfoStateType = {
  pendingCount: number
  availableTopics?: ProjectTypesEnum[]
}

const increasePendingCount = createAction('[software info] increase async action pending count')
const decreasePendingCount = createAction('[software info] dencrease async action pending count')

const fetchSoftwareInfo = createAsyncAction('[software info] fetch software info')
const setSoftwareInfo = createAction<{ availableTopics: ProjectTypesEnum[] }>('[software info] set system info')

export const softwareInfoAction = {
  increasePendingCount,
  decreasePendingCount,
  fetchSoftwareInfo,
  setSoftwareInfo,
}

const initSoftwareInfoState: SoftwareInfoStateType = {
  pendingCount: 0,
  availableTopics: undefined,
}

export const softwareInfoReducer = createReducer(initSoftwareInfoState, (builder) =>
  builder.addCase(setSoftwareInfo, (state, { payload }) => {
    state.availableTopics = payload.availableTopics
  }),
)
