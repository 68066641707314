import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'src/component/atom/button/button'
import { Input } from 'src/component/atom/input/input'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { ProjectTypesIntl } from 'src/intl/data/project.key'
import { projectAction } from 'src/redux/project/project.action'
import { projectSelector } from 'src/redux/project/project.state'
import { ReduxStatusEnum } from 'src/type/common.type'
import { ProjectTypesEnum } from 'src/type/project.type'
import styles from './add-project.scss'

type PropsType = {
  projectType: ProjectTypesEnum
  onClose(): void
}

export const AddProjectModal = withModalWrapper(({ projectType, onClose }: PropsType) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [name, setName] = useState(ProjectTypesIntl[projectType].defaultProjectName)
  const [createClick, setCreateClick] = useState(false)

  const projectStatus = useSelector(projectSelector.status)
  const selectedProjectId = useSelector(projectSelector.selectedProjectId)

  useEffect(() => {
    if (projectStatus === ReduxStatusEnum.IDLE && selectedProjectId) {
      navigate('/project/' + selectedProjectId)
      onClose()
    }
  }, [selectedProjectId])

  function handleCreateClick() {
    setCreateClick(true)
    dispatch(projectAction.createProject.request({ name, type: projectType }))
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <label className={styles.label}>{`テーマ`}</label>
        <label className={styles.type}>{ProjectTypesIntl[projectType].title}</label>
        <label className={styles.label}>{`プロジェクト名`}</label>
        <Input sizeType='md' value={name} onChange={(e) => setName(e.currentTarget.value)} />
      </div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleCreateClick} disabled={createClick}>
          {`追加`}
        </Button>
      </div>
    </div>
  )
}, '新たなプロジェクト')
