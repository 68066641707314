import { EMPTY } from 'rxjs'
import { toastTool } from 'src/tool/toast.tool'
import { isReadyAiAjaxError } from 'src/type/error.type'

export function httpErrorhandling(err: Error) {
  if (isReadyAiAjaxError(err)) {
    if (err.response) {
      toastTool.errorTemplate(err.response.code, err.response.message, err.response.info)
    } else {
      toastTool.errorTemplate(`${err.status}`, err.message, {})
    }
  } else {
    toastTool.errorString(err.message)
  }
  return EMPTY
}
