import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from 'src/component/atom/checkbox/checkbox'
import { Input } from 'src/component/atom/input/input'
import { useVisibleWithOutsideClick } from 'src/hook/use-visible-with-outside-click'
import { ProjectTypesIntl } from 'src/intl/data/project.key'
import { ProjectResultIntl } from 'src/intl/data/result.key'
import { projectSelector } from 'src/redux/project/project.state'
import IconPencil from 'src/style/icon/icon-pencil.svg'
import { ReduxStatusEnum } from 'src/type/common.type'
import { ProjectType, ProjectTypesEnum } from 'src/type/project.type'
import { ProjectTypesProperties } from 'src/ui-variable/project.ui'
import styles from './grid-row-item.scss'

type PropsType = {
  gridType: 'summary' | 'panel'
  project: ProjectType
  available?: boolean
  onChangeCheckbox?(e: React.ChangeEvent<HTMLInputElement>): void
  onChangeProjectName?(projectId: number, name: string): void
}

export const GridRowItem = ({ gridType, project, available, onChangeCheckbox, onChangeProjectName }: PropsType) => {
  const navigate = useNavigate()

  const { ref, isVisible, setIsVisible } = useVisibleWithOutsideClick<HTMLInputElement>(false)
  const [editClick, setEditClick] = useState(false)

  const projectStatus = useSelector(projectSelector.status)

  useEffect(() => {
    if (editClick === true && projectStatus === ReduxStatusEnum.IDLE) {
      setIsVisible(false)
    }
  }, [projectStatus])

  useEffect(() => {
    if (isVisible) {
      ref.current?.select()
    }
  }, [isVisible, ref.current])

  function changeProjectName(id: number, newName: string) {
    if (newName) {
      onChangeProjectName && onChangeProjectName(id, newName)
      setEditClick(true)
    } else {
      setIsVisible(false)
    }
  }

  function handleEditClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    setIsVisible(!isVisible)
  }

  function handleInputBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
    changeProjectName(project.id, e.currentTarget.value)
  }

  function handleInputEnterKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter' && available) {
      changeProjectName(project.id, e.currentTarget.value)
    }
  }

  function handleRowClick(id: number) {
    if (available) {
      navigate(`/project/${id}`)
    }
  }

  return (
    <ul
      className={classNames(styles.bodyRow, {
        [styles.panelGridTemplate]: gridType === 'panel',
        [styles.summaryGridTemplate]: gridType === 'summary',
        [styles.notAvailable]: !available,
      })}
      onClick={() => handleRowClick(project.id)}
    >
      {gridType === 'panel' && (
        <li className={styles.checkCell}>
          <Checkbox type='checkbox' value={project.id} onChange={onChangeCheckbox} />
        </li>
      )}
      <li className={classNames(styles.bodyCell, styles.projectName)}>
        {isVisible ? (
          <Input
            ref={ref}
            sizeType='xs'
            defaultValue={project.name}
            isFullWidth
            onClick={(e) => e.stopPropagation()}
            onBlur={handleInputBlur}
            onKeyDown={handleInputEnterKeyDown}
          />
        ) : (
          <>
            <span>{project.name}</span>
            {gridType === 'panel' && (
              <div className={styles.editNameButton} onClick={handleEditClick}>
                <InlineSVG src={IconPencil} />
              </div>
            )}
          </>
        )}
      </li>
      <li
        className={classNames(styles.bodyCell, {
          [styles.projectInfo]: project.projectResult.length > 0,
          [styles.primaryColor]:
            project.projectResult.length === 0 ||
            (project.projectResult.length <= 1 && project.type === ProjectTypesEnum.CORP_SALES_INSIGHTS),
          [styles.notAvailable]: !available,
        })}
      >
        {project.type !== ProjectTypesEnum.CORP_SALES_INSIGHTS && project.projectResult.length > 0 ? (
          <>
            <span className={styles.description}>
              {`${ProjectResultIntl[project.type].kpiRatioSummery}: ${(
                (project.projectResult[0].kpiRatio || 0) * 100
              ).toFixed(2)} % `}
            </span>
          </>
        ) : project.type === ProjectTypesEnum.CORP_SALES_INSIGHTS && project.projectResult.length > 1 ? (
          `分析完了`
        ) : (
          `作業中`
        )}
      </li>
      <li className={styles.bodyCell}>{new Date(project.createdAt).toLocaleDateString()}</li>
      <li
        className={classNames(styles.bodyCell, styles[`${ProjectTypesProperties[project.type].color}Color`], {
          [styles.notAvailable]: !available,
        })}
      >
        {ProjectTypesIntl[project.type].name}
      </li>
    </ul>
  )
}
