import React from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import IconCheckboxChecked from 'src/style/icon/icon-checkbox-checked.svg'
import styles from './checkbox.scss'

type PropsType = {
  checked?: boolean
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
} & React.InputHTMLAttributes<HTMLInputElement>

export const Checkbox = ({ checked, onChange, ...props }: PropsType) => {
  function handleLabelClick(e: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
    e.stopPropagation()
  }

  return (
    <label className={styles.root} onClick={handleLabelClick}>
      <input {...props} type='checkbox' checked={checked} onChange={onChange} />
      <InlineSVG className={styles.checked} src={IconCheckboxChecked} />
      <div className={styles.emptyBox} />
    </label>
  )
}
