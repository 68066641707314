import { createSelector } from '@reduxjs/toolkit'
import { ReduxStatusEnum } from 'src/type/common.type'
import { RootStateType } from '../root.reducer'

const softwareInfoState = (state: RootStateType) => state.softwareInfoState
const status = createSelector(softwareInfoState, (state) =>
  state.pendingCount > 0 ? ReduxStatusEnum.PENDING : ReduxStatusEnum.IDLE,
)
const availableTopics = createSelector(softwareInfoState, (state) => state.availableTopics)

export const softwareInfoSelector = {
  status,
  availableTopics,
}
