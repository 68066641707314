import { Action } from 'redux'
import { combineEpics, Epic, StateObservable } from 'redux-observable'
import { catchError, filter, forkJoin, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs'
import { ProjectStepEnum } from 'src/type/project.type'
import { TaskProgressType } from 'src/type/task.type'
import { ProjectTypesProperties } from 'src/ui-variable/project.ui'
import { httpErrorhandling } from '../redux.util'
import { RootStateType } from '../root.reducer'
import { unionSelector } from '../union/union.state'
import { projectAction } from './project.action'
import { projectService } from './project.service'

const pendingStartEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        projectAction.fetchProjects.request.type,
        projectAction.fetchProjectGoal.request.type,
        projectAction.fetchProjectDetail.request.type,
        projectAction.createProject.request.type,
        projectAction.deleteProject.request.type,
        projectAction.updateProject.request.type,
        projectAction.updateProjectSetting.request.type,
        projectAction.fetchProjectResultTargets.request.type,
        projectAction.fetchProjectResultTargetsPrev.request.type,
        projectAction.fetchProjectResultSegments.request.type,
        projectAction.downloadProjectResultTargets.request.type,
      ].includes(action.type),
    ),
    map(() => projectAction.increasePendingCount()),
  )

const pendingEndEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        projectAction.fetchProjects.success.type,
        projectAction.fetchProjects.failure.type,
        projectAction.fetchProjects.cancelled.type,
        projectAction.fetchProjectGoal.success.type,
        projectAction.fetchProjectGoal.failure.type,
        projectAction.fetchProjectGoal.cancelled.type,
        projectAction.fetchProjectDetail.success.type,
        projectAction.fetchProjectDetail.failure.type,
        projectAction.fetchProjectDetail.cancelled.type,
        projectAction.createProject.success.type,
        projectAction.createProject.failure.type,
        projectAction.createProject.cancelled.type,
        projectAction.deleteProject.success.type,
        projectAction.deleteProject.failure.type,
        projectAction.deleteProject.cancelled.type,
        projectAction.updateProject.success.type,
        projectAction.updateProject.failure.type,
        projectAction.updateProject.cancelled.type,
        projectAction.updateProjectSetting.success.type,
        projectAction.updateProjectSetting.failure.type,
        projectAction.updateProjectSetting.cancelled.type,
        projectAction.fetchProjectResultTargets.success.type,
        projectAction.fetchProjectResultTargets.failure.type,
        projectAction.fetchProjectResultTargets.cancelled.type,
        projectAction.fetchProjectResultTargetsPrev.success.type,
        projectAction.fetchProjectResultTargetsPrev.failure.type,
        projectAction.fetchProjectResultTargetsPrev.cancelled.type,
        projectAction.fetchProjectResultSegments.success.type,
        projectAction.fetchProjectResultSegments.failure.type,
        projectAction.fetchProjectResultSegments.cancelled.type,
        projectAction.downloadProjectResultTargets.success.type,
        projectAction.downloadProjectResultTargets.failure.type,
        projectAction.downloadProjectResultTargets.cancelled.type,
      ].includes(action.type),
    ),
    map(() => projectAction.decreasePendingCount()),
  )

const pendingFailureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) =>
      [
        projectAction.fetchProjects.failure.type,
        projectAction.fetchProjectGoal.failure.type,
        projectAction.fetchProjectDetail.failure.type,
        projectAction.createProject.failure.type,
        projectAction.deleteProject.failure.type,
        projectAction.updateProject.failure.type,
        projectAction.updateProjectSetting.failure.type,
        projectAction.fetchProjectResultTargets.failure.type,
        projectAction.fetchProjectResultTargetsPrev.failure.type,
        projectAction.fetchProjectResultSegments.failure.type,
        projectAction.downloadProjectResultTargets.failure.type,
      ].includes(action.type),
    ),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const fetchProjectsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.fetchProjects.request.match),
    switchMap(() =>
      projectService.fetchProjects().pipe(
        mergeMap(({ response }) => [projectAction.fetchProjects.success(), projectAction.setProjects(response)]),
        catchError((err) => of(projectAction.fetchProjects.failure(err))),
      ),
    ),
  )

const fetchProjectsInProgressEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.fetchProjectsInProgress.request.match),
    switchMap(() =>
      projectService.fetchProjects().pipe(
        mergeMap(({ response }) => [
          projectAction.fetchProjectsInProgress.success(),
          projectAction.setProjects(response),
        ]),
        catchError((err) => of(projectAction.fetchProjectsInProgress.failure(err))),
      ),
    ),
  )

const createProjectEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.createProject.request.match),
    switchMap(({ payload }) =>
      projectService.createProject(payload).pipe(
        mergeMap(({ response }) => [
          projectAction.createProject.success(),
          projectAction.setProject(response),
          projectAction.setSelectedProjectId({ projectId: response.id }),
        ]),
        catchError((err) => [projectAction.initSelectedProjectId(), projectAction.createProject.failure(err)]),
      ),
    ),
  )

const deleteProjectEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.deleteProject.request.match),
    mergeMap(({ payload }) =>
      forkJoin(payload.ids.map((id) => projectService.deleteProject({ id }))).pipe(
        mergeMap(() => [
          projectAction.deleteProject.success(),
          projectAction.removeProject({ projectIds: payload.ids }),
        ]),
        catchError((err) => [projectAction.deleteProject.failure(err)]),
      ),
    ),
  )

const updateProjectEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.updateProject.request.match),
    switchMap(({ payload }) =>
      projectService.updateProject(payload).pipe(
        mergeMap(({ response }) => [projectAction.updateProject.success(), projectAction.setProject(response)]),
        catchError((err) => [projectAction.updateProject.failure(err)]),
      ),
    ),
  )

const fetchProjectDetailEpic: Epic = (actions$: Observable<Action>, state$: StateObservable<RootStateType>) =>
  actions$.pipe(
    filter(projectAction.fetchProjectDetail.request.match),
    switchMap(({ payload }) =>
      projectService.fetchProjectDetail(payload).pipe(
        mergeMap(({ response }) =>
          of(unionSelector.selectedProjectTrainingProgresses(state$.value)).pipe(
            filter((prg): prg is TaskProgressType[] => !!prg),
            mergeMap((trainingProcess) => {
              const returnActions: Action[] = [
                projectAction.fetchProjectDetail.success(),
                projectAction.setProjectDetail(response),
              ]

              if (response.projectResult.length > 0) {
                returnActions.push(projectAction.setProjectStep(ProjectStepEnum.TRAINNING))
              } else if (trainingProcess.length > 0) {
                returnActions.push(projectAction.setProjectStep(ProjectStepEnum.TRAINNING))
              } else if (
                response.bluePrintMappings.length > 0 &&
                response.dataSets.length >= ProjectTypesProperties[response.type].datasetCategories.length
              ) {
                returnActions.push(projectAction.setProjectStep(ProjectStepEnum.BLUE_PRINT))
              } else if (response.dataSets.length > 0) {
                returnActions.push(projectAction.setProjectStep(ProjectStepEnum.DATASET))
              } else {
                returnActions.push(projectAction.setProjectStep(ProjectStepEnum.SETTING))
              }

              return returnActions
            }),
          ),
        ),
        catchError((err) => of(projectAction.fetchProjectDetail.failure(err))),
      ),
    ),
  )

const fetchProjectDetailInProgressEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.fetchProjectDetailInProgress.request.match),
    switchMap(({ payload }) =>
      projectService.fetchProjectDetail(payload).pipe(
        mergeMap(({ response }) => [
          projectAction.fetchProjectDetailInProgress.success(),
          projectAction.setProjectDetail(response),
        ]),
        catchError((err) => of(projectAction.fetchProjectDetailInProgress.failure(err))),
      ),
    ),
  )

const fetchProjectGoalEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.fetchProjectGoal.request.match),
    switchMap(({ payload }) =>
      projectService.fetchProjectGoal(payload).pipe(
        mergeMap(({ response }) => [projectAction.fetchProjectGoal.success(), projectAction.setProjectGoal(response)]),
        catchError((err) => of(projectAction.fetchProjectGoal.failure(err))),
      ),
    ),
  )

const updateProjectSettingEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.updateProjectSetting.request.match),
    mergeMap(({ payload }) =>
      forkJoin([
        ...payload.goalParams.map((params) =>
          projectService.updateProjectSetting({
            projectId: payload.projectId,
            goalId: params.goalId,
            value: params.value,
          }),
        ),
        ...payload.deleteGoalParams.map((params) =>
          projectService.deleteProjectSetting({
            projectId: payload.projectId,
            goalId: params.goalId,
          }),
        ),
      ]).pipe(
        mergeMap(() => [
          projectAction.updateProjectSetting.success(),
          projectAction.fetchProjectDetail.request({ id: payload.projectId }),
        ]),
        catchError((err) => of(projectAction.updateProjectSetting.failure(err))),
      ),
    ),
  )

const trainingProjectEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.trainingProject.request.match),
    switchMap(({ payload }) =>
      projectService.trainingProject(payload).pipe(
        mergeMap(() => of(projectAction.trainingProject.success())),
        catchError((err) => of(projectAction.trainingProject.failure(err))),
      ),
    ),
  )

const fetchProjectResultTargetsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.fetchProjectResultTargets.request.match),
    switchMap(({ payload }) =>
      projectService.fetchProjectResultTargets(payload).pipe(
        mergeMap(({ response }) => [
          projectAction.fetchProjectResultTargets.success(),
          projectAction.setProjectResultTargets(response),
        ]),
        catchError((err) => of(projectAction.fetchProjectResultTargets.failure(err))),
      ),
    ),
  )

const fetchProjectResultTargetsInProgressEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.fetchProjectResultTargetsInProgress.request.match),
    switchMap(({ payload }) =>
      projectService.fetchProjectResultTargets(payload).pipe(
        mergeMap(({ response }) => [
          projectAction.fetchProjectResultTargetsInProgress.success(),
          projectAction.setProjectResultTargets(response),
        ]),
        catchError((err) => of(projectAction.fetchProjectResultTargetsInProgress.failure(err))),
      ),
    ),
  )

const fetchProjectResultTargetsPrevEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.fetchProjectResultTargetsPrev.request.match),
    switchMap(({ payload }) =>
      projectService.fetchProjectResultTargets(payload).pipe(
        mergeMap(({ response }) => [
          projectAction.fetchProjectResultTargetsPrev.success(),
          projectAction.setProjectResultTargetsPrev(response),
        ]),
        catchError((err) => of(projectAction.fetchProjectResultTargetsPrev.failure(err))),
      ),
    ),
  )

const fetchProjectResultSegmentsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.fetchProjectResultSegments.request.match),
    switchMap(({ payload }) =>
      projectService.fetchProjectResultSegments(payload).pipe(
        mergeMap(({ response }) => [
          projectAction.fetchProjectResultSegments.success(),
          projectAction.setProjectResultSegments(response),
        ]),
        catchError((err) => of(projectAction.fetchProjectResultSegments.failure(err))),
      ),
    ),
  )

const downloadProjectResultTargetsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(projectAction.downloadProjectResultTargets.request.match),
    switchMap(({ payload }) =>
      projectService.downloadProjectResultTargets(payload).pipe(
        mergeMap(() => [projectAction.downloadProjectResultTargets.success()]),
        tap(() => {
          window.location.href = `/api/project/${payload.projectId}/download`
        }),
        catchError((err) => of(projectAction.downloadProjectResultTargets.failure(err))),
      ),
    ),
  )

export const projectEpic = combineEpics(
  pendingStartEpic,
  pendingEndEpic,
  pendingFailureEpic,
  fetchProjectsEpic,
  fetchProjectsInProgressEpic,
  createProjectEpic,
  deleteProjectEpic,
  updateProjectEpic,
  fetchProjectDetailEpic,
  fetchProjectDetailInProgressEpic,
  fetchProjectGoalEpic,
  updateProjectSettingEpic,
  trainingProjectEpic,
  fetchProjectResultTargetsEpic,
  fetchProjectResultTargetsInProgressEpic,
  fetchProjectResultTargetsPrevEpic,
  fetchProjectResultSegmentsEpic,
  downloadProjectResultTargetsEpic,
)
