import { AjaxError } from 'rxjs/ajax'

/* eslint-disable no-shadow */
export enum ErrorCodeEnum {
  // Auth0
  FAU1000 = 'FAU1000',
  // Server auth
  BAU1000 = 'BAU1000', // error
  BAU1001 = 'BAU1001', // not logged in
  BAU1002 = 'BAU1002', // id password not matched
  BAU1003 = 'BAU1003', // access token expired
  BAU1004 = 'BAU1004', // refreash token expired
  // Server
  BSE1000 = 'BSE1000',
  // Celery
  BCE1000 = 'BCE1000',
  BCE1001 = 'BCE1001',
  BCE1002 = 'BCE1002',
  BCE1003 = 'BCE1003',
  BCE1004 = 'BCE1004',
  // Database
  BDB1000 = 'BDB1000',
  BDB1001 = 'BDB1001',
  BDB1002 = 'BDB1002',
  BDB1003 = 'BDB1003',
  BDB1004 = 'BDB1004',
  BDB1005 = 'BDB1005',
  BDB1006 = 'BDB1006',
  BDB1007 = 'BDB1007',
  BDB1008 = 'BDB1008',
  BDB1009 = 'BDB1009',
  BDB1010 = 'BDB1010',
  BDB1011 = 'BDB1011',
  BDB1012 = 'BDB1012',
  BDB1013 = 'BDB1013',
  BDB1014 = 'BDB1014',
  BDB1015 = 'BDB1015',
  BDB1016 = 'BDB1016',
  BDB1017 = 'BDB1017',
  BDB1018 = 'BDB1018',
  BDB1019 = 'BDB1019',
  BDB1020 = 'BDB1020',
  BDB1021 = 'BDB1021',
  BDB1022 = 'BDB1022',
  BDB1023 = 'BDB1023',
  BDB1024 = 'BDB1024',
  BDB1025 = 'BDB1025',
  BDB1026 = 'BDB1026',
  BDB1027 = 'BDB1027',
  BDB1028 = 'BDB1028',
  BDB1029 = 'BDB1029',
  BDB1030 = 'BDB1030',
  BDB1031 = 'BDB1031',
  BDB1032 = 'BDB1032',
  BDB1033 = 'BDB1033',
  BDB1034 = 'BDB1034',
  BDB1035 = 'BDB1035',
  BDB1036 = 'BDB1036',
  BDB1037 = 'BDB1037',
  BDB1038 = 'BDB1038',
  BDB1039 = 'BDB1039',
  BDB1040 = 'BDB1040',
  BDB1041 = 'BDB1041',
  BDB1042 = 'BDB1042',
  BDB1043 = 'BDB1043',
  BDB1044 = 'BDB1044',
  BDB1045 = 'BDB1045',
  BDB1046 = 'BDB1046',
  BDB1047 = 'BDB1047',
  BDB1048 = 'BDB1048',
  BDB1049 = 'BDB1049',
  BDB1050 = 'BDB1050',
  BDB1051 = 'BDB1051',
  BDB1052 = 'BDB1052',
  // Redis
  BRE1000 = 'BRE1000',
  BRE1001 = 'BRE1001',
  BRE1002 = 'BRE1002',
  BRE1003 = 'BRE1003',
  BRE1004 = 'BRE1004',
  BRE1005 = 'BRE1005',
  BRE1006 = 'BRE1006',
  BRE1007 = 'BRE1007',
  BRE1008 = 'BRE1008',
  BRE1009 = 'BRE1009',
  BRE1010 = 'BRE1010',
  // Http
  BHT1000 = 'BHT1000',
  // Validation
  BVA1000 = 'BVA1000',
  BVA1001 = 'BVA1001',
  BVA1002 = 'BVA1002',
  BVA1003 = 'BVA1003',
  BVA1004 = 'BVA1004',
  BVA1005 = 'BVA1005',
  BVA1006 = 'BVA1006',
  BVA1007 = 'BVA1007',
  BVA1008 = 'BVA1008',
  BVA1009 = 'BVA1009',
  // File
  BFI1000 = 'BFI1000',
  BFI1001 = 'BFI1001',
  BFI1002 = 'BFI1002',
  // DataSet
  BDS1000 = 'BDS1000',
  BDS1001 = 'BDS1001',
}

export type ErrorType = {
  code: ErrorCodeEnum
  error: string
  message: string
  info: Record<string, any>
}

export interface ReadyAiAjaxError extends AjaxError {
  response: ErrorType | null
}

export function isReadyAiAjaxError(err: Error): err is ReadyAiAjaxError {
  return (err as ReadyAiAjaxError).status !== undefined
}
