import classNames from 'classnames'
import React, { useEffect } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { Input } from 'src/component/atom/input/input'
import { DropdownMultiOptions } from 'src/component/molecule/dropdown-multi-options/droprown-multi-options'
import { useVisibleWithOutsideClick } from 'src/hook/use-visible-with-outside-click'
import iconCircleCheck from 'src/style/icon/icon-circle-check.svg'
import iconDropdown from 'src/style/icon/icon-dropdown.svg'
import { OptionType } from 'src/type/common.type'
import styles from './dropdown-multi-selector.scss'

type PropsType = {
  options: OptionType[]
  selectedOptions?: OptionType[]
  placeholder?: string
  sizeType?: 'sm' | 'md' | 'lg'
  isNeededFilter?: boolean
  selectedVisible?: boolean
  isOpenUpside?: boolean
  isDarkDisabled?: boolean
  disabled?: boolean
  onChange?(options: OptionType[]): void
}

export const DropdownMultiSelector = ({
  options,
  selectedOptions,
  placeholder,
  sizeType,
  isNeededFilter,
  selectedVisible,
  isOpenUpside,
  isDarkDisabled,
  disabled,
  onChange,
}: PropsType) => {
  const { ref, isVisible, setIsVisible } = useVisibleWithOutsideClick<HTMLDivElement>(false)
  const [filterString, setFilterString] = React.useState<string>('')

  useEffect(() => {
    if (isVisible) {
      ref.current?.scrollIntoView({
        block: 'center',
      })
      if (isNeededFilter) {
        setFilterString('')
      }
    }
    if (!isVisible) {
      if (selectedOptions && selectedOptions.length > 0) {
        setFilterString(`selected ${selectedOptions.length} of ${options.length}`)
      } else {
        setFilterString('')
      }
    }
  }, [isVisible, selectedOptions])

  function handleOptionChange(options: OptionType[]) {
    onChange && onChange(options)
  }

  return (
    <div ref={ref} className={styles.root}>
      <div
        className={classNames(styles.control, {
          [styles.smSize]: sizeType === 'sm',
          [styles.mdSize]: sizeType === 'md',
          [styles.lgSize]: sizeType === 'lg',
          [styles.active]: isVisible,
          [styles.selectedVisible]: selectedVisible,
          [styles.isDarkDisabled]: isDarkDisabled,
          [styles.disabled]: disabled,
        })}
        onClick={() => !disabled && setIsVisible(!isVisible)}
      >
        <Input
          borderType='none'
          sizeType={sizeType}
          placeholder={isVisible ? 'Filter by name' : placeholder}
          isFullWidth
          value={filterString}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            setFilterString(e.currentTarget.value)
          }}
          readOnly={!isNeededFilter}
          onFocus={() => setIsVisible(true)}
          disabled={disabled}
        />
        {selectedVisible && (
          <InlineSVG
            className={classNames(styles.selectedIcon, { [styles.notSelectedIcon]: !selectedOptions })}
            src={iconCircleCheck}
          />
        )}
        <InlineSVG className={classNames(styles.dropdownIcon, { [styles.reversed]: isVisible })} src={iconDropdown} />
      </div>
      {isVisible && (
        <DropdownMultiOptions
          options={options}
          filterString={filterString}
          selectedOption={selectedOptions}
          sizeType={sizeType}
          active={isVisible}
          isOpenUpside={isOpenUpside}
          onChange={handleOptionChange}
        />
      )}
    </div>
  )
}
