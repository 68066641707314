import React from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { ProgressBar } from 'src/component/atom/progress-bar/progress-bar'
import { NavItem } from 'src/component/molecule/nav-item/nav-item'
import LogoReadyAI from 'src/style/logo/logo-readyai.svg'
import styles from './nav-bar.scss'

export type NavigationItemType = {
  path: string
  label: string
  svgIconSrc: string
  hoverSvgIconSrc?: string
  disabledClick?: boolean
}

type PropsType = {
  navigationItems: NavigationItemType[]
}

export const NavBar = ({ navigationItems }: PropsType) => {
  return (
    <div className={styles.root}>
      <InlineSVG src={LogoReadyAI} className={styles.logo} />
      <nav className={styles.navigation}>
        {navigationItems.map((navItem) => (
          <NavItem
            key={navItem.label}
            navigationPath={navItem.path}
            label={navItem.label}
            svgIconSrc={navItem.svgIconSrc}
            hoverSvgIconSrc={navItem.hoverSvgIconSrc}
            disabledClick={navItem.disabledClick}
          />
        ))}
      </nav>
      <div className={styles.storageArea}>
        <div className={styles.storageInfo}>10.5GB / 140GB</div>
        <ProgressBar sizeType='sm' progress={35} progressColor='primary' />
      </div>
    </div>
  )
}
