import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Button } from 'src/component/atom/button/button'
import { blueprintIntl } from 'src/intl/data/blueprint.key'
import { datasetLabelIntl } from 'src/intl/data/dataset.key'
import { KeyMapModal, MappedKeysType } from 'src/modal/key-map/key-map.modal'
import { BlueprintType, MappingInputType, MappedOptionValueType } from 'src/type/blueprint.type'
import { OptionType } from 'src/type/common.type'
import { DatasetType } from 'src/type/dataset.type'
import { ProjectTypesEnum } from 'src/type/project.type'
import { ProjectTypesProperties } from 'src/ui-variable/project.ui'
import styles from './key-map-row.scss'

type PropsType = {
  projectType: ProjectTypesEnum
  datasetWithDatasetTypes: { [key: string]: DatasetType }
  keyBlueprints: BlueprintType[]
  keysMappedOptionValues?: MappedOptionValueType[]
  mappedField?: { [fieldId: number]: number }
  onChange?(mappedKeys: { [blueprintId: number]: MappingInputType }): void
}

export const KeyMapRow = ({
  projectType,
  datasetWithDatasetTypes,
  keyBlueprints,
  keysMappedOptionValues = [],
  mappedField,
  onChange,
}: PropsType) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [firstKeyMap, setFirstKeyMap] = useState<OptionType[]>()
  const [secondKeyMap, setSecondKeyMap] = useState<OptionType[]>()

  const [firstBlueprint, secondaryBlueprint] = keyBlueprints

  const datasetFieldOptions = useMemo(
    () =>
      ProjectTypesProperties[projectType].datasetCategories.map((datasetTypes) => ({
        type: datasetTypes,
        title: datasetLabelIntl[projectType][datasetTypes].title,
        fields:
          (datasetWithDatasetTypes[datasetTypes].fields &&
            datasetWithDatasetTypes[datasetTypes].fields?.map((field) => ({ label: field.name, value: field.id }))) ??
          [],
      })),
    [datasetWithDatasetTypes],
  )

  useEffect(() => {
    setFirstKeyMap(keysMappedOptionValues[0]?.selectedFieldOptions)
    setSecondKeyMap(keysMappedOptionValues[1]?.selectedFieldOptions)
  }, [keysMappedOptionValues])

  function handleConfirmModal(mappedKeys: MappedKeysType) {
    setFirstKeyMap(mappedKeys.firstKeyMap)
    setSecondKeyMap(mappedKeys.secondKeyMap)

    onChange &&
      onChange(
        Object.values<OptionType[] | undefined>(mappedKeys).reduce<{ [blueprintId: number]: MappingInputType }>(
          (res, keyMap, idx) => {
            res[keyBlueprints[idx].id] = {
              bluePrintId: keyBlueprints[idx].id,
              fieldIds: keyMap ? keyMap.map((key) => ({ id: key.value })) : [{ id: undefined }],
            }
            return res
          },
          {},
        ),
      )
  }

  return (
    <>
      <ul className={styles.gridBodyRow}>
        <li className={styles.blueprintCell}>
          {blueprintIntl[projectType][firstBlueprint.name].name}
          {firstBlueprint.isRequired && <span className={styles.required}>*</span>}
        </li>
        <li className={styles.inputCell}>
          {firstKeyMap ? (
            <div className={styles.mappedKey}>
              {firstKeyMap.map((option, idx) => (
                <Fragment key={`${option.value}-${idx}`}>
                  {idx !== 0 && <span>=</span>}
                  <span>{option.label}</span>
                </Fragment>
              ))}
            </div>
          ) : (
            <div className={styles.placeholder}>{`マッピングされていません。`}</div>
          )}
          <Button sizeType='sm' onClick={() => setModalVisible(true)}>
            {`選択`}
          </Button>
        </li>
        <li className={styles.descCell}>{blueprintIntl[projectType][firstBlueprint.name].description}</li>
      </ul>
      {secondKeyMap && (
        <ul className={styles.gridBodyRow}>
          <li className={styles.blueprintCell}>
            {blueprintIntl[projectType][secondaryBlueprint.name].name}
            {secondaryBlueprint.isRequired && <span className={styles.required}>*</span>}
          </li>
          <li className={styles.inputCell}>
            <div className={styles.mappedKey}>
              <div className={styles.mappedKey}>
                {secondKeyMap.map((option, idx) => (
                  <Fragment key={`${option.value}-${idx}`}>
                    {idx !== 0 && <span>=</span>}
                    <span>{option.label}</span>
                  </Fragment>
                ))}
              </div>
            </div>
          </li>
          <li className={styles.descCell}>{blueprintIntl[projectType][firstBlueprint.name].description}</li>
        </ul>
      )}
      <KeyMapModal
        projectType={projectType}
        datasetFieldOptions={datasetFieldOptions}
        modalVisible={modalVisible}
        keysMappedOptionValues={keysMappedOptionValues}
        mappedField={mappedField}
        onConfirm={handleConfirmModal}
        onClose={() => setModalVisible(false)}
      />
    </>
  )
}
