import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import InlineSVG from 'react-inlinesvg/esm'
import closeIcon from 'src/style/icon/icon-x.svg'
import styles from './side-modal-page.scss'

export type PropsType = {
  title?: string
  modalAlignH?: 'left' | 'right'
  children: React.ReactNode
  onClose(): void
}

export const SideModalPage = ({ title, modalAlignH = 'right', children, onClose }: PropsType) => {
  const [container] = useState(document.createElement('div'))
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    document.body.appendChild(container)
    setIsLoaded(true)
    return () => {
      document.body.removeChild(container)
    }
  }, [container])

  function delayedClose() {
    setIsLoaded(false)
    setTimeout(onClose, 500)
  }

  return createPortal(
    <div
      className={classNames(styles.root, {
        [styles.transitionBackground]: isLoaded,
        [styles.leftSide]: modalAlignH === 'left',
        [styles.rightSide]: modalAlignH === 'right',
      })}
      onClick={(e) => {
        e.stopPropagation()
        delayedClose()
      }}
    >
      <div
        className={classNames(styles.modal, {
          [styles.transitionModal]: isLoaded,
          [styles.leftModal]: modalAlignH === 'left',
          [styles.rightModal]: modalAlignH === 'right',
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classNames(styles.header, { [styles.nonHeaderTitle]: !title })}>
          {title && <span className={styles.headerTitle}>{title}</span>}
          <InlineSVG className={styles.closeButton} src={closeIcon} onClick={delayedClose} />
        </div>
        {children}
      </div>
    </div>,
    container,
  )
}
