import { BehaviorSubject, catchError, filter, shareReplay, switchMap, take, tap, throwError } from 'rxjs'
import { ajax, AjaxConfig } from 'rxjs/ajax'
import { AuthTokenType } from 'src/type/auth.type'
import { ErrorCodeEnum, isReadyAiAjaxError } from 'src/type/error.type'

const apiUrl = `/api`
const authToken = { Authorization: '' }

function setAuthToken(token: string) {
  authToken.Authorization = `Bearer ${token}`
}

function clearAuthToken() {
  authToken.Authorization = ''
}

let isRefreshing = false
const refreshTokenSubject = new BehaviorSubject('')

function createRequestWithToken<T>(config: AjaxConfig, method: string) {
  return ajax<T>({
    ...config,
    headers: {
      ...config.headers,
      Authorization: authToken.Authorization,
    },
    url: `${apiUrl}${config.url}`,
    queryParams: config.queryParams,
    body: config.body,
    method,
  })
}

function handleExpiredToken<T>(config: AjaxConfig, method: string) {
  if (!isRefreshing) {
    isRefreshing = true
    refreshTokenSubject.next('')

    return ajax<AuthTokenType>({
      headers: {
        ...config.headers,
        Authorization: authToken.Authorization,
      },
      url: `${apiUrl}/token`,
      method: 'POST',
    }).pipe(
      shareReplay(1),
      tap(({ response }) => {
        setAuthToken(response.accessToken)
        refreshTokenSubject.next(response.accessToken)
        isRefreshing = false
      }),
      switchMap(() => createRequestWithToken<T>(config, method)),
      catchError((error) => {
        refreshTokenSubject.error(error)
        return throwError(() => new Error(error))
      }),
    )
  } else {
    return refreshTokenSubject.pipe(
      filter((token) => token != ''),
      take(1),
      switchMap(() => createRequestWithToken<T>(config, method)),
    )
  }
}

function createRequest<T>(config: AjaxConfig, method: string) {
  const originalRequest = createRequestWithToken<T>(config, method)

  return originalRequest.pipe(
    catchError((err) => {
      if (isReadyAiAjaxError(err) && err.response?.code === ErrorCodeEnum.BAU1003) {
        return handleExpiredToken<T>(config, method)
      }
      return throwError(() => err)
    }),
  )
}

function GET<T>(config: AjaxConfig) {
  return createRequest<T>(config, 'GET')
}

function POST<T>(config: AjaxConfig) {
  return createRequest<T>(config, 'POST')
}

function PUT<T>(config: AjaxConfig) {
  return createRequest<T>(config, 'PUT')
}

function DELETE<T>(config: AjaxConfig) {
  return createRequest<T>(config, 'DELETE')
}

export const httpApi = {
  setAuthToken,
  clearAuthToken,
  get: GET,
  post: POST,
  put: PUT,
  delete: DELETE,
}
