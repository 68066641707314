import React, { useMemo } from 'react'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { blueprintIntl } from 'src/intl/data/blueprint.key'
import { BlueprintType, MappingInputType, MappedOptionValueType } from 'src/type/blueprint.type'
import { OptionType } from 'src/type/common.type'
import { DatasetType } from 'src/type/dataset.type'
import { ProjectTypesEnum } from 'src/type/project.type'
import styles from './select-row.scss'

type PropsType = {
  projectType: ProjectTypesEnum
  blueprint: BlueprintType
  dataset: DatasetType
  mappedOptionValue?: MappedOptionValueType
  mappedField?: { [fieldId: number]: number }
  isDropdownUpside?: boolean
  onChange?(mappedKeys: { [blueprintId: number]: MappingInputType }): void
}

export const SelectRow = ({
  projectType,
  blueprint,
  dataset,
  mappedOptionValue,
  mappedField,
  isDropdownUpside,
  onChange,
}: PropsType) => {
  const fieldOptions = useMemo(
    () =>
      dataset.fields
        ? dataset.fields
            .filter(
              (field) =>
                !mappedField || (mappedField && !mappedField[field.id]) || mappedField[field.id] === blueprint.id,
            )
            .map((field) => ({ label: field.name, value: field.id }))
        : [],
    [dataset, mappedField],
  )

  function handleChangeMapping(option?: OptionType) {
    onChange &&
      onChange({
        [blueprint.id]: {
          bluePrintId: blueprint.id,
          fieldIds: [{ id: option?.value }],
        },
      })
  }

  return (
    <ul className={styles.gridBodyRow}>
      <li className={styles.blueprintCell}>
        {blueprintIntl[projectType][blueprint.name].name}
        {blueprint.isRequired && <span className={styles.required}>*</span>}
      </li>
      <li className={styles.inputCell}>
        <DropdownSingleSelector
          placeholder={`マッピングされていません。`}
          sizeType='lg'
          isNeededFilter
          selectedVisible
          selectedOption={mappedOptionValue?.selectedFieldOptions[0]}
          options={fieldOptions}
          isOpenUpside={isDropdownUpside}
          isDisselectable
          onChange={handleChangeMapping}
        />
      </li>
      <li className={styles.descCell}>{blueprintIntl[projectType][blueprint.name].description}</li>
    </ul>
  )
}
