import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { ProgressBar } from 'src/component/atom/progress-bar/progress-bar'
import iconCircleCheck from 'src/style/icon/icon-circle-check.svg'
import iconCircleX from 'src/style/icon/icon-circle-x.svg'
import iconTrash from 'src/style/icon/icon-trash.svg'
import imageCsv from 'src/style/image/image-csv.svg'
import imageFile from 'src/style/image/image-file.svg'
import { toastTool } from 'src/tool/toast.tool'
import { TaskProgressType } from 'src/type/task.type'
import { util } from 'src/util/common.util'
import styles from './file-uploader.scss'

const acceptFileExtension = ['.csv', '.tsv', '.xlsx', '.xls', '.sas7bdat', '.zip']

type PropsType = {
  fileName?: string
  fileSize?: number
  fileSizeLimitGB?: number
  uploadProgress?: TaskProgressType
  layout?: 'vertical' | 'horizonal'
  onChange?(file: File | undefined): void
  onDeleteButtonClick?(): void
}

export const FileUploader = ({
  fileName,
  fileSize,
  fileSizeLimitGB = 0,
  uploadProgress,
  layout = 'vertical',
  onChange,
  onDeleteButtonClick,
}: PropsType) => {
  const FILE_LIMIT_SIZE = fileSizeLimitGB * 1024 * 1024 * 1024 // MB unit

  const [file, setFile] = React.useState<File | undefined>()
  const [drag, setDrag] = React.useState<boolean>(false)

  const totalProgress = useMemo(() => {
    return util.datasetCreateTaskProgress(uploadProgress)
  }, [uploadProgress])

  useEffect(() => {
    if (totalProgress > 0) {
      setFile(undefined)
    }
  }, [totalProgress])

  function selectFile(files: FileList) {
    const fileExtention = '.' + files[0].name.split('.').pop()?.toLowerCase()

    if (!acceptFileExtension.includes(fileExtention)) {
      toastTool.errorString('Wrong file extextion!')
    } else if (FILE_LIMIT_SIZE !== 0 && files[0].size > FILE_LIMIT_SIZE) {
      toastTool.errorString('FILE SIZE OVER!!')
    } else {
      setFile(files[0])
      onChange && onChange(files[0])
    }
  }

  function handleDeleteClick() {
    onDeleteButtonClick && onDeleteButtonClick()
    setFile(undefined)
  }

  function handleChangeUploadFile(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.currentTarget.files
    if (files) {
      selectFile(files)
    }
  }

  function handleDropFile(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      selectFile(e.dataTransfer.files)
      e.dataTransfer.clearData()
    }
    setDrag(false)
  }

  function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation()
    setDrag(true)
  }

  function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation()
    setDrag(false)
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
  }

  return (
    <div
      className={classNames(styles.root, {
        [styles.isFile]: file || fileName,
        [styles.horizonalLayout]: layout === 'horizonal',
      })}
      onDragEnter={handleDragEnter}
    >
      {fileName ? (
        <div className={styles.fileWrapper}>
          <div className={styles.uploadedFile}>
            <InlineSVG className={styles.fileIcon} src={imageCsv} />
            <div className={styles.fileInfo}>
              <div className={styles.fileName}>{fileName}</div>
              <div className={styles.fileSize}>{util.formattingDataSize(fileSize || 0)}</div>
            </div>
            {totalProgress > 0 && totalProgress < 100 ? (
              <InlineSVG className={styles.cancelIcon} src={iconCircleX} />
            ) : (
              <>
                <InlineSVG className={styles.checkedIcon} src={iconCircleCheck} />
                <InlineSVG className={styles.trashIcon} src={iconTrash} onClick={handleDeleteClick} />
              </>
            )}
          </div>
          {totalProgress > 0 && totalProgress < 100 && (
            <ProgressBar progress={totalProgress} progressColor='secondary' sizeType='sm' isRounded />
          )}
        </div>
      ) : file ? (
        <div className={styles.fileWrapper}>
          <div className={styles.uploadedFile}>
            <InlineSVG className={styles.fileIcon} src={imageCsv} />
            <div className={styles.fileInfo}>
              <div className={styles.fileName}>{file.name}</div>
              <div className={styles.fileSize}>{util.formattingDataSize(file.size || 0)}</div>
            </div>
            <InlineSVG className={styles.cancelIcon} src={iconCircleX} />
          </div>
        </div>
      ) : (
        <>
          <InlineSVG src={imageFile} />
          <label className={styles.fileSelectButton}>
            <input
              type='file'
              accept={acceptFileExtension.join(', ')}
              multiple={false}
              onChange={handleChangeUploadFile}
            />
            {`ファイルを選択`}
          </label>
        </>
      )}
      <div
        className={classNames(styles.onDrag, { [styles.visible]: !file && !fileName && drag })}
        onDragLeave={handleDragLeave}
        onDrop={handleDropFile}
        onDragOver={handleDragOver}
      />
    </div>
  )
}
