import { combineReducers } from 'redux'
import { authReducer } from './auth/auth.action'
import { blueprintReducer } from './blueprint/blueprint.action'
import { branchReducer } from './branch/branch.action'
import { dashboardReducer } from './dashboard/dashboard.action'
import { datasetReducer } from './dataset/dataset.action'
import { notificationReducer } from './notification/notification.action'
import { projectReducer } from './project/project.action'
import { softwareInfoReducer } from './software-info/software-info.action'
import { taskReducer } from './task/task.action'
import { userReducer } from './user/user.action'

export const reducer = combineReducers({
  taskState: taskReducer,
  projectState: projectReducer,
  datasetState: datasetReducer,
  blueprintState: blueprintReducer,
  notificationState: notificationReducer,
  authState: authReducer,
  softwareInfoState: softwareInfoReducer,
  userState: userReducer,
  branchState: branchReducer,
  dashboardState: dashboardReducer,
})

export type RootStateType = ReturnType<typeof reducer>
