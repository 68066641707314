import { ResultColumnNameIntl } from 'src/intl/data/result.key'
import { FieldTypesEnum } from 'src/type/dataset.type'
import { ResultSegmentFieldsType } from 'src/type/project.type'

function segmentComparisonIntl(comparisonString: string) {
  switch (comparisonString) {
    case 'lt':
      return '未満'
    case 'lte':
      return '以下'
    case 'eq':
      return ''
    case 'gte':
      return '以上'
    case 'gt':
      return 'より大きい'
    default:
      return ''
  }
}

function fullSegmentExplainIntl(segmentFields: ResultSegmentFieldsType[]) {
  return segmentFields.map((field) => {
    const fieldNameIntl = (ResultColumnNameIntl[field.name] ?? field.name) + 'が'
    const conditionIntls = field.conditions
      ?.map((condition) => {
        if (field.type === FieldTypesEnum.CATEGORY) {
          return condition.param
        }

        return !['none', 'and', 'or'].includes(condition.op) && condition.param
          ? `${(+condition.param || 0).toFixed(2)}${segmentComparisonIntl(condition.op)}`
          : ''
      })
      .filter((intlItem) => !!intlItem)

    return `${fieldNameIntl}${conditionIntls?.join()}`
  })
}

export const intlUtil = {
  fullSegmentExplainIntl,
}
