import { createSelector } from '@reduxjs/toolkit'
import { RootStateType } from 'src/redux/root.reducer'
import { ReduxStatusEnum } from 'src/type/common.type'

const projectState = (state: RootStateType) => state.projectState
const status = createSelector(projectState, (state) =>
  state.pendingCount > 0 ? ReduxStatusEnum.PENDING : ReduxStatusEnum.IDLE,
)
const isProjects = createSelector(projectState, (state) => !!state.projectRecords)
const projectRecords = createSelector(projectState, (state) => state.projectRecords ?? {})
const projectIds = createSelector(projectState, (state) => state.projectIds ?? [])
const projects = createSelector(projectIds, projectRecords, (ids, records) => {
  return ids.map((id) => records[id])
})
const projectById = (projectId: number) => createSelector(projectRecords, (state) => state[projectId])
const selectedProjectId = createSelector(projectState, (state) => state.selectedProjectId)
const selectedProject = createSelector(projectRecords, selectedProjectId, (projects, projectId) =>
  projectId ? projects[projectId] : null,
)

const selectedProjectDetail = createSelector(projectState, (state) => state.selectedProjectDetail)
const selectedProjectGoal = createSelector(projectState, (state) => state.selectedProjectGoal)

const selectedProjectSettingValues = createSelector(selectedProjectDetail, (state) =>
  state
    ? state.projectSettings.reduce<{ [goalId: number]: string | number }>((res, setting) => {
        res[setting.projectGoalId] = setting.value
        return res
      }, {})
    : {},
)

const selectedProjectStep = createSelector(projectState, (state) => state.selectedProjectStep)

const selectedProjectTargetsColumns = createSelector(projectState, (state) => state.selectedProjectTargetsColumns || [])
const selectedProjectResultTargets = createSelector(projectState, (state) => state.selectedProjectResultTargets || [])
const selectedProjectResultTargetLength = createSelector(
  projectState,
  (state) => state.selectedProjectResultTargetLength || 0,
)
const selectedProjectResultTargetsPrev = createSelector(
  projectState,
  (state) => state.selectedProjectResultTargetsPrev || [],
)
const selectedProjectResultSegments = createSelector(projectState, (state) => state.selectedProjectResultSegments || [])

export const projectSelector = {
  status,
  isProjects,
  projects,
  projectRecords,
  projectById,
  selectedProjectId,
  selectedProject,
  selectedProjectDetail,
  selectedProjectGoal,
  selectedProjectSettingValues,
  selectedProjectStep,
  selectedProjectTargetsColumns,
  selectedProjectResultTargets,
  selectedProjectResultTargetLength,
  selectedProjectResultTargetsPrev,
  selectedProjectResultSegments,
}
