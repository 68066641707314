import { createAction, createReducer } from '@reduxjs/toolkit'
import { BranchRecordByCodeType, BranchRecordType, BranchType } from 'src/type/branch.type'
import { createAsyncAction } from '../redux.type'
import {
  CreateBranchParamsType,
  CreateBranchesParamsType,
  DeleteBranchParamsType,
  EditBranchParamsType,
} from './branch.service'

type BranchStateType = {
  pendingCount: number
  asyncSuccess: boolean
  branchRecords: BranchRecordType | null
  branchRecordsByCode: BranchRecordByCodeType | null
  branchIds: number[] | null
}

const increasePendingCount = createAction('[branch] increase async action pending count')
const decreasePendingCount = createAction('[branch] dencrease async action pending count')
const setAsyncSuccess = createAction<boolean>('[user] set async success')

const fetchBranches = createAsyncAction('[branch] fetch branches')
const setBranches = createAction<BranchType[]>('[branch] set branches')

const createBranches = createAsyncAction<CreateBranchesParamsType>('[branch] create branches with file')
const createBranch = createAsyncAction<CreateBranchParamsType>('[branch] create branch')
const editBranch = createAsyncAction<EditBranchParamsType>('[branch] edit branch')
const setBranch = createAction<BranchType>('[branch] set branch')

const deleteBranches = createAsyncAction<DeleteBranchParamsType>('[branch] delete branches')

const downloadBranchCodeTemplate = createAsyncAction('[branch] create and download branches template')

export const branchAction = {
  increasePendingCount,
  decreasePendingCount,
  setAsyncSuccess,
  fetchBranches,
  setBranches,
  createBranches,
  createBranch,
  editBranch,
  setBranch,
  deleteBranches,
  downloadBranchCodeTemplate,
}

const initBranchState: BranchStateType = {
  pendingCount: 0,
  branchRecords: null,
  branchRecordsByCode: null,
  branchIds: null,
  asyncSuccess: false,
}

export const branchReducer = createReducer(initBranchState, (builder) =>
  builder
    .addCase(increasePendingCount, (state) => {
      state.pendingCount++
    })
    .addCase(decreasePendingCount, (state) => {
      state.pendingCount--
    })
    .addCase(setAsyncSuccess, (state, { payload }) => {
      state.asyncSuccess = payload
    })
    .addCase(setBranches, (state, { payload }) => {
      state.branchRecords = payload.reduce<BranchRecordType>((res, branch) => {
        res[branch.id] = branch
        return res
      }, {})

      state.branchRecordsByCode = payload.reduce<BranchRecordByCodeType>((res, branch) => {
        res[branch.branchCode] = branch
        return res
      }, {})

      state.branchIds = payload.map((data) => data.id)
    })
    .addCase(setBranch, (state, { payload }) => {
      if (!state.branchRecords) {
        state.branchRecords = {}
      }

      if (!state.branchRecordsByCode) {
        state.branchRecordsByCode = {}
      }

      if (!state.branchIds) {
        state.branchIds = [payload.id]
      } else if (!state.branchRecords[payload.id]) {
        state.branchIds.push(payload.id)
      }

      state.branchRecords[payload.id] = payload
    }),
)
