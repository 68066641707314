import React, { useState } from 'react'
import { Button } from 'src/component/atom/button/button'
import { ChangeNicknameModal } from 'src/modal/change-nickname/change-nickname.modal'
import { ChangePasswordModal } from 'src/modal/change-password/change-password.modal'
import { UserType } from 'src/type/user.type'
import styles from './account.scss'

type PropsType = {
  user: UserType
}

export const AccountPanel = ({ user }: PropsType) => {
  const [nicknameModalVisible, setNicknameModalVisible] = useState(false)
  const [passwordModalVisible, setPasswordModalVisible] = useState(false)

  function handleClickEditNickname() {
    setNicknameModalVisible(true)
  }

  function handleClickEditPassword() {
    setPasswordModalVisible(true)
  }

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <div className={styles.title}>{`アイコン`}</div>
        <div className={styles.profile}>{user.username.slice(0, 2)}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.title}>{`ユーザー名`}</div>
        <div className={styles.userValue}>{user.username}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.title}>{`表示名`}</div>
        <div className={styles.userValue}>{user.userNickname}</div>
        <Button sizeType='md' isRounded outlined onClick={handleClickEditNickname}>
          <span className={styles.buttonText}>{`変更`}</span>
        </Button>
      </div>
      <div className={styles.item}>
        <div className={styles.title}>{`現在のパスワード`}</div>
        <div className={styles.passwordTextArea}>
          <div className={styles.passwordItem} />
          <div className={styles.passwordItem} />
          <div className={styles.passwordItem} />
          <div className={styles.passwordItem} />
          <div className={styles.passwordItem} />
          <div className={styles.passwordItem} />
          <div className={styles.passwordItem} />
          <div className={styles.passwordItem} />
          <div className={styles.passwordItem} />
        </div>
        <Button sizeType='md' isRounded outlined onClick={handleClickEditPassword}>
          <span className={styles.buttonText}>{`変更`}</span>
        </Button>
      </div>
      <ChangeNicknameModal
        user={user}
        modalVisible={nicknameModalVisible}
        onClose={() => setNicknameModalVisible(false)}
      />
      <ChangePasswordModal
        user={user}
        modalVisible={passwordModalVisible}
        onClose={() => setPasswordModalVisible(false)}
      />
    </div>
  )
}
