import classNames from 'classnames'
import React, { useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { NavLink } from 'react-router-dom'
import styles from './nav-item.scss'

type PropsType = {
  navigationPath: string
  svgIconSrc: string
  label: string
  svgIconWidth?: number
  hoverSvgIconSrc?: string
  disabledClick?: boolean
}

export const NavItem = ({
  navigationPath,
  svgIconSrc,
  label,
  svgIconWidth,
  hoverSvgIconSrc,
  disabledClick,
}: PropsType) => {
  const [isHover, setIsHover] = useState(false)

  function handleNavLinkClick(e: React.MouseEvent) {
    disabledClick && e.preventDefault()
  }

  function handleMouseEnter() {
    hoverSvgIconSrc && setIsHover(true)
  }

  function handleMouseLeave() {
    hoverSvgIconSrc && setIsHover(false)
  }

  return (
    <NavLink
      className={({ isActive }) =>
        classNames(styles.root, {
          [styles.hover]: !disabledClick,
          [styles.isDisabledClick]: disabledClick,
          [styles.isActive]: isActive,
        })
      }
      to={navigationPath}
      onClick={handleNavLinkClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.icon}>
        {hoverSvgIconSrc && (
          <InlineSVG
            className={classNames({ [styles.noDisplay]: !isHover })}
            src={hoverSvgIconSrc}
            width={svgIconWidth}
          />
        )}
        <InlineSVG className={classNames({ [styles.noDisplay]: isHover })} src={svgIconSrc} width={svgIconWidth} />
      </div>
      <div>{label}</div>
    </NavLink>
  )
}
