import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import React from 'react'
import styles from './chart-bar-monthly.scss'

type PropsType = {
  title?: string
  subTitle?: string
  chartHight?: number
  yAxisTitle?: string
  xAxisTitle?: string
  yUnit?: number
  valueDecimals?: number
  minYvalue?: number
  xTickValues?: string[]
  series?: { name: string; data: number[] }
}

export const ChartBarMonthly = ({
  title,
  subTitle,
  chartHight,
  xAxisTitle,
  yAxisTitle,
  yUnit = 1,
  valueDecimals,
  minYvalue,
  xTickValues,
  series,
}: PropsType) => {
  HighchartsNoDataToDisplay(Highcharts)

  const maxVal = series
    ? Math.max(
        ...series.data.map((v) =>
          Number(
            new Intl.NumberFormat('ko-KR', {
              useGrouping: false,
              maximumFractionDigits: valueDecimals,
            }).format(Number(v / yUnit)),
          ),
        ),
      )
    : 0

  const seriesMin = series
    ? Math.min(
        ...series.data.map((v) =>
          Number(
            new Intl.NumberFormat('ko-KR', {
              useGrouping: false,
              maximumFractionDigits: valueDecimals,
            }).format(Number(v / yUnit)),
          ),
        ),
      )
    : Infinity
  const minVal = minYvalue !== undefined && seriesMin < minYvalue ? minYvalue : seriesMin

  const xTickValuesLength = xTickValues?.length || 0
  const xTickPositions =
    xTickValuesLength === 0
      ? []
      : Array.from(
          { length: Math.ceil(xTickValuesLength / 3) },
          (_, index) => xTickValuesLength - 1 - index * 3,
        ).reverse()

  const chartTitle: Highcharts.TitleOptions = {
    text: title,
    align: 'left',
    margin: subTitle ? 40 : 55,
    style: {
      fontSize: '16px',
      fontWeight: '600',
    },
  }

  const chartYAxis: Highcharts.YAxisOptions = {
    min: minYvalue,
    minRange: 2,
    title: {
      text: yAxisTitle,
    },
    labels: {
      formatter() {
        return new Intl.NumberFormat('ko-KR', {
          maximumFractionDigits: valueDecimals,
        }).format(Number(this.value))
      },
    },
  }

  const chartXAxis: Highcharts.XAxisOptions = {
    categories: xTickValues,
    tickmarkPlacement: 'on',
    tickLength: 10,
    tickWidth: 1,
    gridLineWidth: 1,
    title: {
      text: xAxisTitle,
    },
    labels: {
      rotation: 0,
      style: {
        textOverflow: 'clip',
      },
      formatter() {
        if (xTickPositions.includes(this.pos)) {
          return this.value.toString()
        }
        return ''
      },
    },
  }

  const dataSeries: Highcharts.SeriesOptionsType[] = [
    {
      type: 'column',
      name: series?.name,
      data: series
        ? series.data.map((v) => {
            const vValue = Number(
              new Intl.NumberFormat('ko-KR', {
                useGrouping: false,
                maximumFractionDigits: valueDecimals,
              }).format(
                minYvalue === undefined || minYvalue >= 0
                  ? minYvalue !== undefined && minYvalue >= v
                    ? minYvalue
                    : v / yUnit
                  : v / yUnit,
              ),
            )

            return {
              y: vValue,
              color: vValue === maxVal ? '#8748AF' : vValue === minVal ? '#5EC3EF' : '#9C9C9C',
            }
          })
        : [],
      dataLabels: {
        enabled: true,
        formatter() {
          if (this.y === maxVal || this.y === minVal || (series && this.point.index === series.data.length - 1)) {
            return new Intl.NumberFormat('ko-KR', {
              maximumFractionDigits: valueDecimals,
            }).format(Number(this.y))
          }
          return null
        },
      },
    },
  ]

  const options: Highcharts.Options = {
    credits: { enabled: false },
    accessibility: { enabled: false },
    lang: {
      noData: '該当するデータが存在しません。',
    },
    chart: {
      type: 'column',
      height: chartHight,
      plotShadow: false,
      style: {
        fontFamily: 'Roboto, NotoSansJP',
      },
    },
    title: chartTitle,
    subtitle: {
      text: subTitle || ' ',
      align: 'left',
    },
    yAxis: chartYAxis,
    xAxis: chartXAxis,
    tooltip: {
      formatter() {
        return (
          '<span style="font-size: 11px">' +
          this.point.category +
          '</span><br/>' +
          '<span style="color:' +
          this.point.color +
          '">\u25CF</span> ' +
          this.series.name +
          ': <b>' +
          new Intl.NumberFormat('ko-KR', {
            maximumFractionDigits: valueDecimals,
          }).format(Number(this.point.y)) +
          '</b><br/>'
        )
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    series: dataSeries,
  }

  return (
    <div className={styles.root}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
