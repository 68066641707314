import { createSelector } from '@reduxjs/toolkit'
import { ReduxStatusEnum } from 'src/type/common.type'
import { RootStateType } from '../root.reducer'

const authState = (state: RootStateType) => state.authState
const status = createSelector(authState, (state) =>
  state.pendingCount > 0 ? ReduxStatusEnum.PENDING : ReduxStatusEnum.IDLE,
)
const isLoggedIn = createSelector(authState, (state) => !!state.loggedInUser)
const loggedInUser = createSelector(authState, (state) => state.loggedInUser)

export const authSelector = {
  status,
  isLoggedIn,
  loggedInUser,
}
