import { Action } from 'redux'
import { combineEpics, Epic } from 'redux-observable'
import { filter, switchMap, mergeMap, catchError, of, Observable, map } from 'rxjs'
import { httpErrorhandling } from '../redux.util'
import { blueprintAction } from './blueprint.action'
import { blueprintService } from './blueprint.service'

const pendingStartEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        blueprintAction.fetchBlueprint.request.type,
        blueprintAction.updateFieldMapping.request.type,
        blueprintAction.deleteFieldMapping.request.type,
      ].includes(action.type),
    ),
    map(() => blueprintAction.increasePendingCount()),
  )

const pendingEndEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        blueprintAction.fetchBlueprint.success.type,
        blueprintAction.fetchBlueprint.failure.type,
        blueprintAction.fetchBlueprint.cancelled.type,
        blueprintAction.updateFieldMapping.success.type,
        blueprintAction.updateFieldMapping.failure.type,
        blueprintAction.updateFieldMapping.cancelled.type,
        blueprintAction.deleteFieldMapping.success.type,
        blueprintAction.deleteFieldMapping.failure.type,
        blueprintAction.deleteFieldMapping.cancelled.type,
      ].includes(action.type),
    ),
    map(() => blueprintAction.decreasePendingCount()),
  )

const pendingFailureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) =>
      [
        blueprintAction.fetchBlueprint.failure.type,
        blueprintAction.updateFieldMapping.failure.type,
        blueprintAction.deleteFieldMapping.failure.type,
      ].includes(action.type),
    ),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const fetchBlueprintEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(blueprintAction.fetchBlueprint.request.match),
    switchMap(({ payload }) =>
      blueprintService.fetchBlueprint(payload).pipe(
        mergeMap(({ response }) => [blueprintAction.fetchBlueprint.success(), blueprintAction.setBlueprint(response)]),
        catchError((err) => of(blueprintAction.fetchBlueprint.failure(err))),
      ),
    ),
  )

const fetchBlueprintMappingtEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(blueprintAction.fetchBlueprintMapping.request.match),
    switchMap(({ payload }) =>
      blueprintService.fetchBlueprintMapping(payload).pipe(
        mergeMap(({ response }) => [
          blueprintAction.fetchBlueprintMapping.success(),
          blueprintAction.setBlueprintMapping(response),
        ]),
        catchError((err) => of(blueprintAction.fetchBlueprintMapping.failure(err))),
      ),
    ),
  )

const updateFieldMappingtEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(blueprintAction.updateFieldMapping.request.match),
    mergeMap(({ payload }) =>
      blueprintService.updateFieldMapping(payload).pipe(
        mergeMap(({ response }) => [
          blueprintAction.updateFieldMapping.success(),
          blueprintAction.fetchBlueprintMapping.request({ projectId: response.project.id }),
        ]),
        catchError((err) => of(blueprintAction.updateFieldMapping.failure(err))),
      ),
    ),
  )

const deleteFieldMappingtEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(blueprintAction.deleteFieldMapping.request.match),
    mergeMap(({ payload }) =>
      blueprintService.deleteFieldMapping(payload).pipe(
        mergeMap(() => [
          blueprintAction.deleteFieldMapping.success(),
          blueprintAction.fetchBlueprintMapping.request({ projectId: payload.projectId }),
        ]),
        catchError((err) => of(blueprintAction.deleteFieldMapping.failure(err))),
      ),
    ),
  )

export const blueprintEpic = combineEpics(
  pendingStartEpic,
  pendingEndEpic,
  pendingFailureEpic,
  fetchBlueprintMappingtEpic,
  fetchBlueprintEpic,
  updateFieldMappingtEpic,
  deleteFieldMappingtEpic,
)
