import { CommonIdType } from './common.type'
import { FieldTypesEnum } from './dataset.type'

export enum ProjectTypesEnum {
  CHURN_PREVENTION = 'CHURN_PREVENTION',
  CROSS_SELLING = 'CROSS_SELLING',
  UP_SELLING = 'UP_SELLING',
  CORP_LOAN_DEMAND = 'CORP_LOAN_DEMAND',
  CORP_SALES_INSIGHTS = 'CORP_SALES_INSIGHTS',
}

export enum ProjectGoalTypesEnum {
  VALUE = 'VALUE',
  SELECT = 'SELECT',
}

export enum ProjectStepEnum {
  NONE = 0,
  SETTING = 1,
  DATASET = 2,
  BLUE_PRINT,
  TRAINNING,
  RESULT,
}

export type ProjectType = {
  id: number
  name: string
  type: ProjectTypesEnum
  projectResult: ProjectResultType[]
  dataSets: CommonIdType[]
  user: CommonIdType
  createdAt: string
  updatedAt?: string
}

export type ProjectRecordsType = Record<number, ProjectType>

export type ProjectDetailType = ProjectType & {
  projectSettings: ProjectSettingType[]
  bluePrintMappings: {
    projectId: number
    bluePrintId: number
    value?: number
    values?: string[]
  }[]
  projectResult: ProjectResultType[] | null
}

export type ProjectResultType = {
  projectId: number
  modelId: string
  kpiRatio: number | null
  globalTargetRatio: number | null
  expectedEarn: number | null
  actualProfit: number | null
  resultSegments?: SegmentType[]
  resultTargets?: TargetType[]
  createdAt: string
  updatedAt?: string
}

export type SegmentType = {
  id: number
  average: number
  size: number
  projectResult?: CommonIdType
  resultSegmentFields: ResultSegmentFieldsType[]
}

export type ResultSegmentFieldsType = {
  name: string
  type: FieldTypesEnum
  conditions?: ConditionType[]
}

export type TargetColumnName = {
  name: string
}

export type TargetType = {
  primaryKey: string
  secondaryKey?: string
  score: number
  // common
  industry?: string
  subscriptionPeriod?: number
  isSalary?: number
  balance?: number
  // retail
  age?: number
  gender?: string
  isTargetProductCustomer?: string
  transactionsCount_viewPoint?: number
  transactionsAvg_viewPoint?: number
  deposit_transactionsCount_viewPoint?: number
  deposit_transactionsAvg_viewPoint?: number
  withdrawal_transactionsCount_viewPoint?: number
  withdrawal_transactionsAvg_viewPoint?: number
  // corporate
  corporationAge?: number
  deposit_transactionsTotal_viewPoint?: number
  withdrawal_transactionsTotal_viewPoint?: number
}

export type ResultTargetsType = {
  field: TargetColumnName[]
  target: TargetType[]
  resultTargetLength: number
}

export type OperatorType = 'lt' | 'lte' | 'eq' | 'gt' | 'gte' | 'and' | 'or' | 'none'

export type ConditionType = {
  op: OperatorType
  param?: string | number
}

export type ProjectSettingType = {
  projectId: number
  projectGoalId: number
  value: number
  createdAt: string
  updatedAt: string
}

export type ProjectGoalType = {
  id: number
  createdAt: string
  updatedAt: string
  name: string
  type: ProjectGoalTypesEnum
  projectType: ProjectTypesEnum
  isRequired: boolean
  children: number[]
  parent: number | null
  options: GoalOptionsType
  sortOrder?: number
}

export type GoalOptionsType = {
  default?: number
  min?: number
  max?: number
  selectableValues?: (number | string)[]
}

export type FilterContentType = FilterNumberType | FilterCategoryType

export type FilterNumberType = {
  name: keyof TargetType
  type: FieldTypesEnum.NUMBER
  values?: number[][]
}

export type FilterCategoryType = {
  name: keyof TargetType
  type: FieldTypesEnum.CATEGORY
  values?: string[]
}

export type FilterFieldType = FilterFieldCategoryType | FilterFieldNumberType

export type FilterFieldCategoryType = {
  type: FieldTypesEnum.CATEGORY
  name: keyof TargetType
  option: string[]
}

export type FilterFieldNumberType = {
  type: FieldTypesEnum.NUMBER
  name: keyof TargetType
  option: number[]
}
