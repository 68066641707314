import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { projectAction } from 'src/redux/project/project.action'
import { projectSelector } from 'src/redux/project/project.state'
import { ReduxStatusEnum } from 'src/type/common.type'
import styles from './delete-project.scss'

type PropsType = {
  targetProjectIds: number[]
  onClose(): void
}

export const DeleteProjectModal = withModalWrapper(({ targetProjectIds, onClose }: PropsType) => {
  const dispatch = useDispatch()
  const projectStatus = useSelector(projectSelector.status)
  const [closeClick, setCloseClick] = useState(false)

  useEffect(() => {
    if (closeClick === true && projectStatus === ReduxStatusEnum.IDLE) {
      onClose()
    }
  }, [projectStatus])

  function handleDeleteClick() {
    setCloseClick(true)
    dispatch(projectAction.deleteProject.request({ ids: targetProjectIds }))
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>{`選択されたプロジェクトを削除しますか？`}</div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleDeleteClick}>
          {`削除`}
        </Button>
      </div>
    </div>
  )
}, 'プロジェクト削除')
