import Cookies from 'js-cookie'
import React, { useEffect, useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { Checkbox } from 'src/component/atom/checkbox/checkbox'
import { Input } from 'src/component/atom/input/input'
import { Lottie } from 'src/component/atom/lottie/lottie'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { ChangePasswordMailModal } from 'src/modal/change-password-mail/change-password-mail.modal'
import { SignUpSuccessModal } from 'src/modal/sign-up-success/sign-up-success.modal'
import { SignUpModal } from 'src/modal/sign-up/sign-up.modal'
import { authAction } from 'src/redux/auth/auth.action'
import { userSelector } from 'src/redux/user/user.state'
import LogoAilys from 'src/style/logo/logo-ailys.svg'
import LogoLabel from 'src/style/logo/logo-label.svg'
import LoginBgData from 'src/style/lottie/login-bg.json'
import { ReduxStatusEnum } from 'src/type/common.type'
import styles from './login.scss'
import { InputPassword } from 'src/component/molecule/input-password/input-password'

export const LoginPage = () => {
  const dispatch = useDispatch()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [successModalVisible, setSuccessModalVisible] = useState(false)

  const userStatus = useSelector(userSelector.status)
  const requestedSignUpUsername = useSelector(userSelector.requestedSignUpUsername)

  const fullYear = useMemo(() => new Date().getFullYear(), [])

  useEffect(() => {
    const rememberMeCookie = Cookies.get('rememberMe')

    if (rememberMeCookie) {
      setUsername(rememberMeCookie as string)
      setRememberMe(true)
    }
  }, [])

  useEffect(() => {
    if (userStatus === ReduxStatusEnum.IDLE && requestedSignUpUsername) {
      setSuccessModalVisible(true)
    }
  }, [requestedSignUpUsername])

  function handleLoginClick() {
    dispatch(authAction.login.request({ username, password, rememberMe }))
  }

  function handleInputEnterKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (username && password) {
      if (e.key === 'Enter') {
        handleLoginClick()
      }
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.inputArea}>
        <div className={styles.inputHeader}>
          <InlineSVG src={LogoLabel} />
          <span className={styles.welcomeLabel}>Welcome Back</span>
        </div>
        <div className={styles.inputForm}>
          <span className={styles.inputLabel}>{`表示言語`}</span>
          <DropdownSingleSelector
            sizeType='lg'
            selectedOption={{ label: '日本語', value: 'JP' }}
            options={[{ label: '日本語', value: 'JP' }]}
          />
          <span className={styles.inputLabel}>{`ユーザー名`}</span>
          <Input
            sizeType='lg'
            value={username}
            onChange={(e) => setUsername(e.currentTarget.value)}
            onKeyDown={handleInputEnterKeyDown}
          />
          <span className={styles.inputLabel}>{`パスワード`}</span>
          <InputPassword
            sizeType='lg'
            onChange={(e) => setPassword(e.currentTarget.value)}
            onKeyDown={handleInputEnterKeyDown}
          />
          <div className={styles.infoControlArea}>
            <label className={styles.rememberMeCheckbox}>
              <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.currentTarget.checked)} />
              <span className={styles.checkLabel}>{`Remember me?`}</span>
            </label>
            <a className={styles.link} onClick={() => setIsModalVisible(true)}>{`パスワードをお忘れの場合`}</a>
          </div>
          <Button buttonColor='secondary' sizeType='lg' onClick={handleLoginClick} disabled={!(username && password)}>
            SIGN IN
          </Button>
          <div className={styles.createArea}>
            <span>Don’t have an account?</span>
            <a className={styles.link} onClick={() => setIsSignUpModalVisible(true)}>
              Create an account
            </a>
          </div>
        </div>
        <div className={styles.inputFooter}>
          <InlineSVG src={LogoAilys} />
          <span>{fullYear} Ailys Inc.</span>
          <span>All Rights Reserved</span>
        </div>
      </div>
      <div className={styles.imageArea}>
        <Lottie animationData={LoginBgData} className={styles.lottieSvg} />
      </div>
      <SignUpModal modalVisible={isSignUpModalVisible} onClose={() => setIsSignUpModalVisible(false)} />
      <ChangePasswordMailModal modalVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
      {requestedSignUpUsername && (
        <SignUpSuccessModal
          modalVisible={successModalVisible}
          signedUpUsername={requestedSignUpUsername}
          onClose={() => setSuccessModalVisible(false)}
        />
      )}
    </div>
  )
}
