import { createAction, createReducer } from '@reduxjs/toolkit'
import { AlertLogDictType, AlertLogType } from 'src/type/notification.type'
import { createAsyncAction } from '../redux.type'

type NotificationStateType = {
  alertLogObjs: AlertLogDictType | null
  alertLogIds: number[] | null
}

const fetchAlertLogs = createAsyncAction('[notification] fetch alert logs')
const setAlertLogs = createAction<AlertLogType[]>('[notification] set alert logs')

const updateAlertLog = createAsyncAction('[notification] update alert log')

export const notificationAction = {
  fetchAlertLogs,
  setAlertLogs,
  updateAlertLog,
}

const initNotificationState: NotificationStateType = {
  alertLogObjs: null,
  alertLogIds: null,
}

export const notificationReducer = createReducer(initNotificationState, (builder) =>
  builder.addCase(setAlertLogs, (state, { payload }) => {
    state.alertLogObjs = payload.reduce<AlertLogDictType>((res, dataset) => {
      res[dataset.id] = dataset
      return res
    }, {})

    state.alertLogIds = payload.map((data) => data.id)
  }),
)
