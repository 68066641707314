import { createSelector } from '@reduxjs/toolkit'
import { RootStateType } from '../root.reducer'

const notificationState = (state: RootStateType) => state.notificationState
const alertLogsRecords = createSelector(notificationState, (state) => state.alertLogObjs ?? {})
const alertLogsIds = createSelector(notificationState, (state) => state.alertLogIds ?? [])
const alertLogs = createSelector(alertLogsIds, alertLogsRecords, (ids, records) => {
  return ids.map((id) => records[id]).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
})
const alertLogById = (logId: number) => createSelector(alertLogsRecords, (state) => state[logId])
const notReadedLogIds = createSelector(alertLogs, (state) => state.filter((log) => !log.userCheck).map((log) => log.id))

export const notificationSelector = {
  alertLogsRecords,
  alertLogsIds,
  alertLogs,
  alertLogById,
  notReadedLogIds,
}
