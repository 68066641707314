import { DatasetTypesEnum, DatasetType } from 'src/type/dataset.type'
import { httpApi } from 'src/util/http.module'

export type fetchDatasetsParamsType = {
  projectId: number
}
const fetchDatasets = (params: fetchDatasetsParamsType) =>
  httpApi.get<DatasetType[]>({ url: '/dataset', queryParams: params })

export type fetchDatasetParamsType = {
  datasetId: number
}
const fetchDataset = (params: fetchDatasetParamsType) =>
  httpApi.get<DatasetType>({ url: `/dataset/${params.datasetId}` })

export type CreateDatasetParamsType = {
  file: File
  type: DatasetTypesEnum
  projectId: number
}
const createDataset = (params: CreateDatasetParamsType) => {
  const formData = new FormData()
  formData.append('file', params.file)
  formData.append('type', params.type)
  formData.append('projectId', `${params.projectId}`)

  return httpApi.post<DatasetType>({
    url: '/dataset',
    body: formData,
  })
}

export type DeleteDatasetParamsType = {
  id: number
}
const deleteDataset = (params: DeleteDatasetParamsType) => httpApi.delete({ url: `/dataset/${params.id}` })

export const datasetService = {
  fetchDatasets,
  fetchDataset,
  createDataset,
  deleteDataset,
}
