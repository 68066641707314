import React, { memo } from 'react'
import { SideModalPage } from 'src/component/organism/side-modal-page/side-modal-page'

type ModalWrapperPropsType = {
  onClose(): void
  modalVisible: boolean
  modalAlignH?: 'left' | 'right'
}

export const withSideModalPageWrapper = <T,>(Component: React.ComponentType<T>, title?: string) => {
  const WrappedComp = (props: T & ModalWrapperPropsType) => (
    <>
      {props.modalVisible && (
        <SideModalPage title={title} modalAlignH={props.modalAlignH} onClose={props.onClose}>
          <Component {...props} />
        </SideModalPage>
      )}
    </>
  )

  return memo(WrappedComp, (prevProps, nextProps) => prevProps.modalVisible === nextProps.modalVisible)
}
