import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from 'src/component/organism/header/header'
import { branchSelector } from 'src/redux/branch/branch.state'
import { notificationAction } from 'src/redux/notification/notification.action'
import { notificationSelector } from 'src/redux/notification/notification.state'
import { userSelector } from 'src/redux/user/user.state'
import { OptionType } from 'src/type/common.type'
import { AccountPanel } from './account/account.panel'
import { BranchPanel } from './branch/branch.panel'
import styles from './setting.scss'

const panelOptions: OptionType<'branch' | 'account'>[] = [
  { label: 'Branch', value: 'branch' },
  { label: 'Account', value: 'account' },
]

export const SettingPage = () => {
  const dispatch = useDispatch()

  const user = useSelector(userSelector.user)
  const branches = useSelector(branchSelector.branches)
  const alertLogs = useSelector(notificationSelector.alertLogs)
  const notReadedLogIds = useSelector(notificationSelector.notReadedLogIds)

  const branchesNonAdmin = useMemo(() => {
    return branches.filter((b) => b.branchCode !== 'admin')
  }, [branches])

  const [selecedPanel, setSelectedPanel] = useState<OptionType<'branch' | 'account'>>({
    label: 'Account',
    value: 'account',
  })

  function handleOpenNotification() {
    if (notReadedLogIds.length > 0) {
      dispatch(notificationAction.updateAlertLog.request())
    }
  }

  return (
    <div className={styles.root}>
      {user && (
        <Header user={user} alertLogs={alertLogs} onOpenNotification={handleOpenNotification}>
          <div className={styles.headerText}>{`Setting`}</div>
        </Header>
      )}
      <div className={styles.bodyHeader}>
        <div>{`${selecedPanel.value === 'branch' ? '支店名の設定' : 'Account Setting'} `}</div>
        <div className={styles.buttonTabsWrapper}>
          {panelOptions.map((op) => (
            <div
              key={op.value}
              className={classNames(styles.buttonTab, { [styles.active]: selecedPanel.value === op.value })}
              onClick={() => setSelectedPanel(op)}
            >{`${op.label}`}</div>
          ))}
        </div>
      </div>
      {user && selecedPanel.value === 'account' && <AccountPanel user={user} />}
      {branchesNonAdmin && selecedPanel.value === 'branch' && <BranchPanel branches={branchesNonAdmin} />}
    </div>
  )
}
