import React, { useEffect, useMemo, useState } from 'react'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { InputWith } from 'src/component/molecule/input-with/input-with'
import { blueprintIntl } from 'src/intl/data/blueprint.key'
import { BlueprintType, MappingInputType, MappedOptionValueType } from 'src/type/blueprint.type'
import { OptionType } from 'src/type/common.type'
import { DatasetType, FieldTypesEnum } from 'src/type/dataset.type'
import { ProjectTypesEnum } from 'src/type/project.type'
import styles from './select-value-row.scss'

type PropsType = {
  projectType: ProjectTypesEnum
  blueprint: BlueprintType
  dataset: DatasetType
  mappedOptionValue?: MappedOptionValueType
  mappedField?: { [fieldId: number]: number }
  isDropdownUpside?: boolean
  onChange?(mappedKeys: { [blueprintId: number]: MappingInputType }): void
}

export const SelectValueRow = ({
  projectType,
  blueprint,
  dataset,
  mappedOptionValue,
  mappedField,
  isDropdownUpside,
  onChange,
}: PropsType) => {
  const fieldOptions = useMemo(
    () =>
      dataset.fields
        ? dataset.fields
            .filter(
              (field) =>
                !mappedField || (mappedField && !mappedField[field.id]) || mappedField[field.id] === blueprint.id,
            )
            .filter((field) => field.type === FieldTypesEnum.NUMBER)
            .map((field) => ({ label: field.name, value: field.id }))
        : [],
    [dataset, mappedField],
  )

  const [selectedField, setSelectedField] = useState<OptionType | undefined>()
  const [value, setValue] = useState<number>()

  useEffect(() => {
    setSelectedField(mappedOptionValue?.selectedFieldOptions[0])
    setValue(mappedOptionValue?.value)
  }, [mappedOptionValue])

  function handleChangeMapping(fieldOp?: OptionType, value?: number) {
    if (value) {
      onChange &&
        onChange({
          [blueprint.id]: {
            bluePrintId: blueprint.id,
            fieldIds: [{ id: fieldOp?.value }],
            value,
          },
        })
    }
  }

  return (
    <ul className={styles.gridBodyRow}>
      <li className={styles.blueprintCell}>
        {blueprintIntl[projectType][blueprint.name].name}
        {blueprint.isRequired && <span className={styles.required}>*</span>}
      </li>
      <li className={styles.inputCell}>
        <DropdownSingleSelector
          placeholder={`マッピングされていません。`}
          sizeType='lg'
          isNeededFilter
          selectedVisible
          selectedOption={selectedField}
          options={fieldOptions}
          isOpenUpside={isDropdownUpside}
          isDisselectable
          onChange={(op) => {
            setSelectedField(op)
            handleChangeMapping(op, value)
          }}
        />
        <InputWith
          type='number'
          sizeType='lg'
          isFullWidth
          prefix={`単位 ￥`}
          value={value || ''}
          disabled={!selectedField}
          onChange={(e) => setValue(+e.currentTarget.value)}
          onBlur={(e) => {
            handleChangeMapping(selectedField, +e.currentTarget.value)
          }}
        />
      </li>
      <li className={styles.descCell}>{blueprintIntl[projectType][blueprint.name].description}</li>
    </ul>
  )
}
