import classNames from 'classnames'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import InlineSVG from 'react-inlinesvg/esm'
import closeIcon from 'src/style/icon/icon-x.svg'
import styles from './modal.scss'

export type PropsType = {
  title?: string
  modalAlignV?: 'top' | 'bottom' | 'center'
  modalAlignH?: 'left' | 'right' | 'center'
  children: React.ReactNode
  onClose(): void
}

export const Modal = ({ title, modalAlignV = 'center', modalAlignH = 'center', children, onClose }: PropsType) => {
  const [container] = useState(document.createElement('div'))

  React.useEffect(() => {
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [container])

  return createPortal(
    <div
      className={classNames(styles.root, {
        [styles.alignVModalTop]: modalAlignV === 'top',
        [styles.alignVModalCenter]: modalAlignV === 'center',
        [styles.alignVModalBottom]: modalAlignV === 'bottom',
        [styles.alignHModalLeft]: modalAlignH === 'left',
        [styles.alignHModalCenter]: modalAlignH === 'center',
        [styles.alignHModalRight]: modalAlignH === 'right',
      })}
      onClick={(e) => {
        e.stopPropagation()
        onClose()
      }}
    >
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={classNames(styles.header, { [styles.nonHeaderTitle]: !title })}>
          {title && <span className={styles.headerTitle}>{title}</span>}
          <InlineSVG className={styles.closeButton} src={closeIcon} onClick={onClose} />
        </div>
        {children}
      </div>
    </div>,
    container,
  )
}
