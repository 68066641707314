import { Action } from '@reduxjs/toolkit'
import { Epic, combineEpics } from 'redux-observable'
import { Observable, catchError, filter, map, mergeMap, of, switchMap, tap } from 'rxjs'
import { httpErrorhandling } from '../redux.util'
import { dashboardAction } from './dashboard.action'
import { dashboardService } from './dashboard.service'

const pendingStartEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        dashboardAction.createDashboardCompany.request.type,
        dashboardAction.createDashboardIndustry.request.type,
        dashboardAction.createDashboardContract.request.type,
        dashboardAction.fetchDashboardCompanyList.request.type,
        dashboardAction.fetchDashboardCompanyPieChartDatas.request.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.request.type,
        dashboardAction.fetchDashboardIndustry.request.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.request.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.request.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.request.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.request.type,
        dashboardAction.fetchDashboardContract.request.type,
        dashboardAction.downloadDsCompanyDoc.request.type,
        dashboardAction.downloadDsCompanyList.request.type,
        dashboardAction.downloadDsContractDoc.request.type,
        dashboardAction.downloadDsIndustryDoc.request.type,
      ].includes(action.type),
    ),
    map(() => dashboardAction.increasePendingCount()),
  )

const pendingEndEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter((action) =>
      [
        dashboardAction.createDashboardCompany.success.type,
        dashboardAction.createDashboardCompany.failure.type,
        dashboardAction.createDashboardCompany.cancelled.type,
        dashboardAction.createDashboardIndustry.success.type,
        dashboardAction.createDashboardIndustry.failure.type,
        dashboardAction.createDashboardIndustry.cancelled.type,
        dashboardAction.createDashboardContract.success.type,
        dashboardAction.createDashboardContract.failure.type,
        dashboardAction.createDashboardContract.cancelled.type,
        dashboardAction.fetchDashboardCompanyPieChartDatas.success.type,
        dashboardAction.fetchDashboardCompanyPieChartDatas.failure.type,
        dashboardAction.fetchDashboardCompanyPieChartDatas.cancelled.type,
        dashboardAction.fetchDashboardCompanyList.success.type,
        dashboardAction.fetchDashboardCompanyList.failure.type,
        dashboardAction.fetchDashboardCompanyList.cancelled.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.success.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.failure.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.cancelled.type,
        dashboardAction.fetchDashboardIndustry.success.type,
        dashboardAction.fetchDashboardIndustry.failure.type,
        dashboardAction.fetchDashboardIndustry.cancelled.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.success.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.failure.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.cancelled.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.success.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.cancelled.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.success.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.cancelled.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.success.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.cancelled.type,
        dashboardAction.fetchDashboardContract.success.type,
        dashboardAction.fetchDashboardContract.failure.type,
        dashboardAction.fetchDashboardContract.cancelled.type,
        dashboardAction.downloadDsCompanyDoc.success.type,
        dashboardAction.downloadDsCompanyDoc.failure.type,
        dashboardAction.downloadDsCompanyDoc.cancelled.type,
        dashboardAction.downloadDsCompanyList.success.type,
        dashboardAction.downloadDsCompanyList.failure.type,
        dashboardAction.downloadDsCompanyList.cancelled.type,
        dashboardAction.downloadDsContractDoc.success.type,
        dashboardAction.downloadDsContractDoc.failure.type,
        dashboardAction.downloadDsContractDoc.cancelled.type,
        dashboardAction.downloadDsIndustryDoc.success.type,
        dashboardAction.downloadDsIndustryDoc.failure.type,
        dashboardAction.downloadDsIndustryDoc.cancelled.type,
      ].includes(action.type),
    ),
    map(() => dashboardAction.decreasePendingCount()),
  )

const failureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) =>
      [
        dashboardAction.createDashboardCompany.failure.type,
        dashboardAction.createDashboardIndustry.failure.type,
        dashboardAction.createDashboardContract.failure.type,
        dashboardAction.fetchDashboardCompanyList.failure.type,
        dashboardAction.fetchDashboardCompanyPieChartDatas.failure.type,
        dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.failure.type,
        dashboardAction.fetchDashboardIndustry.failure.type,
        dashboardAction.fetchDashboardIndustryFilterCorporateTypes.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry1s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry2s.failure.type,
        dashboardAction.fetchDashboardIndustryFilterIndustry3s.failure.type,
        dashboardAction.fetchDashboardContract.failure.type,
        dashboardAction.downloadDsCompanyDoc.failure.type,
        dashboardAction.downloadDsCompanyList.failure.type,
        dashboardAction.downloadDsContractDoc.failure.type,
        dashboardAction.downloadDsIndustryDoc.failure.type,
      ].includes(action.type),
    ),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const fetchDashboardCompanyEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompany.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardCompany(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompany.success(),
          dashboardAction.setDashboardCompany(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompany.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyListEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyList.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardCompanyList(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyList.success(),
          dashboardAction.setDashboardCompanyList(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyList.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyPieChartDatasEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyPieChartDatas.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardCompanyPieChartDatas(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyPieChartDatas.success(),
          dashboardAction.setDashboardCompanyPieChartDatas(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyPieChartDatas.failure(err))),
      ),
    ),
  )
const fetchDashboardCompanyUnspecifiedIndustryEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardCompanyUnspecifiedIndustry(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.success(),
          dashboardAction.setDashboardCompanyUnspecifiedIndustry(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyUnspecifiedIndustry.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustry.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardIndustry(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustry.success(),
          dashboardAction.setDashboardIndustry(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustry.failure(err))),
      ),
    ),
  )

const fetchDashboardContractEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardContract.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardContract(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardContract.success(),
          dashboardAction.setDashboardContract(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardContract.failure(err))),
      ),
    ),
  )

const createDashboardCompanyEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.createDashboardCompany.request.match),
    switchMap(({ payload }) =>
      dashboardService.createDashboardCompany(payload).pipe(
        mergeMap(() => [dashboardAction.createDashboardCompany.success()]),
        catchError((err) => of(dashboardAction.createDashboardCompany.failure(err))),
      ),
    ),
  )

const createDashboardIndustryEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.createDashboardIndustry.request.match),
    switchMap(({ payload }) =>
      dashboardService.createDashboardIndustry(payload).pipe(
        mergeMap(() => [
          dashboardAction.createDashboardIndustry.success(),
          dashboardAction.fetchDashboardIndustryFilterCorporateTypes.request(),
        ]),
        catchError((err) => of(dashboardAction.createDashboardIndustry.failure(err))),
      ),
    ),
  )

const createDashboardContractEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.createDashboardContract.request.match),
    switchMap(({ payload }) =>
      dashboardService.createDashboardContract(payload).pipe(
        mergeMap(() => [
          dashboardAction.createDashboardContract.success(),
          dashboardAction.fetchDashboardContractFilterItems.request(),
        ]),
        catchError((err) => of(dashboardAction.createDashboardContract.failure(err))),
      ),
    ),
  )

const downloadDsCompanyListEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.downloadDsCompanyList.request.match),
    switchMap(({ payload }) =>
      dashboardService.downloadDsCompanyList(payload).pipe(
        mergeMap(() => [dashboardAction.downloadDsCompanyList.success()]),
        tap(() => {
          window.location.href = `/api/dashboard/downloaddscompanylistfile`
        }),
        catchError((err) => of(dashboardAction.downloadDsCompanyList.failure(err))),
      ),
    ),
  )

const downloadDsCompanyDocEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.downloadDsCompanyDoc.request.match),
    switchMap(({ payload }) =>
      dashboardService.downloadDsCompanyDoc(payload).pipe(
        mergeMap(() => [dashboardAction.downloadDsCompanyDoc.success()]),
        tap(() => {
          window.location.href = `/api/dashboard/downloaddscompanydoc`
        }),
        catchError((err) => of(dashboardAction.downloadDsCompanyDoc.failure(err))),
      ),
    ),
  )

const downloadDsContractDocEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.downloadDsContractDoc.request.match),
    switchMap(({ payload }) =>
      dashboardService.downloadDsContractDoc(payload).pipe(
        mergeMap(() => [dashboardAction.downloadDsContractDoc.success()]),
        tap(() => {
          window.location.href = `/api/dashboard/downloaddscontractdoc`
        }),
        catchError((err) => of(dashboardAction.downloadDsContractDoc.failure(err))),
      ),
    ),
  )

const downloadDsIndustryDocEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.downloadDsIndustryDoc.request.match),
    switchMap(({ payload }) =>
      dashboardService.downloadDsIndustryDoc(payload).pipe(
        mergeMap(() => [dashboardAction.downloadDsIndustryDoc.success()]),
        tap(() => {
          window.location.href = `/api/dashboard/downloaddsindustrydoc`
        }),
        catchError((err) => of(dashboardAction.downloadDsIndustryDoc.failure(err))),
      ),
    ),
  )

const fetchDashboardContractFilterItemsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardContractFilterItems.request.match),
    switchMap(() =>
      dashboardService.fetchDashboardContractFilterItems().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardContractFilterItems.success(),
          dashboardAction.setDashboardContractFilterItems(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardContractFilterItems.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryFilterCorporateTypesEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustryFilterCorporateTypes.request.match),
    switchMap(() =>
      dashboardService.fetchDashboardIndustryFilterCorporateType().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustryFilterCorporateTypes.success(),
          dashboardAction.setDashboardIndustryFilterCorporateTypes(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustryFilterCorporateTypes.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryFilterIndustry1sEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustryFilterIndustry1s.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardIndustryFilterIndustry1s(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustryFilterIndustry1s.success(),
          dashboardAction.setDashboardIndustryFilterIndustry1s(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustryFilterIndustry1s.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryFilterIndustry2sEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustryFilterIndustry2s.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardIndustryFilterIndustry2s(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustryFilterIndustry2s.success(),
          dashboardAction.setDashboardIndustryFilterIndustry2s(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustryFilterIndustry2s.failure(err))),
      ),
    ),
  )

const fetchDashboardIndustryFilterIndustry3sEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardIndustryFilterIndustry3s.request.match),
    switchMap(({ payload }) =>
      dashboardService.fetchDashboardIndustryFilterIndustry3s(payload).pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardIndustryFilterIndustry3s.success(),
          dashboardAction.setDashboardIndustryFilterIndustry3s(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardIndustryFilterIndustry3s.failure(err))),
      ),
    ),
  )

const fetchDashboardCompanyFilterItemsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(dashboardAction.fetchDashboardCompanyFilterItems.request.match),
    switchMap(() =>
      dashboardService.fetchDashboardCompanyFilterItems().pipe(
        mergeMap(({ response }) => [
          dashboardAction.fetchDashboardCompanyFilterItems.success(),
          dashboardAction.setDashboardCompanyFilterItems(response),
        ]),
        catchError((err) => of(dashboardAction.fetchDashboardCompanyFilterItems.failure(err))),
      ),
    ),
  )

export const dashboardEpic = combineEpics(
  pendingStartEpic,
  pendingEndEpic,
  failureEpic,

  createDashboardCompanyEpic,
  createDashboardIndustryEpic,
  createDashboardContractEpic,

  fetchDashboardCompanyEpic,
  fetchDashboardCompanyListEpic,
  fetchDashboardCompanyPieChartDatasEpic,
  fetchDashboardCompanyUnspecifiedIndustryEpic,

  fetchDashboardIndustryEpic,

  fetchDashboardContractEpic,

  downloadDsContractDocEpic,
  downloadDsIndustryDocEpic,
  downloadDsCompanyDocEpic,
  downloadDsCompanyListEpic,

  fetchDashboardContractFilterItemsEpic,
  fetchDashboardIndustryFilterCorporateTypesEpic,
  fetchDashboardIndustryFilterIndustry1sEpic,
  fetchDashboardIndustryFilterIndustry2sEpic,
  fetchDashboardIndustryFilterIndustry3sEpic,
  fetchDashboardCompanyFilterItemsEpic,
)
