import { UserType } from 'src/type/user.type'
import { httpApi } from 'src/util/http.module'

export type SignupParamsType = {
  username: string
  userNickname: string
  password: string
  branchId?: number
  level?: number
  department?: string
  activate?: boolean
}
const signup = (params: SignupParamsType) => httpApi.post<{ username: string }>({ url: `/signup`, body: params })

export type FetchUserParamsType = {
  userId: number
}
const fetchUser = (params: FetchUserParamsType) => httpApi.get<UserType>({ url: `/users/${params.userId}` })

export type EditUserCommonParamsType = {
  userId: number
  username?: string
  userNickname?: string
  branchId?: number
  level?: number
  department?: string
  activate?: boolean
}
const editUserCommon = ({
  userId,
  username,
  userNickname,
  branchId,
  level,
  department,
  activate,
}: EditUserCommonParamsType) =>
  httpApi.post<UserType>({
    url: `/users/${userId}`,
    body: { username, userNickname, branchId, level, department, activate },
  })

export type EditUserPasswordParamsType = {
  userId: number
  currentPassword: string
  newPassword: string
}
const editUserPassword = ({ userId, currentPassword, newPassword }: EditUserPasswordParamsType) =>
  httpApi.post({
    url: `/users/${userId}/password`,
    body: { currentPassword, newPassword },
  })

export const userService = {
  signup,
  fetchUser,
  editUserCommon,
  editUserPassword,
}
