import React, { useEffect } from 'react'
import InlineSVG from 'react-inlinesvg'
import { useDispatch } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { userAction } from 'src/redux/user/user.action'
import ImageSignUpSuccess from 'src/style/image/image-sign-up-success.svg'
import styles from './sign-up-success.scss'

type PropsType = {
  signedUpUsername: string
  onClose(): void
}

export const SignUpSuccessModal = withModalWrapper(({ signedUpUsername, onClose }: PropsType) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(userAction.setRequestedSignUpUsername(null))
    }
  })

  function handleClickConfirmButton() {
    onClose()
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <InlineSVG src={ImageSignUpSuccess} />
        <div>
          <div className={styles.head}>{`Good Job, ${signedUpUsername}`}</div>
          <div className={styles.desc}>
            {`We are waiting for the administrator's approval for the great journey. Please wait a little longer.`}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button className={styles.submitBtn} buttonColor='black' onClick={handleClickConfirmButton}>
          Back to home
        </Button>
      </div>
    </div>
  )
})
