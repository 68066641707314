import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useVisibleWithOutsideClick } from 'src/hook/use-visible-with-outside-click'
import { authAction } from 'src/redux/auth/auth.action'
import { UserType } from 'src/type/user.type'
import styles from './user-info.scss'

type PropsType = {
  user: UserType
}

export const UserInfo = ({ user }: PropsType) => {
  const dispatch = useDispatch()
  const { ref, isVisible, setIsVisible } = useVisibleWithOutsideClick<HTMLDivElement>(false)

  function handleClickUser() {
    setIsVisible(!isVisible)
  }

  function handleSignOutClick() {
    dispatch(authAction.logout.request())
  }

  return (
    <div ref={ref}>
      <div className={styles.profile} onClick={handleClickUser}>
        {user.userNickname.slice(0, 2)}
      </div>
      <div className={styles.anchor}>
        {isVisible && (
          <div className={styles.content}>
            <div className={styles.info}>
              <div className={styles.infoProfile}>{user.userNickname.slice(0, 2)}</div>
              <span className={styles.nickName}>{user.username}</span>
              <span className={styles.email}>{user.userNickname}</span>
              <div className={styles.setting}>
                <NavLink className={styles.settingNav} to={'/setting'}>
                  View Account
                </NavLink>
              </div>
            </div>
            <div className={styles.signOut} onClick={handleSignOutClick}>
              Sign Out
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
