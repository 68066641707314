import classNames from 'classnames'
import React from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'src/component/atom/button/button'
import { Lottie } from 'src/component/atom/lottie/lottie'
import { ProgressBar } from 'src/component/atom/progress-bar/progress-bar'
import { ProjectResultIntl } from 'src/intl/data/result.key'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { projectAction } from 'src/redux/project/project.action'
import { projectSelector } from 'src/redux/project/project.state'
import { unionSelector } from 'src/redux/union/union.state'
import ImgChurnPrev from 'src/style/image/image-chrun-prev.svg'
import ImgCorpLoanDemand from 'src/style/image/image-corp-loan-demand.svg'
import ImgCorpSalesInsight from 'src/style/image/image-corp-sales-insight.svg'
import ImgCrossSell from 'src/style/image/image-cross-sell.svg'
import ImgUpSell from 'src/style/image/image-up-sell.svg'
import LogoReadyAI from 'src/style/logo/logo-readyai.svg'
import TrainingData from 'src/style/lottie/training.json'
import { ProjectStepEnum, ProjectTypesEnum } from 'src/type/project.type'
import { util } from 'src/util/common.util'
import styles from './training.scss'

export const TrainingPanel = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selecetedProjectDetail = useSelector(projectSelector.selectedProjectDetail)
  const selectedProjectResultSegments = useSelector(projectSelector.selectedProjectResultSegments)
  const currentTrainingProgress = useSelector(unionSelector.currentTrainingProgress)

  function handleClickCreateDashboardButton() {
    if (selecetedProjectDetail) {
      dispatch(dashboardAction.createDashboardCompany.request({ projectId: selecetedProjectDetail.id }))
      dispatch(dashboardAction.createDashboardContract.request({ projectId: selecetedProjectDetail.id }))
      dispatch(dashboardAction.createDashboardIndustry.request({ projectId: selecetedProjectDetail.id }))
    }
  }

  function handleClickNextPageButton() {
    dispatch(projectAction.setProjectStep(ProjectStepEnum.RESULT))
  }

  return (
    selecetedProjectDetail && (
      <div className={styles.root}>
        {selecetedProjectDetail.projectResult.length > 0 &&
        selectedProjectResultSegments &&
        !currentTrainingProgress ? (
          <div className={styles.result}>
            {selecetedProjectDetail.type === ProjectTypesEnum.CHURN_PREVENTION ? (
              <InlineSVG className={styles.logo} src={ImgChurnPrev} />
            ) : selecetedProjectDetail.type === ProjectTypesEnum.CROSS_SELLING ? (
              <InlineSVG className={styles.logo} src={ImgCrossSell} />
            ) : selecetedProjectDetail.type === ProjectTypesEnum.UP_SELLING ? (
              <InlineSVG className={styles.logo} src={ImgUpSell} />
            ) : selecetedProjectDetail.type === ProjectTypesEnum.CORP_LOAN_DEMAND ? (
              <InlineSVG className={styles.logo} src={ImgCorpLoanDemand} />
            ) : selecetedProjectDetail.type === ProjectTypesEnum.CORP_SALES_INSIGHTS ? (
              <InlineSVG className={styles.logo} src={ImgCorpSalesInsight} />
            ) : (
              <InlineSVG className={styles.logo} src={LogoReadyAI} />
            )}
            {selecetedProjectDetail.type === ProjectTypesEnum.CORP_SALES_INSIGHTS ? (
              <span className={styles.notice}>{`分析が終了しました。`}</span>
            ) : (
              <span className={styles.notice}>{`学習を完了しました！`}</span>
            )}
            <div className={styles.resultSummary}>
              {selecetedProjectDetail.type === ProjectTypesEnum.CORP_SALES_INSIGHTS ? (
                <>
                  <div>{`各企業の分析が完了しました。`}</div>
                  <div>{`セールスインサイトが示すダッシュボードを確認しましょう。`}</div>
                </>
              ) : (
                <>
                  <div>{`${ProjectResultIntl[selecetedProjectDetail.type].kpiRatioSummery}: ${(
                    (selecetedProjectDetail?.projectResult[0]?.kpiRatio || 0) * 100
                  ).toFixed(2)} %`}</div>
                  {selecetedProjectDetail?.projectResult[0]?.globalTargetRatio !== null && (
                    <div>{`(${ProjectResultIntl[selecetedProjectDetail.type].globalTargetRatio}: ${(
                      (selecetedProjectDetail?.projectResult[0]?.globalTargetRatio || 0) * 100
                    ).toFixed(2)} %)`}</div>
                  )}
                  <div>{`${selectedProjectResultSegments.length || 0} 個の有意なセグメントが見つかりました。`}</div>
                </>
              )}
            </div>
            <div
              className={classNames(styles.buttonWrapper, {
                [styles.csiBtnWrapper]: selecetedProjectDetail.type === ProjectTypesEnum.CORP_SALES_INSIGHTS,
              })}
            >
              {selecetedProjectDetail.type === ProjectTypesEnum.CORP_SALES_INSIGHTS ? (
                <>
                  <Button
                    sizeType='lg'
                    outlined
                    onClick={() => {
                      navigate('/')
                    }}
                  >{`ホーム画面に戻る`}</Button>
                  <Button
                    sizeType='lg'
                    onClick={handleClickCreateDashboardButton}
                  >{`ダッシュボードをアップデート`}</Button>
                </>
              ) : (
                <Button sizeType='lg' onClick={handleClickNextPageButton}>{`結果を見る`}</Button>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.training}>
            <Lottie className={styles.lottie} animationData={TrainingData} />
            <ProgressBar
              progress={util.trainingProjectTaskProgress(
                selecetedProjectDetail.type,
                selecetedProjectDetail.projectResult.length,
                currentTrainingProgress,
              )}
              sizeType='md'
              outlined
              isRounded
            />
          </div>
        )}
        <div className={styles.footer}>
          <a href='#' className={styles.outQnaLink}>
            {``}
          </a>
        </div>
      </div>
    )
  )
}
