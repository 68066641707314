import { createAction } from '@reduxjs/toolkit'

export function createAsyncAction<P = void, T extends string = string>(type: T) {
  const R = `${type} request`
  const S = `${type} success`
  const F = `${type} failure`
  const C = `${type} cancelled`

  const requestAction = createAction<P>(R)
  const successAction = createAction(S)
  const failureAction = createAction<Error | undefined>(F)
  const cancelledAction = createAction(C)

  return {
    request: requestAction,
    success: successAction,
    failure: failureAction,
    cancelled: cancelledAction,
  }
}
