import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { TrainConfirmModal } from 'src/modal/train-confirm/train-confirm.modal'
import { blueprintAction } from 'src/redux/blueprint/blueprint.action'
import { blueprintSelector } from 'src/redux/blueprint/blueprint.state'
import { datasetSelector } from 'src/redux/dataset/dataset.state'
import { projectAction } from 'src/redux/project/project.action'
import { BlueprintTypesEnum, MappingInputType, MappedOptionValueType } from 'src/type/blueprint.type'
import { OptionType } from 'src/type/common.type'
import { DatasetTypesEnum, FieldType } from 'src/type/dataset.type'
import { ProjectStepEnum } from 'src/type/project.type'
import { ProjectType } from 'src/type/project.type'
import { ProjectTypesProperties } from 'src/ui-variable/project.ui'
import styles from './blue-print.scss'
import { KeyMapRow } from './key-map-row/key-map-row'
import { SelectRow } from './select-row/select-row'
import { SelectSelectRow } from './select-select-row/select-select-row'
import { SelectSingleRow } from './select-single-row /select-single-row'
import { SelectValueRow } from './select-value-row/select-value-row'

type PropsType = {
  project: ProjectType
}

export const BluePrintPanel = ({ project }: PropsType) => {
  const dispatch = useDispatch()

  const datasets = useSelector(datasetSelector.datasets)
  const datasetWithDatasetTypes = useSelector(datasetSelector.datasetWithDatasetTypes)
  const blueprints = useSelector(blueprintSelector.blueprints)
  const blueprintMappings = useSelector(blueprintSelector.blueprintMappings)

  const [modalVisible, setModalVisible] = React.useState(false)

  const [keyBlueprints, otherBlueprints] = useMemo(() => {
    const sortedBlueprints = blueprints ? [...blueprints].sort((a, b) => a.id - b.id) : []
    const keys = sortedBlueprints.filter((item) => item.type === BlueprintTypesEnum.KEY)
    const notKeys = sortedBlueprints.filter((item) => item.type !== BlueprintTypesEnum.KEY)

    return [keys, notKeys]
  }, [blueprints])

  const blueprintMappedOptionValues = useMemo(
    () =>
      blueprintMappings
        ? blueprintMappings.reduce<{ [blueprintId: number]: MappedOptionValueType }>((res, mappingData) => {
            res[mappingData.bluePrint.id] = {
              bluePrint: { id: mappingData.bluePrint.id },
              project: { id: mappingData.project.id },
              selectedFieldOptions: mappingData.fields.map((field) => ({ label: field.name, value: field.id })),
              value: mappingData.value,
              values: mappingData.values,
            }

            return res
          }, {})
        : {},
    [blueprintMappings],
  )

  const keysMappedOptionValues = useMemo(() => {
    const fieldsWithDatasetType = datasets.reduce<{
      [datasetType: string]: (FieldType & { datasetType: DatasetTypesEnum })[]
    }>((res, dataset) => {
      res[dataset.type] = dataset.fields ? dataset.fields.map((field) => ({ ...field, datasetType: dataset.type })) : []
      return res
    }, {})

    return keyBlueprints
      .map((keyBp) => {
        const selectedFields = blueprintMappedOptionValues[keyBp.id]?.selectedFieldOptions
        if (selectedFields) {
          const reOrderedOptions = Object.values(ProjectTypesProperties[project.type].datasetCategories)
            .map((datasetTypes) =>
              selectedFields.find((field) => fieldsWithDatasetType[datasetTypes].some((f) => f.id === field.value)),
            )
            .filter((v): v is OptionType => !!v)
          return { ...blueprintMappedOptionValues[keyBp.id], selectedFieldOptions: reOrderedOptions }
        }
      })
      .filter((bp): bp is MappedOptionValueType => !!bp)
  }, [keyBlueprints, blueprintMappedOptionValues])

  const fieldMappedBlueprint = blueprintMappings?.reduce<{ [fieldId: number]: number }>((res, mapping) => {
    return {
      ...res,
      ...mapping.fields.reduce<{ [fieldId: number]: number }>((re, field) => {
        re[field.id] = mapping.bluePrint.id
        return re
      }, {}),
    }
  }, {})

  const nextButtonDisabled = !useMemo(
    () => blueprints?.filter((bp) => bp.isRequired).every((bp) => !!blueprintMappedOptionValues[bp.id]),
    [blueprintMappedOptionValues],
  )

  useEffect(() => {
    dispatch(blueprintAction.fetchBlueprintMapping.request({ projectId: project.id }))
  }, [])

  function handlePrevButtonClick() {
    dispatch(projectAction.setProjectStep(ProjectStepEnum.DATASET))
  }

  function handleChangeKeyMapping(mappedKeys: { [blueprintId: number]: MappingInputType }) {
    Object.values(mappedKeys).forEach((inputType) => {
      if (inputType.fieldIds.some((field) => field.id === undefined)) {
        // delete (secondKey)
        dispatch(
          blueprintAction.deleteFieldMapping.request({
            projectId: project.id,
            bluePrintId: inputType.bluePrintId,
          }),
        )

        return
      } else {
        dispatch(
          blueprintAction.updateFieldMapping.request({
            projectId: project.id,
            bluePrintId: inputType.bluePrintId,
            fieldIds: inputType.fieldIds.map((key) => ({ id: key.id })),
            value: inputType.value,
            values: inputType.values,
          }),
        )

        return
      }
    })
  }

  function handleChangeMapping(mappedKeys: { [blueprintId: number]: MappingInputType }) {
    Object.values(mappedKeys).forEach((inputType) => {
      if (inputType.fieldIds.some((field) => field.id === undefined)) {
        // disselect
        dispatch(
          blueprintAction.deleteFieldMapping.request({
            projectId: project.id,
            bluePrintId: inputType.bluePrintId,
          }),
        )

        return
      } else {
        dispatch(
          blueprintAction.updateFieldMapping.request({
            projectId: project.id,
            bluePrintId: inputType.bluePrintId,
            fieldIds: inputType.fieldIds.map((key) => ({ id: key.id })),
            value: inputType.value,
            values: inputType.values,
          }),
        )

        return
      }
    })
  }

  function handleNextButtonClick() {
    setModalVisible(true)
  }

  return (
    <div className={styles.root}>
      {blueprints && (
        <>
          <div className={styles.sectionTitle}>{`あなたのデータとテンプレートの紐づけをしてください。`}</div>
          <section className={styles.mappingSection}>
            <span className={styles.mappingState}>{`全 ${blueprints.length} 項目のうち、 ${
              blueprintMappings?.length || 0
            } 項目がマッピングされました。`}</span>
            <div className={styles.mappingGrid}>
              <ul className={styles.gridHeadRow}>
                <li className={styles.headCell}>{`テンプレート`}</li>
                <li className={styles.inputHeadCell}>{`あなたのデータ`}</li>
                <li className={styles.headCell}>{`意味`}</li>
              </ul>
              <div className={styles.gridBody}>
                <KeyMapRow
                  projectType={project.type}
                  datasetWithDatasetTypes={datasetWithDatasetTypes}
                  keyBlueprints={keyBlueprints}
                  keysMappedOptionValues={keysMappedOptionValues}
                  mappedField={fieldMappedBlueprint}
                  onChange={handleChangeKeyMapping}
                />
                {otherBlueprints.map((blueprint, idx) => {
                  if (blueprint.type === BlueprintTypesEnum.SELECT) {
                    return (
                      <SelectRow
                        key={blueprint.id}
                        projectType={project.type}
                        blueprint={blueprint}
                        dataset={datasetWithDatasetTypes[blueprint.resourceType]}
                        mappedOptionValue={blueprintMappedOptionValues[blueprint.id]}
                        mappedField={fieldMappedBlueprint}
                        isDropdownUpside={idx > otherBlueprints.length - 4}
                        onChange={handleChangeMapping}
                      />
                    )
                  } else if (blueprint.type === BlueprintTypesEnum.SELECT_SELECT) {
                    return (
                      <SelectSelectRow
                        key={blueprint.id}
                        projectType={project.type}
                        blueprint={blueprint}
                        dataset={datasetWithDatasetTypes[blueprint.resourceType]}
                        mappedOptionValue={blueprintMappedOptionValues[blueprint.id]}
                        mappedField={fieldMappedBlueprint}
                        isDropdownUpside={idx > otherBlueprints.length - 4}
                        onChange={handleChangeMapping}
                      />
                    )
                  } else if (blueprint.type === BlueprintTypesEnum.SELECT_SINGLE) {
                    return (
                      <SelectSingleRow
                        key={blueprint.id}
                        projectType={project.type}
                        blueprint={blueprint}
                        dataset={datasetWithDatasetTypes[blueprint.resourceType]}
                        mappedOptionValue={blueprintMappedOptionValues[blueprint.id]}
                        mappedField={fieldMappedBlueprint}
                        isDropdownUpside={idx > otherBlueprints.length - 4}
                        onChange={handleChangeMapping}
                      />
                    )
                  } else if (blueprint.type === BlueprintTypesEnum.SELECT_VALUE) {
                    return (
                      <SelectValueRow
                        key={blueprint.id}
                        projectType={project.type}
                        blueprint={blueprint}
                        dataset={datasetWithDatasetTypes[blueprint.resourceType]}
                        mappedOptionValue={blueprintMappedOptionValues[blueprint.id]}
                        mappedField={fieldMappedBlueprint}
                        isDropdownUpside={idx > otherBlueprints.length - 4}
                        onChange={handleChangeMapping}
                      />
                    )
                  }
                })}
              </div>
            </div>
          </section>
          <div className={styles.footer}>
            <a href='#' className={styles.outQnaLink}>
              {``}
            </a>
            <Button sizeType='lg' outlined onClick={handlePrevButtonClick}>
              {`前へ`}
            </Button>
            <Button sizeType='lg' disabled={nextButtonDisabled} onClick={handleNextButtonClick}>{`次へ`}</Button>
          </div>
        </>
      )}

      <TrainConfirmModal modalVisible={modalVisible} project={project} onClose={() => setModalVisible(false)} />
    </div>
  )
}
