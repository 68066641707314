import React from 'react'
import { ChartBarCompare } from 'src/component/molecule/chart-bar-compare/chart-bar-compare'
import { ChartBarMonthly } from 'src/component/molecule/chart-bar-monthly/chart-bar-monthly'
import { ChartLine } from 'src/component/molecule/chart-line/chart-line'
import { ExpantionPanel } from 'src/component/molecule/expantion-panel/expantion-panel'
import { withSideModalPageWrapper } from 'src/hoc/with-side-modal-page-wrapper'
import { dashboardCompanyLoanGradeDataIntl, dashboardCompanySalesMotivesIntl } from 'src/intl/data/dashboard.key'
import { DashboardCompanyType } from 'src/type/dashboard.type'
import styles from './company-detail.scss'

type PropsType = {
  dashboardCompany: DashboardCompanyType
}

const CompanyDetail = ({ dashboardCompany }: PropsType) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.companyName}>{dashboardCompany.companyName}</div>
      </div>
      <div className={styles.companyInfo}>
        <div className={styles.defaultInfo}>
          <div className={styles.defaultTitle}>{`基本情報`}</div>
          <div className={styles.defaultDetail}>
            <div className={styles.detailLabel}>{`住所`}</div>
            <div className={styles.detailText}>{dashboardCompany.postCode2}</div>
            <div className={styles.detailLabel}>{`業種(大)`}</div>
            <div className={styles.detailText}>{dashboardCompany.industry1}</div>
            <div className={styles.detailLabel}>{`業種(中)`}</div>
            <div className={styles.detailText}>{dashboardCompany.industry2}</div>
            <div className={styles.detailLabel}>{`業種(小)`}</div>
            <div className={styles.detailText}>{dashboardCompany.industry3}</div>
            <div className={styles.detailLabel}>{`業歴`}</div>
            <div className={styles.detailText}>
              {dashboardCompany.corporationAge ? `${dashboardCompany.corporationAge}年` : ''}
            </div>
            <div className={styles.detailLabel}>{`代表者年齢`}</div>
            <div className={styles.detailText}>{dashboardCompany.ceoAge ? `${dashboardCompany.ceoAge}` : ''}</div>
            <div className={styles.detailLabel}>{`家族構成`}</div>
            <div className={styles.detailText}>{dashboardCompany.spouse}</div>
            <div className={styles.detailLabel}>{`外部信用情報機関`}</div>
            <div className={styles.detailText}>{dashboardCompany.descendant}</div>
            <div className={styles.detailLabel}>{`格付`}</div>
            <div className={styles.detailText}>{dashboardCompany.creditBureauScore}</div>
          </div>
        </div>
        <div className={styles.adviceArea}>
          <div className={styles.adviceItem}>
            <div className={styles.adviceHeader}>
              <div className={styles.adviceTitle}>{`融資営業に関するインサイト`}</div>
              <div className={styles.loanGrades}>
                {`融資1: ${
                  dashboardCompany?.loan1Grade
                    ? dashboardCompanyLoanGradeDataIntl[dashboardCompany.loan1Grade] || dashboardCompany.loan1Grade
                    : '-'
                } / 融資2: ${
                  dashboardCompany?.loan2Grade
                    ? dashboardCompanyLoanGradeDataIntl[dashboardCompany.loan2Grade] || dashboardCompany.loan2Grade
                    : ''
                }`}
              </div>
            </div>
            <ul className={styles.adviceList}>
              {dashboardCompany.salesMotives?.loanBusiness.loanRules.rule1 === 'true' && (
                <li>{dashboardCompanySalesMotivesIntl['loanRule1'] || `If grade of loan1 is 5, then True`}</li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.loanRules.rule2 === 'true' && (
                <li>{dashboardCompanySalesMotivesIntl['loanRule2'] || `If grade of loan2 is 5, then True`}</li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.balanceRules.rule1 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule1'] || `Drops for 3 months in a row (>20% per month)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.balanceRules.rule2 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule2'] || `Drops for 6 months in a row (>20% per month)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.balanceRules.rule3 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule3'] ||
                    `Average balance for the last 6 months is <= (1/2) * avg over entire period`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.balanceRules.rule4 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule4'] ||
                    `average balance in the last 6 months is >= 2 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.balanceRules.rule5 === 'true' && (
                <li>{dashboardCompanySalesMotivesIntl['balanceRule5'] || `vp month balance <= 0.1 * vp-1 balance`}</li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.balanceRules.rule6 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['balanceRule6'] ||
                    `balance decreased by more than 20% at least 2 times in last 6 months (compared to prev month)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.depositRules.rule1 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['depositRule1'] ||
                    `6 consecutive months of increase (>20% per month)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.depositRules.rule2 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['depositRule2'] ||
                    `6 consecutive months of decrease (>20% per month)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.depositRules.rule3 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['depositRule3'] ||
                    `avg deposit amount of recent 6m <= (1/2) * avg over entire period`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.depositRules.rule4 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['depositRule4'] ||
                    `avg deposit amount of recent 6m >= 2 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.withdrawalRules.rule1 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['withdrawalRule1'] ||
                    `avg withdrawal amount over recent 6m <= (1/2) * avg over entire period`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.withdrawalRules.rule2 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['withdrawalRule2'] ||
                    `avg withdrawal amount over recent 6m >= 2 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.withdrawalRules.rule3 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['withdrawalRule3'] ||
                    `withdrawal increased by more than 20% at viewpoint (compared to prev month)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.gainRules.rule1 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['gainRule1'] ||
                    `For the 3 consecutive month, earnings are negative `}
                </li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.gainRules.rule2 === 'true' && (
                <li>{dashboardCompanySalesMotivesIntl['gainRule2'] || `6 consecutive months of negative earnings `}</li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.gainRules.rule3 === 'true' && (
                <li>{dashboardCompanySalesMotivesIntl['gainRule3'] || `gain is negative (loss) at viewpoint`}</li>
              )}
              {dashboardCompany.salesMotives?.loanBusiness.gainRules.rule4 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['gainRule4'] ||
                    `gain is negative (loss) at least 2 times in the last 6 months`}
                </li>
              )}
            </ul>
            <div className={styles.adviceHeader}>
              <div className={styles.adviceTitle}>{`事業支援に関するインサイト`}</div>
            </div>
            <ul className={styles.adviceList}>
              {dashboardCompany.salesMotives?.businessSupport.salaryRules.rule1 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['salaryRule1'] ||
                    `avg monthly payment over recent 6m <= 0.7 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.salesMotives?.businessSupport.salaryRules.rule2 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['salaryRule2'] ||
                    `avg monthly payment over recent 6m >= 1.3 * avg over entire period`}
                </li>
              )}
              {dashboardCompany.salesMotives?.businessSupport.salaryRules.rule3 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['salaryRule3'] ||
                    `avg monthly payments over recent 6m <= 0.7 * avg over same period 1y before (applicable only when 24 months of data are put in)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.businessSupport.salaryRules.rule4 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['salaryRule4'] ||
                    `avg monthly payment over recent 6m >= 1.3 * avg over same period 1y before (applicable only when inputting 24-month data)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.businessSupport.insuranceFeeRules.rule1 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['insuranceFeeRule1'] ||
                    `Total premium payments over recent 12m <= 0.7 * avg over same period 1y ago (applicable only when 24-month data is put in)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.businessSupport.insuranceFeeRules.rule2 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['insuranceFeeRule2'] ||
                    `Total premium payments over recent 12m >= 1.3 * avg over same period 1y ago (applicable only when inputting 24-month data)`}
                </li>
              )}
              {dashboardCompany.salesMotives?.businessSupport.corporateTaxRules.rule1 === 'true' && (
                <li>
                  {dashboardCompanySalesMotivesIntl['corporateTaxRule1'] ||
                    `avg corporate tax rate for the last 12m >= 2 * industry avg corporate tax`}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <ExpantionPanel title='融資営業に関するインサイト' contentHeight={1330} initailExpantion={true}>
        <div className={styles.chartWrapper}>
          <ChartBarCompare
            chartHight={300}
            title='AIスコア（融資1）'
            yAxisTitle='スコア'
            yUnit={0.0001}
            valueDecimals={2}
            xTickValues={dashboardCompany.chartLoan1Score?.xValues}
            series={dashboardCompany.chartLoan1Score?.series}
          />
          <ChartBarCompare
            chartHight={300}
            title='AIスコア（融資2）'
            yAxisTitle='スコア'
            yUnit={0.0001}
            valueDecimals={2}
            xTickValues={dashboardCompany.chartLoan2Score?.xValues}
            series={dashboardCompany.chartLoan2Score?.series}
          />
          <ChartBarMonthly
            chartHight={300}
            title='資金不足までの残り期間（単位：か月）'
            subTitle='※ 月末残高÷直近6か月の月次平均出金額にて計算。'
            yAxisTitle='か月'
            valueDecimals={0}
            minYvalue={0}
            xTickValues={dashboardCompany.chartRunway?.xValues}
            series={dashboardCompany.chartRunway?.series}
          />
          <ChartLine
            chartHight={300}
            title='残高推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dashboardCompany.chartBalanceTrend?.xValues}
            series={dashboardCompany.chartBalanceTrend?.series}
          />
          <ChartLine
            chartHight={300}
            title='月次入金額の推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dashboardCompany.chartDepositTrend?.xValues}
            series={dashboardCompany.chartDepositTrend?.series}
          />
          <ChartLine
            chartHight={300}
            title='月次出金額の推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dashboardCompany.chartWithdrawalTrend?.xValues}
            series={dashboardCompany.chartWithdrawalTrend?.series}
          />
          <ChartLine
            chartHight={300}
            title='月次損益（月次入金-月次出金）（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dashboardCompany.chartGain?.xValues}
            series={dashboardCompany.chartGain?.series}
          />
        </div>
      </ExpantionPanel>
      <ExpantionPanel title='事業支援に関するインサイト' contentHeight={670} initailExpantion={true}>
        <div className={styles.chartWrapper}>
          <ChartBarCompare
            chartHight={300}
            title='代表者年齢'
            xTickValues={dashboardCompany.chartCeoAge?.xValues}
            yAxisTitle='年齢'
            series={dashboardCompany.chartCeoAge?.series}
          />
          <ChartLine
            chartHight={300}
            title='給与支払額の推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dashboardCompany.chartSalary?.xValues}
            series={dashboardCompany.chartSalary?.series}
          />
          <ChartLine
            chartHight={300}
            title='社会保険料支払額の推移（単位：千円）'
            yAxis1Title='千円(当社)'
            yAxis2Title='千円(同業平均)'
            y1Unit={1000}
            y2Unit={1000}
            valueDecimals={0}
            xTickValues={dashboardCompany.chartInsuranceFee?.xValues}
            series={dashboardCompany.chartInsuranceFee?.series}
          />
          <ChartLine
            chartHight={300}
            title='法人税の支払い比率（%)'
            subTitle='※ 月次損益対比での法人税の支払額の比率'
            yAxis1Title='%'
            valueDecimals={2}
            minYvalue={0}
            xTickValues={dashboardCompany.chartCorporateTax?.xValues}
            series={dashboardCompany.chartCorporateTax?.series}
          />
        </div>
      </ExpantionPanel>
    </div>
  )
}

export const CompanyDetailSideModal = withSideModalPageWrapper(CompanyDetail)
