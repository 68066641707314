import { DatasetTypesEnum } from 'src/type/dataset.type'
import { ProjectTypesEnum } from 'src/type/project.type'

export enum ProjectColorEnum {
  ORANGE = 'orange',
  PURPLE = 'purple',
  BLUE = 'blue',
  PINK = 'pink',
  MINT = 'mint',
}

export const ProjectTypesProperties: Record<
  ProjectTypesEnum,
  { color: ProjectColorEnum; datasetCategories: DatasetTypesEnum[] }
> = {
  [ProjectTypesEnum.CHURN_PREVENTION]: {
    color: ProjectColorEnum.ORANGE,
    datasetCategories: [DatasetTypesEnum.CUSTOMER, DatasetTypesEnum.TRANSACTION],
  },
  [ProjectTypesEnum.CROSS_SELLING]: {
    color: ProjectColorEnum.PURPLE,
    datasetCategories: [DatasetTypesEnum.CUSTOMER, DatasetTypesEnum.TARGET_CUSTOMER, DatasetTypesEnum.TRANSACTION],
  },
  [ProjectTypesEnum.UP_SELLING]: {
    color: ProjectColorEnum.BLUE,
    datasetCategories: [DatasetTypesEnum.CUSTOMER, DatasetTypesEnum.TRANSACTION],
  },
  [ProjectTypesEnum.CORP_LOAN_DEMAND]: {
    color: ProjectColorEnum.PINK,
    datasetCategories: [DatasetTypesEnum.CUSTOMER, DatasetTypesEnum.LOAN1, DatasetTypesEnum.TRANSACTION],
  },
  [ProjectTypesEnum.CORP_SALES_INSIGHTS]: {
    color: ProjectColorEnum.MINT,
    datasetCategories: [
      DatasetTypesEnum.CUSTOMER,
      DatasetTypesEnum.LOAN1,
      DatasetTypesEnum.LOAN2,
      DatasetTypesEnum.TRANSACTION,
    ],
  },
}

export const projectGoalProperties: Record<
  ProjectTypesEnum,
  {
    [key: string]: { widthType: 'sm' | 'md' | 'lg'; prefixUnit?: string; suffixUnit?: string }
  }
> = {
  [ProjectTypesEnum.CHURN_PREVENTION]: {
    targetNumberOfMonths: {
      widthType: 'sm',
    },
    targetNumberOfSamples: {
      widthType: 'lg',
    },
    expectedProfitPerSample: {
      widthType: 'lg',
      prefixUnit: '￥',
    },
    expenses: {
      widthType: 'lg',
      prefixUnit: '￥',
    },
  },
  [ProjectTypesEnum.CROSS_SELLING]: {
    dateRange: {
      widthType: 'md',
    },
    targetNumberOfMonths: {
      widthType: 'sm',
    },
    targetNumberOfSamples: {
      widthType: 'lg',
    },
    expectedProfitPerSample: {
      widthType: 'lg',
      prefixUnit: '￥',
    },
    expenses: {
      widthType: 'lg',
      prefixUnit: '￥',
    },
  },
  [ProjectTypesEnum.UP_SELLING]: {
    targetNumberOfMonths: {
      widthType: 'sm',
    },
    targetNumberOfSamples: {
      widthType: 'lg',
    },
    minBalanceDifference: {
      widthType: 'lg',
      prefixUnit: '￥',
    },
    averageInterest: {
      widthType: 'lg',
      suffixUnit: '%',
    },
    expenses: {
      widthType: 'lg',
      prefixUnit: '￥',
    },
  },
  [ProjectTypesEnum.CORP_LOAN_DEMAND]: {
    targetNumberOfMonths: {
      widthType: 'sm',
    },
    targetNumberOfSamples: {
      widthType: 'lg',
    },
    dateRange: {
      widthType: 'md',
    },
  },
  [ProjectTypesEnum.CORP_SALES_INSIGHTS]: {
    targetNumberOfMonths1: {
      widthType: 'sm',
    },
    targetNumberOfMonths2: {
      widthType: 'sm',
    },
    dateRange: {
      widthType: 'md',
    },
  },
}
