import React from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import IconRadioChecked from 'src/style/icon/icon-radio-checked.svg'
import styles from './radio-button.scss'

type PropsType = {
  checked?: boolean
  disabled?: boolean
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
} & React.InputHTMLAttributes<HTMLInputElement>

export const RadioButton = ({ checked, disabled, onChange, ...props }: PropsType) => {
  function handleLabelClick(e: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
    e.stopPropagation()
  }

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange && onChange(e)
  }

  return (
    <label className={styles.root} onClick={handleLabelClick}>
      <input {...props} type='radio' checked={checked} onChange={handleCheckboxChange} disabled={disabled} />
      <div className={styles.emptyBox} />
      <InlineSVG className={styles.checked} src={IconRadioChecked} />
    </label>
  )
}
