import Slider from 'rc-slider'
import * as React from 'react'
import styles from './range-slider.scss'
import 'rc-slider/assets/index.css'
import './range-slider-custom.css'

type PropsType = {
  values: number[]
  defaultValue?: number[]
  min?: number
  max?: number
  disabled?: boolean
  onChange?: (range: number[]) => void
}

export const RangeSlider = ({ values, defaultValue = [0, 100], min = 0, max = 100, disabled, onChange }: PropsType) => {
  const marks = {
    [values[0]]: {
      label: `${values[0]}`,
    },
    [values[1]]: {
      label: `${values[1]}`,
    },
  }

  function handleChange(range: number | number[]) {
    onChange && onChange(range as number[])
  }

  return (
    <div className={styles.root}>
      <Slider
        range
        marks={marks}
        min={min}
        max={max}
        defaultValue={defaultValue}
        value={values}
        allowCross={false}
        disabled={disabled}
        onChange={handleChange}
      />
    </div>
  )
}
