import { AlertLogType } from 'src/type/notification.type'
import { httpApi } from 'src/util/http.module'

const fetchAlertLogs = () => httpApi.get<AlertLogType[]>({ url: '/alertlog' })

export type UpdateAlertLogParamsType = {
  alertLogIds: number[]
  userCheck: boolean
}
const updateAlertLogs = (params: UpdateAlertLogParamsType) =>
  httpApi.put<AlertLogType[]>({ url: `/alertlog`, body: { ids: params.alertLogIds, userCheck: params.userCheck } })

export const notificationService = {
  fetchAlertLogs,
  updateAlertLogs,
}
