import classNames from 'classnames'
import React, { useMemo } from 'react'
import { OptionType } from 'src/type/common.type'
import { DropdownSingleOptionItem } from './dropdown-single-option-item/dropdown-single-option-item'
import styles from './dropdown-single-options.scss'

type PropsType = {
  options: OptionType[]
  selectedOption?: OptionType
  sizeType?: 'sm' | 'md' | 'lg'
  active?: boolean
  isOpenUpside?: boolean
  isDisselectable?: boolean
  onChange?(option: OptionType | undefined): void
}

export const DropdownSingleOptions = ({
  options,
  selectedOption,
  sizeType,
  active,
  isOpenUpside,
  isDisselectable,
  onChange,
}: PropsType) => {
  const compOptions = useMemo(() => {
    if (isDisselectable) {
      return [{ label: 'disselect', value: 0 }].concat(options)
    }
    return options
  }, [options])

  function handleClick(option: OptionType) {
    onChange && onChange(option.value === 0 ? undefined : option)
  }

  return (
    <ul
      className={classNames(styles.root, {
        [styles.isOpenUpside]: isOpenUpside,
        [styles.active]: active,
        [styles.smSize]: sizeType === 'sm',
        [styles.mdSize]: sizeType === 'md',
        [styles.lgSize]: sizeType === 'lg',
      })}
    >
      {compOptions.map((option) => (
        <DropdownSingleOptionItem
          key={option.value}
          option={option}
          active={selectedOption?.value === option.value}
          sizeType={sizeType}
          onClick={handleClick}
        />
      ))}
    </ul>
  )
}
