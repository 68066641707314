import React, { useEffect, useState } from 'react'
import { Checkbox } from 'src/component/atom/checkbox/checkbox'
import { OptionType } from 'src/type/common.type'
import { ConditionType } from 'src/type/project.type'
import styles from './category-setting.scss'

type PropsType = {
  conditions?: ConditionType[]
  categories: OptionType[]
  onValueChange?(value: ConditionType[]): void
}

export const CategorySetting = ({ conditions, categories, onValueChange }: PropsType) => {
  const [checkedConditions, setCheckedConditions] = useState<{ [key: string]: boolean }>(
    categories.reduce<{ [key: string]: boolean }>((res, option) => {
      res[option.value] = !!conditions?.find((condition) => condition.param === option.value)
      return res
    }, {}),
  )
  const allChecked = React.useMemo(
    () => Object.values(checkedConditions).every((checked) => checked),
    [checkedConditions],
  )

  useEffect(() => {
    handleConditionsChange()
  }, [checkedConditions])

  function handleAllCheckClick(e: React.ChangeEvent<HTMLInputElement>) {
    setCheckedConditions(
      categories.reduce<{ [key: string]: boolean }>((res, option) => {
        res[option.value] = e.currentTarget.checked
        return res
      }, {}),
    )
  }

  function handleConditionsChange() {
    onValueChange &&
      onValueChange(
        Object.entries(checkedConditions)
          .filter(([, checked]) => checked)
          .map(([name]) => ({
            op: 'none',
            param: name,
          })),
      )
  }

  return (
    <div className={styles.root}>
      <label className={styles.conditionItem}>
        <Checkbox checked={allChecked} onChange={handleAllCheckClick} />
        <span className={styles.conditionName}>ALL</span>
      </label>
      {categories &&
        categories.map((category) => (
          <label key={category.value} className={styles.conditionItem}>
            <Checkbox
              value={category.value}
              checked={checkedConditions[category.value]}
              onChange={(e) =>
                setCheckedConditions({ ...checkedConditions, [category.value]: e.currentTarget.checked })
              }
            />
            <span className={styles.conditionName}>{category.label}</span>
          </label>
        ))}
    </div>
  )
}
