import { createSelector } from '@reduxjs/toolkit'
import { ReduxStatusEnum } from 'src/type/common.type'
import { RootStateType } from '../root.reducer'

const userState = (state: RootStateType) => state.userState
const status = createSelector(userState, (state) =>
  state.pendingCount > 0 ? ReduxStatusEnum.PENDING : ReduxStatusEnum.IDLE,
)
const requestedSignUpUsername = createSelector(userState, (state) => state.requestedSignUpUsername)
const user = createSelector(userState, (state) => state.user)
const editSuccess = createSelector(userState, (state) => state.editSuccess)

export const userSelector = {
  status,
  requestedSignUpUsername,
  user,
  editSuccess,
}
