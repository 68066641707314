export const PieChartColors = [
  '#2caffe',
  '#544fc5',
  '#00e272',
  '#fe6a35',
  '#6b8abc',
  '#d568fb',
  '#2ee0ca',
  '#fa4b42',
  '#feb56a',
  '#91e8e1',

  '#1f7ab2',
  '#3b378a',
  '#009e50',
  '#b24a25',
  '#4b6184',
  '#9549b0',
  '#209d8d',
  '#af342e',
  '#b27f4a',
  '#66a29d',

  '#39e4ff',
  '#6d67ff',
  '#00ff94',
  '#ff8a45',
  '#8bb3f4',
  '#ff87ff',
  '#3cffff',
  '#ff6256',
  '#ffeb8a',
  '#bcffff',
]

// default dashboard colors
// ['#2caffe', '#544fc5', '#00e272', '#fe6a35', '#6b8abc', '#d568fb', '#2ee0ca', '#fa4b42', '#feb56a', '#91e8e1']
