import React, { useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { useDispatch, useSelector } from 'react-redux'
import { Tab } from 'src/component/molecule/tab/tab'
import { DownloadDsCompanyModal } from 'src/modal/download-ds-company/download-ds-company.modal'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { dashboardSelector } from 'src/redux/dashboard/dashboard.state'
import IconDownload from 'src/style/icon/icon-download.svg'
import { OptionType } from 'src/type/common.type'
import { ContractTrand } from './contract-trand/contract-trand'
import styles from './industry-insight.scss'
import { IndustryTrand } from './industry-trand/industry-trand'

export const IndustryInsight = () => {
  const dispatch = useDispatch()

  const [selectedItem, setSelectedItem] = useState<OptionType<string>>(tabItems[0])
  const [modalVisiable, setModalVisible] = useState(false)

  const selectedBranchCode = useSelector(dashboardSelector.selectedContractBranchCode)
  const selectedLookbackPeriod = useSelector(dashboardSelector.selectedLookbackPeriod)
  const contractInderstyColor = useSelector(dashboardSelector.contractInderstyColor)

  const selectedIndustry1 = useSelector(dashboardSelector.selectedIndustry1)
  const selectedIndustry2 = useSelector(dashboardSelector.selectedIndustry2)
  const selectedIndustry3 = useSelector(dashboardSelector.selectedIndustry3)
  const selectedCorporateType = useSelector(dashboardSelector.selectedCorporateType)

  function handleDownloadButtonClick() {
    if (selectedItem.value === 'CONTRACT_TRAND') {
      dispatch(
        dashboardAction.downloadDsContractDoc.request({
          branchCode: selectedBranchCode,
          lookbackPeriod: selectedLookbackPeriod,
          colorSet: contractInderstyColor,
        }),
      )
    } else {
      dispatch(
        dashboardAction.downloadDsIndustryDoc.request({
          industry1: selectedIndustry1,
          industry2: selectedIndustry2,
          industry3: selectedIndustry3,
          corporateType: selectedCorporateType,
        }),
      )
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.tabWrapper}>
        <Tab items={tabItems} activeItem={selectedItem} onChange={setSelectedItem} />
        <div className={styles.downloadButton} onClick={handleDownloadButtonClick}>
          <span>{`ダウンロード`}</span>
          <div className={styles.downloadIconWrapper}>
            <InlineSVG className={styles.downloadIcon} src={IconDownload} />
          </div>
        </div>
      </div>
      {selectedItem.value === 'CONTRACT_TRAND' ? (
        <ContractTrand key={selectedItem.value} />
      ) : (
        <IndustryTrand key={selectedItem.value} />
      )}
      <DownloadDsCompanyModal modalVisible={modalVisiable} onClose={() => setModalVisible(false)} />
    </div>
  )
}

const tabItems: OptionType<string>[] = [
  {
    label: '新規契約トレンド',
    value: 'CONTRACT_TRAND',
  },
  {
    label: '業種トレンド',
    value: 'INDUSTRY_TRAND',
  },
]
