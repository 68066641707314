import classNames from 'classnames'
import React, { useMemo } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Lottie } from 'src/component/atom/lottie/lottie'
import { NewProjectCard } from 'src/component/molecule/new-project-card/new-project-card'
import { ProjectGrid } from 'src/component/molecule/project-grid/project-grid'
import { Header } from 'src/component/organism/header/header'
import { ProjectTypesIntl } from 'src/intl/data/project.key'
import { AddProjectModal } from 'src/modal/add-project/add-project.modal'
import { notificationAction } from 'src/redux/notification/notification.action'
import { notificationSelector } from 'src/redux/notification/notification.state'
import { projectSelector } from 'src/redux/project/project.state'
import { softwareInfoSelector } from 'src/redux/software-info/software-info.state'
import { userSelector } from 'src/redux/user/user.state'
import IconDoc from 'src/style/icon/icon-doc.svg'
import homeCatData from 'src/style/lottie/home-cat.json'
import { ProjectTypesEnum } from 'src/type/project.type'
import { ProjectTypesProperties } from 'src/ui-variable/project.ui'
import styles from './home.scss'

export const HomePage = () => {
  const dispatch = useDispatch()

  const availableTopics = useSelector(softwareInfoSelector.availableTopics)
  const user = useSelector(userSelector.user)
  const projects = useSelector(projectSelector.projects)
  const alertLogs = useSelector(notificationSelector.alertLogs)
  const notReadedLogIds = useSelector(notificationSelector.notReadedLogIds)

  const projectsLatest = useMemo(() => {
    return [...projects].sort((projA, projB) => (projA.createdAt > projB.createdAt ? -1 : 1)).slice(0, 5)
  }, [projects])

  const [newProjectType, setNewProjectType] = React.useState<ProjectTypesEnum>(ProjectTypesEnum.CHURN_PREVENTION)
  const [modalVisible, setModalVisible] = React.useState(false)

  function handleOpenNotification() {
    if (notReadedLogIds.length > 0) {
      dispatch(notificationAction.updateAlertLog.request())
    }
  }

  function handleClickNewProjectCard(type: ProjectTypesEnum) {
    setNewProjectType(type)
    if ((availableTopics && availableTopics.includes(type)) || !availableTopics) {
      setModalVisible(true)
    } else {
      const url = `https://www.readyai.ailys.ai/plan-pricing`
      window.open(url, '_blank')
    }
  }

  return (
    <div className={styles.root}>
      {user && (
        <Header user={user} alertLogs={alertLogs} onOpenNotification={handleOpenNotification}>
          <Lottie className={styles.image} animationData={homeCatData} />
          <div className={styles.headerText}>
            <div className={styles.title}>{`こんにちは ${user.userNickname} !`}</div>
            <span className={styles.description}>{`いつもご利用ありがとうございます。`}</span>
          </div>
        </Header>
      )}
      <div className={styles.sectionTitle}>{`新たなプロジェクトをスタート`}</div>
      <section className={styles.cardSection}>
        {availableTopics?.map((type) => (
          <NewProjectCard
            key={type}
            name={ProjectTypesIntl[type].name}
            description={ProjectTypesIntl[type].description}
            color={ProjectTypesProperties[type].color}
            buttonState={(availableTopics && availableTopics.includes(type)) || !availableTopics}
            onClick={() => {
              handleClickNewProjectCard(type)
            }}
          />
        ))}
      </section>
      <div className={classNames(styles.sectionTitle, styles.projectListSectionTitle)}>
        <span>{`最近のプロジェクト`}</span>
        <Link className={styles.projectListButton} to='/projects'>
          <span>{`プロジェクト一覧`}</span>
          <div className={styles.projectListIconWrapper}>
            <InlineSVG className={styles.projectListIcon} src={IconDoc} />
          </div>
        </Link>
      </div>
      <section className={styles.projectSection}>
        <ProjectGrid projects={projectsLatest} gridType='summary' availableTopics={availableTopics} />
      </section>
      <AddProjectModal
        modalVisible={modalVisible}
        projectType={newProjectType}
        onClose={() => setModalVisible(false)}
      />
    </div>
  )
}
