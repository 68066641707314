import { Action } from 'redux'
import { combineEpics, Epic, StateObservable } from 'redux-observable'
import { catchError, delay, EMPTY, filter, fromEvent, mergeMap, Observable, of, switchMap, takeUntil } from 'rxjs'
import { toastTool } from 'src/tool/toast.tool'
import { ProjectStepEnum } from 'src/type/project.type'
import { TaskErrorType, TaskProgressType, TASKS, TaskSuccessType } from 'src/type/task.type'
import { socketIo } from 'src/util/socket.module'
import { datasetAction } from '../dataset/dataset.action'
import { datasetSelector } from '../dataset/dataset.state'
import { notificationAction } from '../notification/notification.action'
import { projectAction } from '../project/project.action'
import { projectSelector } from '../project/project.state'
import { httpErrorhandling } from '../redux.util'
import { RootStateType } from '../root.reducer'
import { taskAction } from './task.action'
import { taskService } from './task.service'

const failureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) => [taskAction.fetchTasks.failure.type].includes(action.type)),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const fetchTasksEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(taskAction.fetchTasks.request.match),
    switchMap(() =>
      taskService.fetchTasks().pipe(
        mergeMap(({ response }) => [taskAction.fetchTasks.success(), taskAction.setTasks(response)]),
        catchError((err) => of(taskAction.fetchTasks.failure(err))),
      ),
    ),
  )

const taskSuccessEpic: Epic = (actions$: Observable<Action>, state$: StateObservable<RootStateType>) =>
  actions$.pipe(
    filter(taskAction.eventConnectionOpen.match),
    mergeMap(({ payload }) =>
      fromEvent<TaskSuccessType>(socketIo.connectedSocket(payload), 'success').pipe(
        mergeMap((data) =>
          of(projectSelector.selectedProjectId(state$.value)).pipe(
            mergeMap((selectedProjectId) =>
              of(datasetSelector.datasetObjs(state$.value)).pipe(
                mergeMap((datasetObjs) => {
                  const returnActions: Action[] = [
                    taskAction.setTaskSuccess(data),
                    notificationAction.fetchAlertLogs.request(),
                  ]

                  if (selectedProjectId) {
                    switch (data.name) {
                      case TASKS.PREPROCESS_DATA:
                        if (data.instanceId === selectedProjectId) {
                          returnActions.push(
                            datasetAction.fetchDatasetsInProgress.request({ projectId: selectedProjectId }),
                          )
                        }
                        break
                      case TASKS.VALIDATE_DATA:
                      case TASKS.DESCRIBE_DATA:
                        if (
                          datasetObjs[data.instanceId] &&
                          datasetObjs[data.instanceId].project.id === selectedProjectId
                        ) {
                          returnActions.push(
                            datasetAction.fetchDatasetInProgress.request({ datasetId: data.instanceId }),
                          )
                        }
                        break
                      case TASKS.CREATE_EXPERIMENT_PATH:
                      case TASKS.TRAIN:
                      case TASKS.TEST:
                        break
                      case TASKS.CREATE_SEGMENTS:
                        returnActions.push(projectAction.fetchProjectsInProgress.request())
                        if (data.instanceId === selectedProjectId) {
                          returnActions.push(
                            projectAction.fetchProjectDetailInProgress.request({ id: selectedProjectId }),
                          )
                          returnActions.push(
                            projectAction.fetchProjectResultTargetsInProgress.request({ id: selectedProjectId }),
                          )
                          returnActions.push(
                            projectAction.fetchProjectResultSegments.request({ id: selectedProjectId }),
                          )
                        }
                        break
                      case TASKS.PREPARE_DASHBOARD:
                        returnActions.push(projectAction.fetchProjectsInProgress.request())
                        if (data.instanceId === selectedProjectId) {
                          returnActions.push(
                            projectAction.fetchProjectDetailInProgress.request({ id: selectedProjectId }),
                          )
                        }
                        break
                      default:
                    }
                  }
                  return returnActions
                }),
                takeUntil(actions$.pipe(filter(taskAction.eventConnectionClose.match))),
                catchError((err) => {
                  toastTool.errorString(err)
                  return EMPTY
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  )

const taskSuccessSelfGarbageCollectEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(taskAction.eventConnectionOpen.match),
    mergeMap(({ payload }) =>
      fromEvent<TaskSuccessType>(socketIo.connectedSocket(payload), 'success').pipe(
        delay(10000),
        mergeMap((data) => of(taskAction.removeTaskSuccess(data))),
        takeUntil(actions$.pipe(filter(taskAction.eventConnectionClose.match))),
        catchError((err) => {
          toastTool.errorString(err)
          return EMPTY
        }),
      ),
    ),
  )

const taskProgressEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(taskAction.eventConnectionOpen.match),
    mergeMap(({ payload }) =>
      fromEvent<TaskProgressType[]>(socketIo.connectedSocket(payload), 'progress').pipe(
        mergeMap((data) => [taskAction.setTaskProgress(data)]),
        takeUntil(actions$.pipe(filter(taskAction.eventConnectionClose.match))),
        catchError((err) => {
          toastTool.errorString(err)
          return EMPTY
        }),
      ),
    ),
  )

const taskErrorEpic: Epic = (actions$: Observable<Action>, state$: StateObservable<RootStateType>) =>
  actions$.pipe(
    filter(taskAction.eventConnectionOpen.match),
    mergeMap(({ payload }) =>
      fromEvent<TaskErrorType>(socketIo.connectedSocket(payload), 'error').pipe(
        mergeMap((data) =>
          of(projectSelector.selectedProjectId(state$.value)).pipe(
            mergeMap((selectedProjectId) =>
              of(projectSelector.selectedProjectStep(state$.value)).pipe(
                mergeMap((selectedProjectStep) =>
                  of(datasetSelector.datasetObjs(state$.value)).pipe(
                    mergeMap((datasetObjs) => {
                      toastTool.errorTemplate(data.error.code, data.error.message, data.error.info)

                      const returnActions: Action[] = [
                        taskAction.setTaskError(data),
                        notificationAction.fetchAlertLogs.request(),
                      ]

                      if (
                        ([
                          TASKS.EXTRACT_DATA,
                          TASKS.VALIDATE_DATA,
                          TASKS.DESCRIBE_DATA,
                          TASKS.CREATE_EXPERIMENT_PATH,
                        ].includes(data.name) &&
                          datasetObjs[data.instanceId] &&
                          datasetObjs[data.instanceId].project.id === selectedProjectId) ||
                        (![
                          TASKS.EXTRACT_DATA,
                          TASKS.VALIDATE_DATA,
                          TASKS.DESCRIBE_DATA,
                          TASKS.CREATE_EXPERIMENT_PATH,
                        ].includes(data.name) &&
                          data.instanceId === selectedProjectId)
                      ) {
                        returnActions.push(projectAction.fetchProjectsInProgress.request())
                        if (selectedProjectStep === ProjectStepEnum.TRAINNING) {
                          returnActions.push(projectAction.fetchProjectDetail.request({ id: selectedProjectId }))
                        }
                        returnActions.push(
                          datasetAction.fetchDatasetsInProgress.request({ projectId: selectedProjectId }),
                        )
                      }

                      return returnActions
                    }),
                    takeUntil(actions$.pipe(filter(taskAction.eventConnectionClose.match))),
                    catchError((err) => {
                      toastTool.errorString(err)
                      return EMPTY
                    }),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  )

const taskDisconnectEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(taskAction.eventConnectionClose.match),
    mergeMap(() => {
      socketIo.disconnectSocket()
      return EMPTY
    }),
  )

export const taskEpic = combineEpics(
  failureEpic,
  fetchTasksEpic,
  taskSuccessEpic,
  taskSuccessSelfGarbageCollectEpic,
  taskProgressEpic,
  taskErrorEpic,
  taskDisconnectEpic,
)
