import { Action } from 'redux'
import { Epic, StateObservable, combineEpics } from 'redux-observable'
import { filter, switchMap, mergeMap, catchError, of, Observable, forkJoin } from 'rxjs'
import { httpErrorhandling } from '../redux.util'
import { RootStateType } from '../root.reducer'
import { notificationAction } from './notification.action'
import { notificationService } from './notification.service'
import { notificationSelector } from './notification.state'

const failureEpic: Epic = (actions$: Observable<{ type: string; payload: Error }>) =>
  actions$.pipe(
    filter((action) =>
      [notificationAction.fetchAlertLogs.failure.type, notificationAction.updateAlertLog.failure.type].includes(
        action.type,
      ),
    ),
    mergeMap(({ payload }) => httpErrorhandling(payload)),
  )

const fetcAlertLogsEpic: Epic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(notificationAction.fetchAlertLogs.request.match),
    switchMap(() =>
      notificationService.fetchAlertLogs().pipe(
        mergeMap(({ response }) => [
          notificationAction.fetchAlertLogs.success(),
          notificationAction.setAlertLogs(response),
        ]),
        catchError((err) => of(notificationAction.fetchAlertLogs.failure(err))),
      ),
    ),
  )

const updateAlertLogsEpic: Epic = (actions$: Observable<Action>, state$: StateObservable<RootStateType>) =>
  actions$.pipe(
    filter(notificationAction.updateAlertLog.request.match),
    switchMap(() =>
      of(notificationSelector.notReadedLogIds(state$.value)).pipe(
        filter((ids) => ids.length > 0),
        switchMap((ids) =>
          notificationService.updateAlertLogs({ alertLogIds: ids, userCheck: true }).pipe(
            mergeMap(() => [notificationAction.updateAlertLog.success(), notificationAction.fetchAlertLogs.request()]),
            catchError((err) => of(notificationAction.updateAlertLog.failure(err))),
          ),
        ),
      ),
    ),
  )

export const notificationEpic = combineEpics(failureEpic, fetcAlertLogsEpic, updateAlertLogsEpic)
