import { BlueprintMappingDetailType, BlueprintType, UpdatedFieldMappingReturnType } from 'src/type/blueprint.type'
import { CommonIdType } from 'src/type/common.type'
import { ProjectTypesEnum } from 'src/type/project.type'
import { httpApi } from 'src/util/http.module'

export type FetchBlueprintParamsType = {
  projectType: ProjectTypesEnum
}
const fetchBlueprint = (params: FetchBlueprintParamsType) =>
  httpApi.get<BlueprintType[]>({ url: '/blueprint', queryParams: params })

export type FetchBlueprintMappingParamsType = {
  projectId: number
}
const fetchBlueprintMapping = (params: FetchBlueprintMappingParamsType) =>
  httpApi.get<BlueprintMappingDetailType[]>({ url: '/blueprintmappings', queryParams: params })

export type UpdateFieldMappingParamsType = {
  projectId: number
  bluePrintId: number
  fieldIds: CommonIdType[]
  value?: number
  values?: string[]
}
const updateFieldMapping = (params: UpdateFieldMappingParamsType) =>
  httpApi.post<UpdatedFieldMappingReturnType>({
    url: `/project/${params.projectId}/blueprint/${params.bluePrintId}`,
    body: { fields: params.fieldIds, value: params.value, values: params.values },
  })

export type DeleteFieldMappingParamsType = {
  projectId: number
  bluePrintId: number
}
const deleteFieldMapping = (params: DeleteFieldMappingParamsType) =>
  httpApi.delete<UpdatedFieldMappingReturnType>({
    url: `/project/${params.projectId}/blueprint/${params.bluePrintId}`,
  })

export const blueprintService = {
  fetchBlueprint,
  fetchBlueprintMapping,
  updateFieldMapping,
  deleteFieldMapping,
}
