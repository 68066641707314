import React, { useState } from 'react'
import { Button } from 'src/component/atom/button/button'
import { SegmentVariable } from 'src/component/molecule/segment-variable/segment-variable'
import { FieldTypesEnum } from 'src/type/dataset.type'
import { ResultSegmentFieldsType, SegmentType } from 'src/type/project.type'
import styles from './segment.scss'
import { VariableSetting } from './variable-setting/variable-setting'

type FieldType = { type: FieldTypesEnum; name: string }

type PropsType = {
  datasetFields: FieldType[]
  segment?: SegmentType
  onClose(): void
}

export const Segment = ({ datasetFields, segment, onClose }: PropsType) => {
  const [newVariable, setNewVariable] = useState<ResultSegmentFieldsType | null>(null)
  const [variables, setVariables] = useState<{ [name: string]: ResultSegmentFieldsType }>(
    segment
      ? segment.resultSegmentFields.reduce<{ [name: string]: ResultSegmentFieldsType }>((res, variable) => {
          res[variable.name] = variable
          return res
        }, {})
      : {},
  )

  function handleVariableChange(activeVariable: ResultSegmentFieldsType) {
    if (variables[activeVariable.name]) {
      setNewVariable(null)
    } else {
      setNewVariable(activeVariable)
    }
    if (activeVariable.conditions && activeVariable.conditions.length > 0) {
      setNewVariable(null)
      setVariables({ ...variables, [activeVariable.name]: activeVariable })
    }
  }

  function handleDeleteClick(variable: ResultSegmentFieldsType) {
    delete variables[variable.name]
    setVariables({ ...variables })
  }

  function handleCreateClick() {
    console.log(variables)
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>{`データ項目`}</div>
      <VariableSetting
        datasetFields={datasetFields}
        selectedVariables={variables}
        onVariableChange={handleVariableChange}
      />
      <div className={styles.clusterList}>
        {Object.values(variables).map((variable) => (
          <SegmentVariable
            key={variable.name}
            variable={variable}
            isActionAvailable
            onDeleteClick={handleDeleteClick}
          />
        ))}
        {newVariable && <SegmentVariable variable={{ type: newVariable.type, name: newVariable.name }} />}
      </div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleCreateClick}>
          {segment ? `修正` : `生成`}
        </Button>
      </div>
    </div>
  )
}
