import React, { useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { Tab } from 'src/component/molecule/tab/tab'
import { DownloadDsCompanyModal } from 'src/modal/download-ds-company/download-ds-company.modal'
import IconDownload from 'src/style/icon/icon-download.svg'
import { OptionType } from 'src/type/common.type'
import { SalesInsightInfo } from './sales-insight-info/sales-insight-info'
import styles from './sales-insight.scss'

export const SalesInsight = () => {
  const [selectedItem, setSelectedItem] = useState<OptionType<string>>(tabItems[0])
  const [modalVisiable, setModalVisible] = useState(false)

  function handleDownloadButtonClick() {
    setModalVisible(true)
  }

  return (
    <div className={styles.root}>
      <div className={styles.tabWrapper}>
        <Tab items={tabItems} activeItem={selectedItem} onChange={setSelectedItem} />
        <div className={styles.downloadButton} onClick={handleDownloadButtonClick}>
          <span>{`企業別レポートダウンロード`}</span>
          <div className={styles.downloadIconWrapper}>
            <InlineSVG className={styles.downloadIcon} src={IconDownload} />
          </div>
        </div>
      </div>
      <SalesInsightInfo />
      <DownloadDsCompanyModal modalVisible={modalVisiable} onClose={() => setModalVisible(false)} />
    </div>
  )
}

const tabItems: OptionType<string>[] = [
  {
    label: '営業インサイト',
    value: 'SALES_INSIGHT_TAB',
  },
]
