import { ErrorType } from './error.type'

export enum TASKS {
  EXTRACT_DATA = 'EXTRACT_DATA',
  VALIDATE_DATA = 'VALIDATE_DATA',
  DESCRIBE_DATA = 'DESCRIBE_DATA',
  PREPROCESS_DATA = 'PREPROCESS_DATA',
  TRAIN = 'TRAIN',
  CREATE_EXPERIMENT_PATH = 'CREATE_EXPERIMENT_PATH',
  TEST = 'TEST',
  CREATE_SEGMENTS = 'CREATE_SEGMENTS',
  PREPARE_DASHBOARD = 'PREPARE_DASHBOARD',
}

export enum TASK_STATUS {
  PROGRESS = 'PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type TaskProgressType = {
  id: string
  userId: number
  instanceId: number
  taskId: string
  name: TASKS
  status: TASK_STATUS
  startedAt: number
  progress: {
    total: number
    current: number
  }
}

export type TaskSuccessType<T = any> = TaskProgressType & {
  endedAt: number
  elapsed: number
  result: T
}

export type TaskErrorType = TaskProgressType & {
  endedAt: number
  elapsed: number
  error: ErrorType
}
