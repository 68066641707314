import classNames from 'classnames'
import React from 'react'
import styles from './progress-bar.scss'

type PropsType = {
  progress: number
  sizeType?: 'sm' | 'md'
  progressColor?: 'primary' | 'secondary'
  outlined?: boolean
  isRounded?: boolean
}

export const ProgressBar = ({ progress, sizeType = 'md', progressColor, outlined, isRounded }: PropsType) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.smSize]: sizeType === 'sm',
        [styles.mdSize]: sizeType === 'md',
        [styles.outlined]: outlined,
        [styles.rounded]: isRounded,
      })}
    >
      <div
        className={classNames(styles.progress, {
          [styles.primary]: progressColor === 'primary',
          [styles.secondary]: progressColor === 'secondary',
          [styles.rounded]: isRounded,
        })}
        style={{ width: `${progress}%` }}
      />
    </div>
  )
}
