import classNames from 'classnames'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { Checkbox } from 'src/component/atom/checkbox/checkbox'
import { OptionType } from 'src/type/common.type'
import styles from './dropdown-multi-options.scss'

type PropsType = {
  options: OptionType[]
  selectedOption?: OptionType[]
  sizeType?: 'sm' | 'md' | 'lg'
  filterString?: string
  active?: boolean
  isOpenUpside?: boolean
  onChange?(options: OptionType[]): void
}

export const DropdownMultiOptions = ({
  options,
  selectedOption,
  sizeType,
  filterString,
  active,
  isOpenUpside,
  onChange,
}: PropsType) => {
  const optionObjs = useMemo(
    () =>
      options.reduce<{ [key: string]: OptionType }>((res, option) => {
        res[option.value] = option
        return res
      }, {}),
    [options],
  )

  const filteredOptions = useMemo(() => {
    const lowerCaseFilterString = filterString ? filterString.toLowerCase() : ''

    return options.filter((option) => option.label.toLowerCase().includes(lowerCaseFilterString))
  }, [options, filterString])

  const [checkedOptions, setCheckedOptions] = useState<{ [key: string]: boolean }>(
    options.reduce<{ [key: string]: boolean }>((res, option) => {
      res[option.value] = !!selectedOption?.find((selectedOption) => selectedOption.value === option.value)
      return res
    }, {}),
  )

  function handleCheckboxChecked(e: ChangeEvent<HTMLInputElement>) {
    const newCheckedState = { ...checkedOptions, [e.currentTarget.value]: e.currentTarget.checked }

    setCheckedOptions({ ...checkedOptions, [e.currentTarget.value]: e.currentTarget.checked })

    onChange &&
      onChange(
        Object.entries(newCheckedState)
          .filter(([, checked]) => checked)
          .map(([value]) => optionObjs[value]),
      )
  }

  return (
    <ul
      className={classNames(styles.root, {
        [styles.isOpenUpside]: isOpenUpside,
        [styles.active]: active,
        [styles.smSize]: sizeType === 'sm',
        [styles.mdSize]: sizeType === 'md',
        [styles.lgSize]: sizeType === 'lg',
      })}
    >
      {filteredOptions.map((option) => (
        <li key={option.value} className={styles.option}>
          <label className={styles.label}>
            <Checkbox value={option.value} checked={checkedOptions[option.value]} onChange={handleCheckboxChecked} />
            {option.label}
          </label>
        </li>
      ))}
    </ul>
  )
}
