import classNames from 'classnames'
import React from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { Button } from 'src/component/atom/button/button'
import iconArrow from 'src/style/icon/icon-arrow.svg'
import iconPlus from 'src/style/icon/icon-plus.svg'
import { ProjectColorEnum } from 'src/ui-variable/project.ui'
import styles from './new-project-card.scss'

type PropsType = {
  color?: ProjectColorEnum
  name: string
  description: string
  buttonState: boolean
  onClick?(): void
}

export const NewProjectCard = ({ color, name, description, buttonState, onClick }: PropsType) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.orangeColorSet]: color === ProjectColorEnum.ORANGE,
        [styles.purpleColorSet]: color === ProjectColorEnum.PURPLE,
        [styles.blueColorSet]: color === ProjectColorEnum.BLUE,
        [styles.pinkColorSet]: color === ProjectColorEnum.PINK,
        [styles.mintColorSet]: color === ProjectColorEnum.MINT,
        [styles.blackColorSet]: !buttonState,
      })}
    >
      <div className={styles.title}>{name}</div>
      <div className={styles.description}>{description}</div>
      <Button onClick={onClick} buttonColor={buttonState ? color : 'black'} sizeType='sm' isRounded>
        <div className={styles.innerButton}>
          {buttonState ? `プロジェクト` : `Get now`}
          <InlineSVG src={buttonState ? iconPlus : iconArrow} className={styles.icon} />
        </div>
      </Button>
    </div>
  )
}
