import React, { memo } from 'react'
import { Modal } from 'src/component/organism/modal/modal'

type ModalWrapperPropsType = {
  onClose(): void
  modalVisible: boolean
  modalAlignV?: 'top' | 'bottom' | 'center'
  modalAlignH?: 'left' | 'right' | 'center'
}

export const withModalWrapper = <T,>(Component: React.ComponentType<T>, title?: string) => {
  const WrappedComp = (props: T & ModalWrapperPropsType) => (
    <>
      {props.modalVisible && (
        <Modal title={title} modalAlignV={props.modalAlignV} modalAlignH={props.modalAlignH} onClose={props.onClose}>
          <Component {...props} />
        </Modal>
      )}
    </>
  )

  return memo(WrappedComp, (prevProps, nextProps) => prevProps.modalVisible === nextProps.modalVisible)
}
