import { useEffect, useRef, useState } from 'react'

export function useVisibleWithOutsideClick<T extends HTMLElement>(initialVisible: boolean) {
  const ref = useRef<T>(null)
  const [isVisible, setIsVisible] = useState(initialVisible)

  useEffect(() => {
    const handleDocumentMouseDown = (e: MouseEvent) => {
      if (ref.current && e.target instanceof Node && !ref.current.contains(e.target)) setIsVisible(false)
    }
    const handleEscKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') setIsVisible(false)
    }

    document.addEventListener('mousedown', handleDocumentMouseDown)
    document.addEventListener('keydown', handleEscKeyDown)
    return () => {
      document.removeEventListener('mousedown', handleDocumentMouseDown)
      document.removeEventListener('keydown', handleEscKeyDown)
    }
  }, [ref])

  return { ref, isVisible, setIsVisible }
}

// reference: https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=/src/OutsideAlerter.js&file=/src/OutsideAlerter.js:277-295
// referance: https://stackoverflow.com/questions/54560790/detect-click-outside-react-component-using-hooks
