import { createSelector } from '@reduxjs/toolkit'
import { ReduxStatusEnum } from 'src/type/common.type'
import { RootStateType } from '../root.reducer'

const blueprintState = (state: RootStateType) => state.blueprintState
const status = createSelector(blueprintState, (state) =>
  state.pendingCount > 0 ? ReduxStatusEnum.PENDING : ReduxStatusEnum.IDLE,
)
const blueprints = createSelector(blueprintState, (state) => state.blueprints)
const blueprintMappings = createSelector(blueprintState, (state) => state.blueprintMappings)

export const blueprintSelector = {
  status,
  blueprints,
  blueprintMappings,
}
