import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { Button } from 'src/component/atom/button/button'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { blueprintIntl } from 'src/intl/data/blueprint.key'
import { MappedOptionValueType } from 'src/type/blueprint.type'
import { OptionType } from 'src/type/common.type'
import { DatasetTypesEnum } from 'src/type/dataset.type'
import { ProjectTypesEnum } from 'src/type/project.type'
import styles from './key-map.scss'
import { Mapping } from './mapping/mapping.panel'

export type MappedKeysType = { firstKeyMap: OptionType[]; secondKeyMap?: OptionType[] }

export type DatasetFieldOptionsType = { type: DatasetTypesEnum; title: string; fields: OptionType[] }

type PropsType = {
  projectType: ProjectTypesEnum
  datasetFieldOptions: DatasetFieldOptionsType[]
  keysMappedOptionValues?: MappedOptionValueType[]
  mappedField?: { [fieldId: number]: number }
  onConfirm?(mappedKeys: MappedKeysType): void
  onClose(): void
}

export const KeyMapModal = withModalWrapper(
  ({ projectType, datasetFieldOptions, keysMappedOptionValues = [], mappedField, onConfirm, onClose }: PropsType) => {
    const [useSecondaryKey, setUseSecondaryKey] = useState(keysMappedOptionValues && !!keysMappedOptionValues[1])
    const [firstKeyMap, setFirstKeyMap] = useState<OptionType[]>(
      keysMappedOptionValues.length > 0 ? keysMappedOptionValues[0].selectedFieldOptions : [],
    )
    const [secondKeyMap, setSecondKeyMap] = useState<OptionType[] | undefined>(
      keysMappedOptionValues.length > 1 ? keysMappedOptionValues[1].selectedFieldOptions : [],
    )

    const [datasetTitles, datasetFieldsList] = useMemo(() => {
      const titles = datasetFieldOptions.map((fields) => fields.title)
      const fields = datasetFieldOptions.map((fields) => fields.fields)

      return [titles, fields]
    }, [datasetFieldOptions])

    const firstKeyDatasetFieldsList = useMemo(() => {
      return datasetFieldsList.map((fields, idx) =>
        fields
          .filter(
            (field) =>
              mappedField &&
              (!mappedField[field.value] ||
                mappedField[field.value] === keysMappedOptionValues[0]?.bluePrint.id ||
                (mappedField[field.value] === keysMappedOptionValues[1]?.bluePrint.id &&
                  field.value !== (secondKeyMap && secondKeyMap[idx])?.value)),
          )
          .filter((field) => field.value !== (secondKeyMap && secondKeyMap[idx])?.value),
      )
    }, [secondKeyMap, mappedField])

    const secondKeyDatasetFieldsList = useMemo(() => {
      return datasetFieldsList.map((fields, idx) =>
        fields
          .filter(
            (field) =>
              mappedField &&
              (!mappedField[field.value] ||
                mappedField[field.value] === keysMappedOptionValues[1]?.bluePrint.id ||
                (mappedField[field.value] === keysMappedOptionValues[0]?.bluePrint.id &&
                  field.value !== firstKeyMap[idx]?.value)),
          )
          .filter((field) => field.value !== firstKeyMap[idx]?.value),
      )
    }, [firstKeyMap, mappedField])

    useEffect(() => {
      !useSecondaryKey && setSecondKeyMap(undefined)
    }, [useSecondaryKey])

    function handleAddSecondKeyClick() {
      setUseSecondaryKey(!useSecondaryKey)
    }

    function handleConfirmClick() {
      if (
        firstKeyMap.length === datasetFieldOptions.length &&
        secondKeyMap &&
        secondKeyMap.length === datasetFieldOptions.length
      ) {
        onConfirm && onConfirm({ firstKeyMap, secondKeyMap })
      } else if (firstKeyMap.length === datasetFieldOptions.length) {
        onConfirm && onConfirm({ firstKeyMap, secondKeyMap: undefined })
      }

      onClose()
    }

    return (
      <>
        <div className={styles.body}>
          <div className={classNames(styles.fieldInfo)}>
            {datasetTitles.map((title) => (
              <div key={title} className={styles.title}>
                {title} <span className={styles.required}>*</span>
              </div>
            ))}
          </div>
          <Mapping
            datasetFieldsList={firstKeyDatasetFieldsList}
            selectedFieldList={firstKeyMap}
            onChange={(keys) => setFirstKeyMap(keys)}
          />
          <div className={styles.separator}>
            <div className={styles.legend}>
              <label>{`任意項目`}</label>
            </div>
          </div>
          <div className={styles.optionalCtrl}>
            {blueprintIntl[projectType].secondaryKey.name}
            <Button
              sizeType='sm'
              outlined
              onClick={handleAddSecondKeyClick}
              disabled={firstKeyMap.length < datasetTitles.length}
            >
              {useSecondaryKey ? '閉じる' : '追加'}
            </Button>
          </div>
          {useSecondaryKey && (
            <Mapping
              datasetFieldsList={secondKeyDatasetFieldsList}
              selectedFieldList={secondKeyMap}
              onChange={(keys) => setSecondKeyMap(keys)}
            />
          )}
        </div>
        <div className={styles.footer}>
          <Button sizeType='md' outlined onClick={onClose}>
            {`キャンセル`}
          </Button>
          <Button sizeType='md' onClick={handleConfirmClick}>
            {`確定`}
          </Button>
        </div>
      </>
    )
  },
  'Key 変数のマッピング',
)
