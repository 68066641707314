import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'src/component/organism/spinner/spinner'
import { spinnerSelector } from 'src/redux/spinner/spinner.state'

export const SpinnerLoader = () => {
  const [delayedVisible, setDeleyedVisible] = useState(false)

  const visible = useSelector(spinnerSelector.visible)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDeleyedVisible(visible)
    }, 50)

    return () => {
      clearTimeout(timer)
    }
  }, [visible])

  return <Spinner visible={delayedVisible} />
}
