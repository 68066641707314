import { createAction, createReducer } from '@reduxjs/toolkit'
import { BlueprintMappingDetailType, BlueprintType } from 'src/type/blueprint.type'
import { createAsyncAction } from '../redux.type'
import {
  DeleteFieldMappingParamsType,
  FetchBlueprintMappingParamsType,
  FetchBlueprintParamsType,
  UpdateFieldMappingParamsType,
} from './blueprint.service'

type BlueprintStateType = {
  pendingCount: number
  blueprints: BlueprintType[] | null
  blueprintMappings: BlueprintMappingDetailType[] | null
}

const init = createAction('[blueprint] init')
const increasePendingCount = createAction('[blueprint] increase async action pending count')
const decreasePendingCount = createAction('[blueprint] dencrease async action pending count')
const fetchBlueprint = createAsyncAction<FetchBlueprintParamsType>('[blueprint] fetch selected project blueprint')
const setBlueprint = createAction<BlueprintType[]>('[blueprint] set selected project blueprint')

const fetchBlueprintMapping = createAsyncAction<FetchBlueprintMappingParamsType>('[blueprint] fetch blueprint mapping')
const setBlueprintMapping = createAction<BlueprintMappingDetailType[]>('[blueprint] set blueprint mapping')
const updateFieldMapping = createAsyncAction<UpdateFieldMappingParamsType>('[blueprint] update field mapping')
const deleteFieldMapping = createAsyncAction<DeleteFieldMappingParamsType>('[blueprint] delete field mapping')

export const blueprintAction = {
  init,
  increasePendingCount,
  decreasePendingCount,
  fetchBlueprint,
  setBlueprint,
  fetchBlueprintMapping,
  setBlueprintMapping,
  updateFieldMapping,
  deleteFieldMapping,
}

const initBlueprintState: BlueprintStateType = {
  pendingCount: 0,
  blueprints: null,
  blueprintMappings: null,
}

export const blueprintReducer = createReducer(initBlueprintState, (builder) =>
  builder
    .addCase(init, () => initBlueprintState)
    .addCase(increasePendingCount, (state) => {
      state.pendingCount++
    })
    .addCase(decreasePendingCount, (state) => {
      state.pendingCount--
    })
    .addCase(setBlueprint, (state, { payload }) => {
      state.blueprints = payload
    })
    .addCase(setBlueprintMapping, (state, { payload }) => {
      state.blueprintMappings = payload
    }),
)
