import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { Button } from 'src/component/atom/button/button'
import { Input } from 'src/component/atom/input/input'
import iconCircleCheck from 'src/style/icon/icon-circle-check.svg'
import iconSearch from 'src/style/icon/icon-search.svg'
import { FieldTypesEnum } from 'src/type/dataset.type'
import { ResultSegmentFieldsType } from 'src/type/project.type'
import { CategorySetting } from './category-setting/category-setting'
import { NumberSetting } from './number-setting/number-setting'
import styles from './variable-setting.scss'

type TempFieldType = { type: FieldTypesEnum; name: string }

type PropsType = {
  datasetFields: TempFieldType[]
  selectedVariables?: { [name: string]: ResultSegmentFieldsType }
  onVariableChange?(activeVariable: ResultSegmentFieldsType): void
}

export const VariableSetting = ({ datasetFields, selectedVariables, onVariableChange }: PropsType) => {
  const [filterString, setFilterString] = useState<string>('')
  const [activeVariable, setActiveVariable] = useState<ResultSegmentFieldsType | null>(null)

  const filteredDatasetFields = useMemo(() => {
    const lowerCaseFilterString = filterString.toLowerCase()

    return datasetFields.filter((field) => field.name.toLowerCase().includes(lowerCaseFilterString))
  }, [datasetFields, filterString])

  function handleItemClick(field: TempFieldType) {
    let _variable: ResultSegmentFieldsType

    if (selectedVariables && selectedVariables[field.name]) {
      _variable = selectedVariables[field.name]
    } else {
      _variable = {
        name: field.name,
        type: field.type,
      }
    }

    setActiveVariable(_variable)
    onVariableChange && onVariableChange(_variable)
  }

  function handleAddConditionButtonClick() {
    onVariableChange && activeVariable && onVariableChange(activeVariable)
  }

  return (
    <div className={styles.body}>
      <div className={styles.filter}>
        <div className={styles.inputWrapper}>
          <Input
            type='search'
            placeholder='Search for dataset'
            sizeType='sm'
            borderType='none'
            onChange={(e) => setFilterString(e.currentTarget.value)}
          />
          <InlineSVG className={styles.suffixIcon} src={iconSearch} />
        </div>
      </div>
      <ul className={styles.fieldList}>
        {filteredDatasetFields.map((field) => (
          <li
            key={field.name}
            className={classNames(styles.fieldItem, {
              [styles.active]: activeVariable?.name === field.name,
            })}
            onClick={() => handleItemClick(field)}
          >
            <div className={styles.fieldName}>{field.name}</div>
            <InlineSVG
              className={classNames(styles.selectedIcon, {
                [styles.notSelectedIcon]: selectedVariables && !selectedVariables[field.name],
              })}
              src={iconCircleCheck}
            />
          </li>
        ))}
      </ul>
      <div className={styles.fieldConditions}>
        <div className={styles.conditionTitle}>{`条件を追加`}</div>
        {activeVariable?.type === FieldTypesEnum.NUMBER && (
          <NumberSetting
            key={activeVariable.name}
            conditions={activeVariable.conditions}
            onValueChange={(conditions) => setActiveVariable({ ...activeVariable, conditions })}
          />
        )}
        {activeVariable?.type === FieldTypesEnum.CATEGORY && (
          <CategorySetting
            key={activeVariable.name}
            conditions={activeVariable.conditions}
            categories={categories}
            onValueChange={(conditions) => setActiveVariable({ ...activeVariable, conditions })}
          />
        )}
        {!activeVariable && <div className={styles.noActiveVariable}>Select Field</div>}
        <div className={styles.conditionFooter}>
          <Button
            sizeType='sm'
            outlined
            disabled={!activeVariable || (activeVariable?.conditions && !(activeVariable.conditions.length > 0))}
            onClick={handleAddConditionButtonClick}
          >{`追加`}</Button>
        </div>
      </div>
    </div>
  )
}

const categories = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
]
