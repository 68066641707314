import React, { useEffect, useState } from 'react'
import { Input } from 'src/component/atom/input/input'
import { RadioButton } from 'src/component/atom/radio-button/radio-button'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { ConditionType, OperatorType } from 'src/type/project.type'
import styles from './number-setting.scss'

type PropsType = {
  conditions?: ConditionType[]
  onValueChange?(value: ConditionType[]): void
}

export const NumberSetting = ({ conditions, onValueChange }: PropsType) => {
  const [op1, setOp1] = useState<OperatorType | undefined>(conditions && conditions[0]?.op)
  const [param1, setParam1] = useState<string | number | undefined>(conditions && conditions[0]?.param)
  const [conjunction, setConjunctions] = useState<OperatorType | undefined>(
    conditions && conditions[1] ? conditions[1].op : 'none',
  )
  const [op2, setOp2] = useState<OperatorType | undefined>(conditions && conditions[2]?.op)
  const [param2, setParam2] = useState<string | number | undefined>(conditions && conditions[2]?.param)

  useEffect(() => {
    handleConditionsChange()
  }, [op1, param1, conjunction, op2, param2])

  function selectedOption(value: string) {
    return operatorOptions.find((op) => op.value === value)
  }

  function selectConjunctions(e: React.ChangeEvent<HTMLInputElement>) {
    setConjunctions(e.currentTarget.value as OperatorType)
  }

  function handleConditionsChange() {
    const changedConditions: ConditionType[] = []
    if (!!op1 && !!param1) {
      changedConditions.push({ op: op1, param: param1 })
    }
    if (!!op1 && !!param1 && conjunction) {
      changedConditions.push({ op: conjunction })
      if (conjunction !== 'none' && !!op2 && !!param2) {
        changedConditions.push({ op: op2, param: param2 })
      }
    }
    onValueChange && onValueChange(changedConditions)
  }

  return (
    <div className={styles.root}>
      <div className={styles.condition}>
        <DropdownSingleSelector
          sizeType='lg'
          placeholder='選択'
          options={operatorOptions}
          selectedOption={op1 && selectedOption(op1)}
          onChange={(option) => setOp1(option.value as OperatorType)}
        />
        <Input
          type='number'
          sizeType='lg'
          placeholder='None'
          defaultValue={param1}
          isFullWidth
          onChange={(e) => setParam1(e.currentTarget.value)}
        />
      </div>
      <div className={styles.opertators}>
        <label className={styles.opertatorItem}>
          <span>{'None'}</span>
          <RadioButton
            name='operator'
            value='none'
            checked={conjunction === 'none'}
            onChange={selectConjunctions}
            disabled={!op1 || !param1}
          />
        </label>
        <label className={styles.opertatorItem}>
          <span>{'And'}</span>
          <RadioButton
            name='operator'
            value='and'
            checked={conjunction === 'and'}
            onChange={selectConjunctions}
            disabled={!op1 || !param1}
          />
        </label>
        <label className={styles.opertatorItem}>
          <span>{'Or'}</span>
          <RadioButton
            name='operator'
            value='or'
            checked={conjunction === 'or'}
            onChange={selectConjunctions}
            disabled={!op1 || !param1}
          />
        </label>
      </div>
      <div className={styles.condition}>
        <DropdownSingleSelector
          sizeType='lg'
          placeholder='選択'
          options={operatorOptions}
          selectedOption={op2 && selectedOption(op2)}
          isDarkDisabled
          disabled={conjunction === 'none'}
          onChange={(option) => setOp2(option.value as OperatorType)}
        />
        <Input
          type='number'
          sizeType='lg'
          placeholder='None'
          defaultValue={param2}
          isFullWidth
          isDarkDisabled
          disabled={conjunction === 'none'}
          onChange={(e) => setParam2(e.currentTarget.value)}
        />
      </div>
    </div>
  )
}

const operatorOptions = [
  { label: '<', value: 'lt' },
  { label: '<=', value: 'lte' },
  { label: '==', value: 'eq' },
  { label: '>=', value: 'gte' },
  { label: '>', value: 'gt' },
]
