import React, { useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg/esm'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'src/component/atom/input/input'
import { SymmetryButton } from 'src/component/atom/symmetry-button/symmetry-button'
import { ProjectGrid } from 'src/component/molecule/project-grid/project-grid'
import { Header } from 'src/component/organism/header/header'
import { DeleteProjectModal } from 'src/modal/delete-project/delete-project.modal'
import { notificationAction } from 'src/redux/notification/notification.action'
import { notificationSelector } from 'src/redux/notification/notification.state'
import { projectAction } from 'src/redux/project/project.action'
import { projectSelector } from 'src/redux/project/project.state'
import { softwareInfoSelector } from 'src/redux/software-info/software-info.state'
import { userSelector } from 'src/redux/user/user.state'
import IconTrash2 from 'src/style/icon/icon-trash2.svg'
import styles from './projects.scss'

export const ProjectsPage = () => {
  const dispatch = useDispatch()

  const user = useSelector(userSelector.user)
  const projects = useSelector(projectSelector.projects)
  const alertLogs = useSelector(notificationSelector.alertLogs)
  const notReadedLogIds = useSelector(notificationSelector.notReadedLogIds)
  const availableTopics = useSelector(softwareInfoSelector.availableTopics)

  const [filterString, setFilterString] = useState('')
  const [checkedProjectIds, setCheckedProjectIds] = useState<number[]>([])
  const [modalVisible, setModalVisible] = React.useState(false)

  const filteredProjects = useMemo(() => {
    const lowerCaseFilterString = filterString.toLowerCase()
    return [...projects]
      .sort((projA, projB) => (projA.createdAt > projB.createdAt ? -1 : 1))
      .filter((project) => project.name.toLowerCase().includes(lowerCaseFilterString))
  }, [projects, filterString])

  function handleOpenNotification() {
    if (notReadedLogIds.length > 0) {
      dispatch(notificationAction.updateAlertLog.request())
    }
  }

  function handleDeleteClick() {
    setModalVisible(true)
  }

  function handleEditProject(projectId: number, newName: string) {
    dispatch(projectAction.updateProject.request({ id: projectId, name: newName }))
  }

  return (
    <div className={styles.root}>
      {user && (
        <Header user={user} alertLogs={alertLogs} onOpenNotification={handleOpenNotification}>
          <div className={styles.headerText}>{`プロジェクト一覧`}</div>
        </Header>
      )}
      <div className={styles.body}>
        <div className={styles.control}>
          <label>Filter</label>
          <Input
            placeholder='Filter by project name'
            sizeType='xs'
            borderType='bottom'
            onChange={(e) => setFilterString(e.currentTarget.value)}
          />
          <SymmetryButton sizeType='md' onClick={handleDeleteClick}>
            <InlineSVG src={IconTrash2} />
          </SymmetryButton>
        </div>
        <section className={styles.grid}>
          <ProjectGrid
            projects={filteredProjects}
            gridType='panel'
            availableTopics={availableTopics}
            onChangeCheckedProject={setCheckedProjectIds}
            onChangeTargetProjectName={handleEditProject}
          />
        </section>
      </div>
      <DeleteProjectModal
        targetProjectIds={checkedProjectIds}
        modalVisible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </div>
  )
}
