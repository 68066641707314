import { combineEpics } from 'redux-observable'
import { authEpic } from './auth/auth.epic'
import { blueprintEpic } from './blueprint/blueprint.epic'
import { branchEpic } from './branch/branch.epic'
import { dashboardEpic } from './dashboard/dashboard.epic'
import { datasetEpic } from './dataset/dataset.epic'
import { notificationEpic } from './notification/notification.epic'
import { projectEpic } from './project/project.epic'
import { softwareInfoEpic } from './software-info/software-info.epic'
import { taskEpic } from './task/task.epic'
import { userEpic } from './user/user.epic'

export const epic = combineEpics(
  taskEpic,
  projectEpic,
  datasetEpic,
  blueprintEpic,
  notificationEpic,
  authEpic,
  userEpic,
  branchEpic,
  dashboardEpic,
  softwareInfoEpic,
)
