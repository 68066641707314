import { BranchType } from 'src/type/branch.type'
import { httpApi } from 'src/util/http.module'

const fetchBranches = () =>
  httpApi.get<BranchType[]>({
    url: `/branches`,
  })

export type CreateBranchesParamsType = {
  file: File
}
const createBranches = (params: CreateBranchesParamsType) => {
  const formData = new FormData()
  formData.append('file', params.file)

  return httpApi.post({
    url: `/branches`,
    body: formData,
  })
}

export type CreateBranchParamsType = {
  branchCode: string
  branchName: string
}
const createBranch = ({ branchCode, branchName }: CreateBranchParamsType) =>
  httpApi.post<BranchType>({
    url: `/branch`,
    body: { branchCode, branchName },
  })

export type EditBranchParamsType = {
  branchId: number
  branchCode: string
  branchName: string
}
const editBranch = ({ branchId, branchCode, branchName }: EditBranchParamsType) =>
  httpApi.post<BranchType>({
    url: `/branches/${branchId}`,
    body: { branchCode, branchName },
  })

export type DeleteBranchParamsType = {
  ids: number[]
}
const deleteBranches = ({ ids }: DeleteBranchParamsType) =>
  httpApi.delete({
    url: `/branches`,
    body: { ids },
  })

const downloadBranchCodeTemplate = () => httpApi.post({ url: '/branches/templatefile' })

export const branchService = {
  fetchBranches,
  createBranches,
  createBranch,
  editBranch,
  deleteBranches,
  downloadBranchCodeTemplate,
}
