import 'src/style/css/common.scss'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { CommonPage } from 'src/page/common/common.page'
import { LoginPage } from 'src/page/login/login.page'
import { authAction } from 'src/redux/auth/auth.action'
import { authSelector } from 'src/redux/auth/auth.state'
import { softwareInfoAction } from 'src/redux/software-info/software-info.action'
import { userAction } from 'src/redux/user/user.action'
import { userSelector } from 'src/redux/user/user.state'
import styles from './app.scss'

export const App = () => {
  const dispatch = useDispatch()
  const loggedInUser = useSelector(authSelector.loggedInUser)
  const user = useSelector(userSelector.user)

  useEffect(() => {
    dispatch(authAction.refresh.request())
    dispatch(softwareInfoAction.fetchSoftwareInfo.request())
  }, [])

  useEffect(() => {
    if (loggedInUser) {
      dispatch(userAction.fetchUser.request({ userId: loggedInUser.id }))
    } else {
      dispatch(userAction.setUser(null))
    }
  }, [loggedInUser])

  return (
    <div className={styles.root}>
      {user ? <CommonPage /> : <LoginPage />}
      <ToastContainer
        position='top-center'
        theme='light'
        hideProgressBar
        autoClose={false}
        draggable={false}
        className={styles.toastContainer}
      />
    </div>
  )
}
