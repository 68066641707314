import React, { useMemo, useState } from 'react'
import InlineSVG from 'react-inlinesvg'
import { useDispatch, useSelector } from 'react-redux'
import { ChartPie } from 'src/component/molecule/chart-pie/chart-pie'
import { CellRenderProps, DataTable, DataTableColumnDefsType } from 'src/component/molecule/data-table/data-table'
import { DropdownSingleSelector } from 'src/component/molecule/dropdown-single-selector/dropdown-single-selector'
import { dashboardCompanyDataIntl, dashboardCompanyLoanGradeDataIntl } from 'src/intl/data/dashboard.key'
import { CompanyDetailSideModal } from 'src/modal/company-detail/company-detail.modal'
import { branchSelector } from 'src/redux/branch/branch.state'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { PieChartColors } from 'src/redux/dashboard/dashboard.const'
import { dashboardSelector } from 'src/redux/dashboard/dashboard.state'
import IconDownload from 'src/style/icon/icon-download.svg'
import { OptionType } from 'src/type/common.type'
import styles from './sales-insight-info.scss'

export const SalesInsightInfo = () => {
  const dispatch = useDispatch()

  const [detailModalVisible, setDetailModalVisible] = useState<string>()

  const dashboardCompany = useSelector(dashboardSelector.dashboardCompanies)
  const companyPieChartDatas = useSelector(dashboardSelector.dashboardCompanyPieChartDatas)
  const dashboardCompanyRecords = useSelector(dashboardSelector.dashboardCompanyRecords)
  const branchCodeOptions = useSelector(dashboardSelector.dsCompanyBranchCodeOptions)
  const branchRecordsByCode = useSelector(branchSelector.branchRecordsByCode)
  const selectedBranchCode = useSelector(dashboardSelector.selectedCompanyBranchCode)
  const dashboardCompanyPage = useSelector(dashboardSelector.dashboardCompanyPage)

  const customerBranchCodeOptions = useMemo(() => {
    return branchCodeOptions.map<OptionType>((bc) => ({
      label:
        branchRecordsByCode[bc.value]?.branchName ||
        (bc.value === 'allBranches' ? dashboardCompanyDataIntl['allBranches'] : bc.value),
      value: bc.value,
    }))
  }, [branchCodeOptions, branchRecordsByCode])

  const selectedBranchCodeOption = useMemo(
    () => customerBranchCodeOptions.find((op) => op.value === selectedBranchCode),
    [customerBranchCodeOptions, selectedBranchCode],
  )

  const dataColumnDefs = useMemo(
    () =>
      companyDataColumnDefObjs.map((column) => ({
        ...column,
        cellRenderer:
          column.dataKey === 'companyName'
            ? (cell: CellRenderProps) => (
                <div className={styles.companyLink} onClick={() => setDetailModalVisible(`${cell.rowData.id}`)}>
                  {cell.cellData}
                </div>
              )
            : undefined,
      })),
    [companyDataColumnDefObjs],
  )

  const companyDataList = useMemo(() => {
    return dashboardCompany?.map((c) => ({
      id: c.id,
      companyName: c.companyName,
      corporateType: c.corporateType,
      ceoName: c.ceoName,
      industry: c.industry,
      postCode: c.postCode,
      isMainBank: c.isMainBank === 'true' ? 'あり' : c.isMainBank === 'false' ? 'なし' : '-',
      isLoan: c.isLoan === 'true' ? 'あり' : c.isLoan === 'false' ? 'なし' : '-',
      loan1Grade:
        c.loan1Grade &&
        (['None', 'null'].includes(c.loan1Grade)
          ? '-'
          : dashboardCompanyLoanGradeDataIntl[c.loan1Grade] || c.loan1Grade),
      loan2Grade:
        c.loan2Grade &&
        (['None', 'null'].includes(c.loan2Grade)
          ? '-'
          : dashboardCompanyLoanGradeDataIntl[c.loan2Grade] || c.loan2Grade),
      numSalesMotives: c.numSalesMotives && ['None', 'null'].includes(c.numSalesMotives) ? '-' : c.numSalesMotives,
    }))
  }, [dashboardCompany])

  function handleChangeSelectBranchCode(option: OptionType<string>) {
    dispatch(dashboardAction.setSelectedCompanyBranchCode(option.value))
  }

  function handleDownloadButtonClick() {
    dispatch(dashboardAction.downloadDsCompanyList.request({ branchCode: selectedBranchCode }))
  }

  function handleClickLoadMore() {
    dispatch(dashboardAction.setDashboardCompanyListPage(dashboardCompanyPage + 1))
    dispatch(
      dashboardAction.fetchDashboardCompanyList.request({
        branchCode: selectedBranchCode,
        page: dashboardCompanyPage + 1,
        pageSize: 20,
      }),
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.tabContentHeader}>
        <div className={styles.tabContentHeaderController}>
          <div>{`支店名`}</div>
          <DropdownSingleSelector
            sizeType='lg'
            isNeededFilter
            options={customerBranchCodeOptions}
            placeholder={branchCodeOptions.length === 1 ? 'No Branches' : 'Select Branch'}
            disabled={branchCodeOptions.length === 1}
            selectedOption={selectedBranchCodeOption}
            onChange={handleChangeSelectBranchCode}
          />
        </div>
      </div>
      <div className={styles.chartWrapper}>
        <ChartPie
          height={300}
          pieSize={200}
          title={`返済中 (%)`}
          yUnit={`%`}
          data={companyPieChartDatas ? [companyPieChartDatas.mainBankData, companyPieChartDatas.otherBankData] : []}
        />
        <ChartPie
          height={300}
          pieSize={200}
          title={`直近借入 (%)`}
          yUnit={`%`}
          data={companyPieChartDatas ? [companyPieChartDatas.isLoan, companyPieChartDatas.notLoan] : []}
        />
        <ChartPie
          height={300}
          pieSize={200}
          title={`融資1の需要`}
          yUnit={`件`}
          data={
            companyPieChartDatas
              ? [
                  companyPieChartDatas.loan1Demand5,
                  companyPieChartDatas.loan1Demand4,
                  companyPieChartDatas.loan1Demand3,
                  companyPieChartDatas.loan1Demand2,
                  companyPieChartDatas.loan1Demand1,
                  companyPieChartDatas.loan1others,
                ]
              : []
          }
        />
        <ChartPie
          height={300}
          pieSize={200}
          title={`融資2の需要`}
          yUnit={`件`}
          data={
            companyPieChartDatas
              ? [
                  companyPieChartDatas.loan2Demand5,
                  companyPieChartDatas.loan2Demand4,
                  companyPieChartDatas.loan2Demand3,
                  companyPieChartDatas.loan2Demand2,
                  companyPieChartDatas.loan2Demand1,
                  companyPieChartDatas.loan2others,
                ]
              : []
          }
        />
      </div>
      <div className={styles.pieLegendInfo}>
        <div className={styles.pieLegend}>
          <div className={styles.pieLegendItem}>
            <div className={styles.bullet} style={{ backgroundColor: PieChartColors[0] }} />
            {`あり`}
          </div>
          <div className={styles.pieLegendItem}>
            <div className={styles.bullet} style={{ backgroundColor: PieChartColors[1] }} />
            {`なし`}
          </div>
        </div>
        <div className={styles.pieLegend}>
          <div className={styles.pieLegendItem}>
            <div className={styles.bullet} style={{ backgroundColor: PieChartColors[0] }} />
            {dashboardCompanyLoanGradeDataIntl['10']}
          </div>
          <div className={styles.pieLegendItem}>
            <div className={styles.bullet} style={{ backgroundColor: PieChartColors[1] }} />
            {dashboardCompanyLoanGradeDataIntl['9']}
          </div>
          <div className={styles.pieLegendItem}>
            <div className={styles.bullet} style={{ backgroundColor: PieChartColors[2] }} />
            {dashboardCompanyLoanGradeDataIntl['7']}
          </div>
          <div className={styles.pieLegendItem}>
            <div className={styles.bullet} style={{ backgroundColor: PieChartColors[3] }} />
            {dashboardCompanyLoanGradeDataIntl['4']}
          </div>
          <div className={styles.pieLegendItem}>
            <div className={styles.bullet} style={{ backgroundColor: PieChartColors[4] }} />
            {dashboardCompanyLoanGradeDataIntl['2']}
          </div>
        </div>
      </div>
      <div className={styles.tableHeader}>
        <div className={styles.downloadButton} onClick={handleDownloadButtonClick}>
          <span>{`企業リストダウンロード`}</span>
          <div className={styles.downloadIconWrapper}>
            <InlineSVG className={styles.downloadIcon} src={IconDownload} />
          </div>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <DataTable
          dataColumnDefs={dataColumnDefs}
          dataList={companyDataList || []}
          theme='light'
          headerHeight={70}
          rowHeight={70}
          rowSeperator={false}
          tableHeight={400}
          colorizedRow
          onClickLastRow={handleClickLoadMore}
        />
      </div>
      {detailModalVisible && (
        <CompanyDetailSideModal
          modalVisible={!!detailModalVisible}
          dashboardCompany={dashboardCompanyRecords[+detailModalVisible]}
          onClose={() => setDetailModalVisible(undefined)}
        />
      )}
    </div>
  )
}

const companyDataColumnDefObjs: DataTableColumnDefsType[] = [
  {
    dataKey: 'companyName',
    label: '会社名',
    flexRate: 0.7,
    headerBold: true,
  },
  {
    dataKey: 'corporateType',
    label: '法人格',
    flexRate: 0.7,
    headerBold: true,
  },
  {
    dataKey: 'ceoName',
    label: '代表者氏名',
    flexRate: 0.7,
    headerBold: true,
  },
  {
    dataKey: 'industry',
    label: '業種',
    flexRate: 1,
    headerBold: true,
  },
  {
    dataKey: 'postCode',
    label: '会社所在地',
    flexRate: 1,
    headerBold: true,
  },
  {
    dataKey: 'isMainBank',
    label: '直近返済実績',
    flexRate: 0.7,
    headerBold: true,
  },
  {
    dataKey: 'isLoan',
    label: '直近借入実績',
    flexRate: 0.7,
    headerBold: true,
  },
  {
    dataKey: 'loan1Grade',
    label: '融資1の需要',
    flexRate: 0.7,
    headerBold: true,
  },
  {
    dataKey: 'loan2Grade',
    label: '融資2の需要',
    flexRate: 0.7,
    headerBold: true,
  },
  {
    dataKey: 'numSalesMotives',
    label: '営業のネタ',
    flexRate: 0.7,
    headerBold: true,
  },
]
