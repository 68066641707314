import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'src/component/atom/button/button'
import { withModalWrapper } from 'src/hoc/with-modal-wrapper'
import { branchAction } from 'src/redux/branch/branch.action'
import { branchSelector } from 'src/redux/branch/branch.state'
import { ReduxStatusEnum } from 'src/type/common.type'
import styles from './delete-branches.scss'

type PropsType = {
  targetBranchIds: number[]
  onClose(): void
}

export const DeleteBranchesModal = withModalWrapper(({ targetBranchIds, onClose }: PropsType) => {
  const dispatch = useDispatch()
  const branchStatus = useSelector(branchSelector.status)
  const [closeClick, setCloseClick] = useState(false)

  useEffect(() => {
    if (closeClick === true && branchStatus === ReduxStatusEnum.IDLE) {
      onClose()
    }
  }, [branchStatus])

  function handleDeleteClick() {
    setCloseClick(true)
    dispatch(branchAction.deleteBranches.request({ ids: targetBranchIds }))
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>{`本当に選択した項目を削除しますか?`}</div>
      <div className={styles.footer}>
        <Button sizeType='lg' isRounded outlined onClick={onClose}>
          {`キャンセル`}
        </Button>
        <Button sizeType='lg' isRounded onClick={handleDeleteClick}>
          {`削除`}
        </Button>
      </div>
    </div>
  )
}, '支店名削除')
