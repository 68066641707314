import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavBar, NavigationItemType } from 'src/component/organism/nav-bar/nav-bar'
import { branchAction } from 'src/redux/branch/branch.action'
import { dashboardAction } from 'src/redux/dashboard/dashboard.action'
import { notificationAction } from 'src/redux/notification/notification.action'
import { projectAction } from 'src/redux/project/project.action'
import { projectSelector } from 'src/redux/project/project.state'
import { taskAction } from 'src/redux/task/task.action'
import { userSelector } from 'src/redux/user/user.state'
import IconChartHover from 'src/style/icon/icon-chart-hover.svg'
import IconChart from 'src/style/icon/icon-chart.svg'
import IconCube from 'src/style/icon/icon-cube.svg'
import IconDocHover from 'src/style/icon/icon-doc-hover.svg'
import IconDoc from 'src/style/icon/icon-doc.svg'
import IconHomeHover from 'src/style/icon/icon-home-hover.svg'
import IconHome from 'src/style/icon/icon-home.svg'
import IconSettingHover from 'src/style/icon/icon-setting-hover.svg'
import IconSetting from 'src/style/icon/icon-setting.svg'
import { SpinnerLoader } from '../spinner/spinner.loader'
import { CommonRoute } from './common.route'
import styles from './common.scss'

export const CommonPage = () => {
  const dispatch = useDispatch()

  const user = useSelector(userSelector.user)
  const isProjects = useSelector(projectSelector.isProjects)

  useEffect(() => {
    if (user) {
      dispatch(branchAction.fetchBranches.request())
      dispatch(projectAction.fetchProjects.request())
      dispatch(taskAction.fetchTasks.request())
      dispatch(notificationAction.fetchAlertLogs.request())
      dispatch(dashboardAction.fetchDashboardContractFilterItems.request())
      dispatch(dashboardAction.fetchDashboardIndustryFilterCorporateTypes.request())
      dispatch(dashboardAction.fetchDashboardCompanyFilterItems.request())
      dispatch(taskAction.eventConnectionOpen(user.id))
    }

    return () => {
      dispatch(taskAction.eventConnectionClose())
    }
  }, [])

  return (
    <>
      <NavBar navigationItems={navigationItems} />
      <div className={styles.pageContainer}>{isProjects && <CommonRoute />}</div>
      <SpinnerLoader />
    </>
  )
}

const navigationItems: NavigationItemType[] = [
  {
    path: '/',
    label: 'ホーム画面',
    svgIconSrc: IconHome,
    hoverSvgIconSrc: IconHomeHover,
  },
  {
    path: '/projects',
    label: 'プロジェクト一覧',
    svgIconSrc: IconDoc,
    hoverSvgIconSrc: IconDocHover,
  },
  {
    path: '/project',
    label: 'プロジェクト',
    svgIconSrc: IconCube,
    disabledClick: true,
  },
  {
    path: '/dashboard',
    label: 'Dashboard',
    svgIconSrc: IconChart,
    hoverSvgIconSrc: IconChartHover,
  },
  {
    path: '/setting',
    label: '設定',
    svgIconSrc: IconSetting,
    hoverSvgIconSrc: IconSettingHover,
  },
]
