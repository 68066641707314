import classNames from 'classnames'
import React from 'react'
import { OptionType } from 'src/type/common.type'
import styles from './tab.scss'

type PropsType = {
  items: OptionType<string>[]
  activeItem?: OptionType<string>
  onChange?(item: OptionType<string>): void
}

export const Tab = ({ items, activeItem, onChange }: PropsType) => {
  return (
    <div className={styles.root}>
      {items.map((item) => (
        <div
          key={item.value}
          className={classNames(styles.item, { [styles.active]: activeItem?.value === item.value })}
          onClick={() => onChange && onChange(item)}
        >
          {item.label}
        </div>
      ))}
    </div>
  )
}
