import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsColorAxis from 'highcharts/modules/coloraxis'
import React from 'react'
import { SegmentType } from 'src/type/project.type'

type PropsType = {
  segments: SegmentType[]
  chartHight?: number
}

export const ChartPackedBubble = ({ segments, chartHight }: PropsType) => {
  HighchartsMore(Highcharts)
  HighchartsColorAxis(Highcharts)

  const { range, maxSize, minSize } = calcStats(segments)

  const data = segments.map((segment, idx) => {
    return {
      name: idx + 1,
      value: segment.size,
      colorValue: segment.average,
    }
  })

  const options: Highcharts.Options = {
    credits: { enabled: false },
    title: { text: '' },
    accessibility: {
      enabled: false,
    },
    chart: {
      type: 'packedbubble',
      height: chartHight,
      style: {
        fontFamily: 'Roboto, NotoSansJP',
      },
    },
    xAxis: [],
    yAxis: [],
    colorAxis: [
      {
        layout: 'vertical',
        minColor: '#AFBDF4',
        maxColor: '#3C53B2',
        min: range[0],
        max: range[2],
        tickPositions: range,
        labels: {
          x: -50,
        },
      },
    ],
    legend: {
      layout: 'vertical',
      verticalAlign: 'middle',
      align: 'right',
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<b>Segment </b>',
      pointFormat: '<b>{point.name}</b><br/>Size: {point.value}<br/>Avg: {point.colorValue:,.3f}',
    },
    plotOptions: {
      packedbubble: {
        minSize: '30%',
        maxSize: '100%',
        marker: {
          lineWidth: 0,
        },
        layoutAlgorithm: {
          splitSeries: undefined,
          gravitationalConstant: 0.03,
          initialPositionRadius: 100,
        },
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          useHTML: true,
          formatter() {
            const size = (30 * ((this.point.y || 0) - minSize)) / (maxSize - minSize) + 15
            const label = this.point.name

            return `<span style='font-size: ${size}px; color: white;'>${label}</span>`
          },
        },
        events: {
          click(e) {
            console.log('bubble click eveent')
          },
        },
      },
    },
    series: [
      {
        type: 'packedbubble',
        name: 'SegmentSet',
        colorKey: 'colorValue',
        data,
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

function calcStats(segments: SegmentType[]): { range: number[]; maxSize: number; minSize: number } {
  let minAvg: number | undefined
  let maxAvg: number | undefined
  let globalTargetAvg: number | undefined
  let maxSize = 0
  let minSize = 0
  segments.forEach((segment) => {
    if (!segment) return
    const { average, size } = segment
    if (typeof average !== 'number') return

    if (minAvg === undefined || average < minAvg) minAvg = average
    if (maxAvg === undefined || average > maxAvg) maxAvg = average
    if (globalTargetAvg === undefined) globalTargetAvg = average

    if (size !== undefined && size < minSize) minSize = size
    if (size !== undefined && size > maxSize) maxSize = size
  })

  minAvg = minAvg || 0
  maxAvg = maxAvg || 1
  globalTargetAvg = globalTargetAvg || 0

  // When there is one segment only
  if (minAvg === maxAvg) {
    minAvg *= 0.8
    maxAvg *= 1.2
  }

  const range = [
    Math.floor(maxAvg * 1000) / 1000,
    Math.floor(minAvg * 1000) / 1000,
    Math.floor(globalTargetAvg * 1000) / 1000,
  ]
  range.sort((a, b) => a - b)

  return { range, maxSize, minSize }
}
