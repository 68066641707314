import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { OptionType } from 'src/type/common.type'
import styles from './dropdown-single-option-item.scss'

type PropsType = {
  option: OptionType
  active: boolean
  sizeType?: 'sm' | 'md' | 'lg'
  onClick?(option: OptionType): void
}

export const DropdownSingleOptionItem = ({ option, active, sizeType, onClick }: PropsType) => {
  const itemRef = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (active && itemRef.current) {
      itemRef.current.scrollIntoView({
        block: 'center',
      })
    }
  }, [active, itemRef.current])

  return (
    <li
      ref={itemRef}
      className={classNames(styles.root, {
        [styles.selected]: active,
        [styles.smSize]: sizeType === 'sm',
        [styles.mdSize]: sizeType === 'md',
        [styles.lgSize]: sizeType === 'lg',
      })}
      onClick={() => onClick && onClick(option)}
    >
      {option.label}
    </li>
  )
}
